import React, { Component } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { BpBase, ButtonAction, formatNumber } from 'blueprint-hooks-ui';
import config from 'src/utils/config';

import './index.less';

const PackageList = ({ dataStorage, handleSelectPackage, currentId }) => {
  const fieldFilter = [
    {
      title: 'Tên gói',
      dataIndex: 's'
    }
  ];

  const fieldList = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt'
    },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      key: 'name',
      render: (value) => {
        return value
      }
    },
    {
      title: 'Thông tin',
      dataIndex: 'plans',
      key: 'plans',
      render: (values, record) => {
        if (values) {
          return values.map((plan) => {
            const { time, price } = plan;

            return (
              <div style={{ margin: '5px 0' }}>
                <Button
                  small
                  intent={Intent.PRIMARY}
                  text="Chọn"
                  onClick={() => {
                    handleSelectPackage(record, plan);
                  }}
                  style={{ marginRight: '10px' }}
                  disabled={!record.status}
                />
                {time} ({formatNumber(price, { suffix: 'VND' })})
              </div>
            )
          })
        }
      }
    }
  ];

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isDeleteRecord={false}
        fieldFilter={fieldFilter}
        fieldList={fieldList}
        orderName="index"
        orderType="asc"
        apiConfig={{
          list: {
            url: `${config.apiEndpoint.PLAN}`,
            params: {
              status: -1
            }
          }
        }}
      />
    </div>
  )
};

export default PackageList
