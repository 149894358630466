import React, { Fragment, useEffect, useState } from "react";
import { Button, Intent } from "@blueprintjs/core";
import ChannelList from "../ChannelList";
import constants from "../../../utils/constants";
import Axios from "axios";
import { fetchData, request, useAuth } from "blueprint-hooks-ui";
import config from "src/utils/config";
import MetadataTab from "src/components/blocks/MetadataTab";
import dot from "dot-object";

export default function DetailItem(props) {
  const auth = useAuth();
  const storageKey = process.env.STORAGE_KEY;
  const tokenName = process.env.TOKEN_NAME;
  const dataStorage = auth[storageKey];
  const token = dataStorage[tokenName];
  let [text, setText] = useState("");
  let {
    data,
    dataList,
    type,
    typeData,
    handleOpenDialog,
    handleSetValueForm,
    handleCloseDialog,
    textButton,
    titleModal,
    dataKey = "data.value",
    screenType,
    isDisabled = false
  } = props;

  const fieldValue = dot.pick(dataKey, data);
  console.log("🚀 ~ file: index.js ~ line 33 ~ DetailItem ~ fieldValue", fieldValue)

  useEffect(() => {
    if (data.data || data.target) {
      let idItem = "";

      if (type === "spotlight") {
        idItem = data.target.vodId;
      } else {
        idItem = data.data.value;
      }
      let stringUrl = "";
      switch (typeData) {
        case constants.TYPE_DETAIL_ITEM_COMPONENT.CHANNEL:
          stringUrl = `/channel/${idItem}`;
          break;

        case constants.TYPE_DETAIL_ITEM_COMPONENT.VOD:
        case 3:
          stringUrl = `/metadata/${idItem}`;
          break;
        default:
          break;
      }

      if (idItem && stringUrl) {
        fetchData({
          fetchApi: request({
            url: stringUrl,
            apiUrl: config.apiJungoTv,
            data: {},
            token,
            auth,
          }),
          hideMessage: true,
          handleSuccess: (data) => {
            if (data.title) {
              setText(data.title);
            }
          },
        });
      }
    }
  }, []);

  return (
    <Fragment>
      {
        text
        &&
        <span style={{ marginRight: "5px" }}>{fieldValue && text}</span>
      }
      <Button
        intent={Intent.PRIMARY}
        disabled={isDisabled}
        text={textButton}
        onClick={() => {
          let component;
          if (typeData === constants.TYPE_DETAIL_ITEM_COMPONENT.CHANNEL) {
            component = (
              <ChannelList
                dataList={dataList}
                handleSelectChannel={(record) => {
                  handleSetValueForm(dataKey, record.id);
                  setText(record.title);
                  handleCloseDialog();
                }}
              />
            );
          } else {
            component = (
              <MetadataTab
                screenType={screenType}
                handleSelect={(record) => {
                  handleSetValueForm(dataKey, record.id);

                  // console.log('record.language', record);

                  if (type === 'spotlight') {
                    handleSetValueForm('languages', record.languages || []);
                    handleSetValueForm('allowNation', record.allowNation || []);
                    handleSetValueForm('blockNation', record.blockNation || []);
                    // handleSetValueForm('parentNodeId', record.)
                  }

                  setText(record.title);
                  handleCloseDialog();
                }}
              />
            );
          }
          handleOpenDialog({
            dialogTitle: titleModal, //"Chọn node nội dung",
            dialogContent: component,
            dialogHideFooter: true,
          });
        }}
      />
    </Fragment>
  );
}
