import constants from 'src/utils/constants';

let initialState = {
  authenticated: false,
  permissions: []
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case constants.AUTH_TYPE.LOGIN:
      return {
        ...state,
        authenticated: true
      };

    case constants.AUTH_TYPE.LOGOUT:
      return {
        ...state,
        authenticated: false
      };

    case constants.AUTH_TYPE.PERMISSION:
      return {
        ...state,
        permissions: payload.permissions
      };

    default:
      return state;
  }
};

export default reducer
