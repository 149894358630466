import Global from 'src/commons/classes/action/global';
import User from 'src/commons/classes/action/user';
import Metadata from 'src/commons/classes/action/metadata';
import Channel from 'src/commons/classes/action/channel';
import SDP from 'src/commons/classes/action/sdp';
import { getAllFunctionByClass } from 'src/helpers';

export default {
  init: ({ dataStorage, auth }) => {
    Global.instance().init({ dataStorage, auth });
    User.instance().init({ dataStorage, auth });
    Metadata.instance().init({ dataStorage, auth });
    Channel.instance().init({ dataStorage, auth });
    SDP.instance().init({ dataStorage, auth });
  },
  ...getAllFunctionByClass(Global),
  ...getAllFunctionByClass(User),
  ...getAllFunctionByClass(Metadata),
  ...getAllFunctionByClass(Channel),
  ...getAllFunctionByClass(SDP)
}
