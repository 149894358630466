import React, { Component } from "react";
import {
  BpBase,
  formatDateTime,
  confirmHandler,
  ButtonAction,
  useRouter,
} from "blueprint-hooks-ui";
import AppManager from "src/commons/AppManager";
import ActionManager from "src/commons/ActionManager";
import constants from "src/utils/constants";
import config from "src/utils/config";

import "./index.less";

const VodSingleList = ({
  dataStorage,
  dataDetail = {},
  isSelect,
  handleSelect = () => {},
  isAddRecord = true,
}) => {
  const router = useRouter();

  ActionManager.init({
    dataStorage,
  });

  const fieldFilter = [
    {
      title: "Title", // "Tên VOD",
      dataIndex: "s",
    },
  ];

  const fieldList = ({ handleOpenDialog, refreshData }) => {
    let fieldExtra = [];

    if (isSelect) {
      fieldExtra = [
        {
          title: "Select",
          dataIndex: "choice",
          key: "choice",
          sorter: false,
          align: "center",
          render: (value, record) => {
            if (
              record.source &&
              record.state === constants.STATE_VOD_TYPE.PUBLISHED
            ) {
              return (
                <ButtonAction
                  title=""
                  icon="add"
                  onClick={() => {
                    handleSelect(record);
                  }}
                />
              );
            }
          },
        },
      ];
    } else {
      fieldExtra = [
        {
          title: "Choose", //'Chọn',
          dataIndex: "choice",
          key: "choice",
          align: "center",
          render: (value, record) => {
            if (!record.source) {
              return (
                <ButtonAction
                  title=""
                  icon="add"
                  onClick={() => {
                    confirmHandler({
                      title: "Confirm", //"Xác nhận",
                      content: (
                        <div>
                          Do you want to move this content to movie{" "}
                          <b>{record.title}</b>?
                        </div>
                      ),
                      onOk: () => {
                        router.push(
                          "/media/vod/single/" +
                            record.id +
                            `?parentId=${dataDetail.id}`
                        );
                      },
                    });
                  }}
                />
              );
            }
          },
        },
      ];
    }

    return [
      {
        title: "Index",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Title", // "Tên VOD",
        dataIndex: "title",
        key: "title",
        width: 400,
        render: (value) => {
          return value;
        },
      },
      ...fieldExtra,
    ];
  };

  const listPrams = {};

  if (isSelect) {
    listPrams.state = constants.STATE_VOD_TYPE.PUBLISHED;
  }

  return (
    <BpBase
      hasInView
      isTargetBlank
      isAddRecord={isAddRecord}
      isDeleteRecord={false}
      // isFetchList={false}
      fieldFilter={fieldFilter}
      fieldList={fieldList}
      apiConfig={{
        list: {
          url: config.apiEndpoint.METADATA,
          params: {
            type: constants.VOD_TYPE.LE,
            isDeleted: 0,
            screenType: "vod",
            ...listPrams,
          },
        },
      }}
      filterProps={{
        addRecordTo: "/media/vod/ingest/detail/single/" + dataDetail.id,
      }}
    />
  );
};

export default VodSingleList;
