import React, { Component } from 'react';
import classNames from 'classnames';
import { ContextMenu, Menu, MenuItem, Button, Intent, H3, ButtonGroup } from '@blueprintjs/core';
import { BpBase, formatDateTime, confirmHandler } from 'blueprint-hooks-ui';
import AppManager from 'src/commons/AppManager';
import ActionManager from 'src/commons/ActionManager';
import config from 'src/utils/config';

import './index.less';

const ApproveList = ({ dataStorage, dataDetail, dataList = [] }) => {
  ActionManager.init({
    dataStorage
  });

  const fieldList = ({ handleOpenDialog, refreshData }) => {
    return [
      {
        title: 'STT',
        dataIndex: 'stt',
        key: 'stt'
      },
      {
        title: 'Title',
        dataIndex: 'payload',
        key: 'title',
        render: (data = {}) => {
          return data.title
        }
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Action',
        dataIndex: 'payload',
        key: 'payload',
        render: (data = {}) => {
          return data.action
        }
      },
      {
        title: 'Creator',
        dataIndex: 'creator',
        key: 'creator',
        render: (data = {}) => {
          return data.username
        }
      },
      {
        title: 'Creator',
        dataIndex: 'creator',
        key: 'updatedAt',
        render: (value) => {
          return value && formatDateTime(value)
        }
      },
      {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        align: 'center',
        render: (value, record = {}) => {
          return (
            <Button
              text="Preview"
              onClick={() => {
                AppManager.initDialog({ handleOpenDialog, refreshData });

                AppManager.openMetadataDetail({
                  dialogData: record.payload,
                  dialogTitle: 'Information changes'
                })
              }}
            />
          )
        }
      }
    ];
  };

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isDeleteRecord={false}
        // isFetchList={false}
        dataList={dataList}
        fieldList={fieldList}
        ctaRecord={({ handleCloseDialog, refreshData }) => [
          {
            title: 'Reject',
            icon: 'error',
            intent: Intent.DANGER,
            handleAction: (record) => {
              const title = record.payload && record.payload.title;

              confirmHandler({
                title: 'Notification',
                content: `Reject approve ${title || record.id}`,
                onOk: () => {
                  ActionManager.approveReject(record, () => {
                    refreshData();

                    handleCloseDialog();
                  });
                }
              })
            }
          },
          {
            title: 'Approve',
            icon: 'tick-circle',
            intent: Intent.SUCCESS,
            handleAction: (record) => {
              const title = record.payload && record.payload.title;

              confirmHandler({
                title: 'Notification',
                content: `Agree approve ${title || record.id}`,
                onOk: () => {
                  ActionManager.approvePublish(record, () => {
                    refreshData();

                    handleCloseDialog();
                  });
                }
              });
            }
          }
        ]}
        apiConfig={{
          list: {
            url: `${config.apiEndpoint.APPROVE}/metadata/${dataDetail.id}`,
            params: {
              limit: 10
            }
          }
        }}
      />
    </div>
  )
};

export default ApproveList
