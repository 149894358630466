import { fetchData, request } from 'blueprint-hooks-ui';
import constants from 'src/utils/constants';
import config from 'src/utils/config';

export const getPermissions = (store, params = {}, callback) => {
  const { accessToken } = params;

  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.AUTH}`,
      token: accessToken
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.AUTH_TYPE.PERMISSION,
        payload: {
          permissions: data
        }
      });

      callback && callback(data);
    }
  });
};

export const setProfileInfo = (store, data, callback) => {
  store.dispatch({
    type: constants.ACTION_TYPE.GET_PROFILE,
    payload: {
      profile: data
    }
  })
};
