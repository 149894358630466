import React, { Component } from "react";
import { BpBase, ButtonAction } from "blueprint-hooks-ui";
import config from "src/utils/config";

import "./index.less";
import { checkStatusBySchedule } from "../../../helpers";

const ChannelList = ({ dataStorage, handleSelectChannel, currentId }) => {
  const fieldFilter = [
    {
      title: "Title",
      dataIndex: "s",
    },
  ];

  const fieldList = [
    {
      title: "Index",
      dataIndex: "stt",
      key: "stt",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value) => {
        return value;
      },
    },
    {
      // title: 'Chọn',
      dataIndex: "choice",
      key: "choice",
      align: "center",
      render: (value, record) => {
        if (checkStatusBySchedule(record)) {
          return (
            <ButtonAction
              text="Choose"
              disabled={
                currentId === record.id ||
                (record.planCode && record.planCode[0] === "KPLUS")
              }
              onClick={() => {
                handleSelectChannel(record);
              }}
            />
          );
        }
      },
    },
  ];

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isDeleteRecord={false}
        fieldFilter={fieldFilter}
        fieldList={fieldList}
        orderName="index"
        orderType="asc"
        apiConfig={{
          list: {
            url: `${config.apiEndpoint.CHANNEL}`,
          },
        }}
        listProps={{
          isPagination: false,
          isNextPrev: false,
        }}
      />
    </div>
  );
};

export default ChannelList;
