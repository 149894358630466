import React from 'react';
import { Button, Intent, Dialog } from '@blueprintjs/core';
import { useSetState } from 'blueprint-hooks-ui';
import VodSeriesList from 'src/components/blocks/VodSeriesList';

import './index.less';

const ButtonChangeVOD = ({ data, handleSetValueForm, vodLength, onChange }) => {
  const [{ isOpen, dialogContent }, setState] = useSetState({
    isOpen: false,
    dialogContent: null
  });

  const handleClose = () => {
    setState({
      isOpen: false,
      dialogContent: null
    })
  };

  return (
    <>
      <Button
        text="Choose"
        intent={Intent.PRIMARY}
        onClick={() => {
          setState({
            isOpen: true,
            dialogContent: (
              <VodSeriesList
                isChangeType
                isAddRecord={false}
                vodLength={vodLength}
                handleCallback={(record) => {
                  handleSetValueForm('parent', record);

                  onChange && onChange(record);

                  handleClose();
                }}
              />
            )
          })
        }}
      />
      <Dialog
        style={{ width: 640 }}
        isOpen={isOpen}
        onClose={handleClose}
        title="Choose series"
        portalClassName="app-timeshift-series-dialog"
      >
        {dialogContent}
      </Dialog>
    </>
  )
};

export default ButtonChangeVOD
