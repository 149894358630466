import { fetchData, request, queryString } from 'blueprint-hooks-ui';
import constants from 'src/utils/constants';
import config from 'src/utils/config';

// store = { dispatch, getState }

export const toggleNotifyBox = (store, status) => {
  store.dispatch({
    type: constants.ACTION_TYPE.TOGGLE_NOTIFY,
    payload: {
      isOpenNotify: status
    }
  })
};

export const getNotificationsUnread = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.NOTIFICATION}/noti/unread`,
      token: dataStorage.accessToken
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.SET_NOTIFY_INFO,
        payload: {
          number: data.count === 0 ? '' : data.count
        }
      })
    }
  });
};

export const getSpotlightSDP = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.SDP_SPOTLIGHT}`,
      token: dataStorage.accessToken,
      data: {
        sort: 2
      }
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_SPOTLIGHT_SDP,
        payload: {
          dataSpotlight: data
        }
      })
    }
  });
};

export const getMenuSDP = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.SDP_MENU}`,
      token: dataStorage.accessToken,
      data: {
        sort: 2
      }
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_MENU_SDP,
        payload: {
          dataHomeMenu: data
        }
      })
    }
  });
};

export const getEventSDP = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.SDP_EVENT}`,
      token: dataStorage.accessToken,
      data: {
        sort: 2
      }
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_EVENT_SDP,
        payload: {
          dataHomeEvent: data
        }
      })
    }
  });
};

export const getNotificationSDP = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.SDP_NOTIFICATION}`,
      token: dataStorage.accessToken,
      data: {
        sort: 2
      }
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_NOTIFICATION_SDP,
        payload: {
          dataHomeNotification: data
        }
      })
    }
  });
};


export const getConfigSDP = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      // apiUrl: config.apiTestLocal14,
      url: `${config.apiEndpoint.SDP_CONFIG}`,
      token: dataStorage.accessToken
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_CONFIG_SDP,
        payload: {
          dataHomeConfig: data
        }
      })
    }
  });
};

export const setConfigSDP = (store, dataStorage, data, callback) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.SDP_CONFIG}`,
      token: dataStorage.accessToken,
      method: 'POST',
      data
    }),
    handleSuccess: data => {
      callback && callback();

      store.dispatch({
        type: constants.ACTION_TYPE.GET_CONFIG_SDP,
        payload: {
          dataHomeConfig: data
        }
      })
    },
    handleFail: () => {
      callback && callback();
    }
  });
};

export const getAdsConfig = (store, dataStorage) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.MENU_ADS}`,
      token: dataStorage.accessToken
    }),
    hideMessage: true,
    handleSuccess: data => {
      store.dispatch({
        type: constants.ACTION_TYPE.GET_ADS_CONFIG,
        payload: {
          dataAds: data
        }
      })
    }
  });
};

export const setAdsConfig = (store, dataStorage, data, callback) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.MENU_ADS}`,
      token: dataStorage.accessToken,
      method: 'POST',
      data
    }),
    handleSuccess: data => {
      callback && callback();

      store.dispatch({
        type: constants.ACTION_TYPE.GET_ADS_CONFIG,
        payload: {
          dataAds: data
        }
      })
    },
    handleFail: () => {
      callback && callback();
    }
  });
};

export const getBroadcastSchedule = (store, dataStorage, params = {}, callback) => {
  fetchData({
    fetchApi: request({
      url: `${config.apiEndpoint.CHANNEL_SCHEDULE}?${queryString.stringify(params)}`,
      token: dataStorage.accessToken,
      method: 'GET'
    }),
    hideMessage: true,
    handleSuccess: data => {
      callback && callback(data);
      if (data.length < 10) {
        store.dispatch({
          type: constants.ACTION_TYPE.GET_BROADCAST_SCHEDULE,
          payload: {
            hasMore: false
          }
        })
      } else {
        store.dispatch({
          type: constants.ACTION_TYPE.GET_BROADCAST_SCHEDULE,
          payload: {
            hasMore: true
          }
        })
      }
    },
    handleFail: () => {
      callback && callback();
    }
  })
}

