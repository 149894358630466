import React from "react";
import ActionManager from "src/commons/ActionManager";
import config from "src/utils/config";
import constants from "src/utils/constants";
import Manager from "./manager";

let instanceId = null;

class Channel extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Channel();
    }
    return instanceId;
  }

  openDialogChannelCatalog(record, dataMapState, callback) {
    this.openDialog({
      dialogWidth: 480,
      dialogData: record,
      dialogTitle: "Add group for channel:" + `${record.title || record.id}`,
      dialogFieldForm: [
        {
          title: "Group channel", // "Nhóm kênh",
          dataIndex: "catalogIds",
          type: constants.FORM_TYPE.CHECKBOX_MULTI,
          isRequired: true,
          dataMap: dataMapState["channelCatalogMap"],
          updateField: {
            key: "catalogs",
            change: (value) => {
              if (value && value instanceof Array) {
                return value.map(
                  (key) => dataMapState["channelCatalogMap"][key]
                );
              }
            },
          },
        },
        {
          title: "Group channel", // "Nhóm kênh",
          dataIndex: "catalogs",
          isRequired: true,
          isHide: true,
        },
      ],
      dialogOnOK: (data, handleCloseDialog) => {
        ActionManager.updateChannel(record, data, () => {
          handleCloseDialog();

          callback();
        });
      },
    });
  }
}

export default Channel;
