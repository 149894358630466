import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider as StoreProvider, rootReducer, rootAction } from "src/store";
import { AuthProvider, i18nInit } from "blueprint-hooks-ui";
import App from "src/App";
import router from "src/router";
import VI from "src/locales/vi";
import EN from "src/locales/en";

import "blueprint-hooks-ui/dist/blueprint-hooks-ui.css";

import "./index.less";

const initialValue = {};

i18nInit({
  translation: {
    vi: VI,
    en: EN,
  },
});

const app = (
  <HashRouter>
    <StoreProvider
      rootReducer={rootReducer}
      rootAction={rootAction}
      initialValue={initialValue}
    >
      <AuthProvider>
        <App>{router}</App>
      </AuthProvider>
    </StoreProvider>
  </HashRouter>
);

ReactDOM.render(app, document.getElementById("app"));
