import React from 'react';
import { messageHandler } from 'blueprint-hooks-ui';
import config from 'src/utils/config';
import Manager from './manager';

let instanceId = null;

class Channel extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Channel();
    }
    return instanceId;
  }

  removeScheduleMetadataId() {

  }

  updateChannelCatalog(record, data, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.CHANNEL_CATALOG}/${record.id}`,
        method: 'PUT',
        data
      },
      callback
    })
  }

  updateChannel(record, data, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.CHANNEL}/${record.id}`,
        method: 'PUT',
        data
      },
      callback
    })
  }

  checkChannel(record, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.CHANNEL}`
      },
      handleSuccess: (dataChannel = {}) => {
        const checkId = dataChannel.find(item => item.id === record.id);

        const checkIndex = dataChannel.find(item => item.index === record.index);

        if (checkId) {
          return messageHandler.warning('Channel ID already exist!')
        }

        if (checkIndex) {
          return messageHandler.warning('Channel Index already exist!')
        }

        callback();
      }
    })
  }
}

export default Channel;
