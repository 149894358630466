import React from "react";
import { Icon } from "@blueprintjs/core";
import { formatSecondToTime } from "src/helpers";

import "./index.less";

const DailyTimeBox = ({ time = {} }) => {
  return (
    <div className="app-daily-time-box">
      {(time.showTime || time.showTime === 0) && (
        <>
          <div className="app-daily-time-item">
            <label title="Giờ hiển thị">Show Time</label>
            <span>{formatSecondToTime(time.showTime)}</span>
          </div>
          <div className="app-daily-time-arrow">
            <Icon icon="arrow-right" />
          </div>
        </>
      )}
      {/* {
        (time.startTime || time.startTime === 0)
        &&
        <>
          <div className="app-daily-time-item">
            <label title="Giờ bắt đầu">Giờ BĐ</label>
            <span>{formatSecondToTime(time.startTime)}</span>
          </div>
          <div className="app-daily-time-arrow">
            <Icon icon="arrow-right"/>
          </div>
        </>
      } */}
      {(time.endTime || time.endTime === 0) && (
        <div className="app-daily-time-item">
          <label title="Giờ kết thúc">End Time</label>
          {formatSecondToTime(time.endTime)}
        </div>
      )}
    </div>
  );
};

export default DailyTimeBox;
