import React from 'react';
import moment from 'moment';
import config from 'src/utils/config';
import Manager from './manager';

let instanceId = null;

class SDP extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new SDP();
    }
    return instanceId;
  }
}

export default SDP;
