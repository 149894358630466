import Global from "src/commons/classes/app/global";
import User from "src/commons/classes/app/user";
import Metadata from "src/commons/classes/app/metadata";
import Channel from "src/commons/classes/app/channel";
import SDP from "src/commons/classes/app/sdp";
import ActionManager from "src/commons/ActionManager";
import { getAllFunctionByClass } from "src/helpers";

export default {
  init: ({ dataStorage, auth, router, permissions }) => {
    ActionManager.init({ dataStorage, auth });

    Global.instance().init({ dataStorage, auth, router, permissions });
    User.instance().init({ dataStorage, auth, router, permissions });
    Metadata.instance().init({ dataStorage, auth, router, permissions });
    Channel.instance().init({ dataStorage, auth, router, permissions });
    SDP.instance().init({ dataStorage, auth, router, permissions });
  },
  initDialog: ({ handleOpenDialog, handleCloseDialog, refreshData }) => {
    Global.instance().initDialog({
      handleOpenDialog,
      handleCloseDialog,
      refreshData,
    });
    User.instance().initDialog({
      handleOpenDialog,
      handleCloseDialog,
      refreshData,
    });
    Metadata.instance().initDialog({
      handleOpenDialog,
      handleCloseDialog,
      refreshData,
    });
    Channel.instance().initDialog({
      handleOpenDialog,
      handleCloseDialog,
      refreshData,
    });
    SDP.instance().initDialog({
      handleOpenDialog,
      handleCloseDialog,
      refreshData,
    });
  },
  ...getAllFunctionByClass(Global),
  ...getAllFunctionByClass(User),
  ...getAllFunctionByClass(Metadata),
  ...getAllFunctionByClass(Channel),
  ...getAllFunctionByClass(SDP),
};
