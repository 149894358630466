import React from "react";
import config from "src/utils/config";
import Manager from "./manager";

let instanceId = null;

class User extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new User();
    }
    return instanceId;
  }

  changePassword(uid, data, callback) {
    this.fetch({
      apiConfig: {
        // apiUrl: this.apiUrl,
        url: `${config.apiEndpoint.USER}/change-password/${uid}`,
        method: "POST",
        data,
      },
      hideMessage: false,
      callback,
    });
  }

  resetPassword(uid, callback) {
    this.fetch({
      apiConfig: {
        // apiUrl: this.apiUrl,
        url: `${config.apiEndpoint.USER}/reset-password/${uid}`,
        method: "POST",
      },
      callback,
    });
  }

  deleteUser(uid, callback) {
    this.fetch({
      apiConfig: {
        // apiUrl: this.apiUrl,
        url: `${config.apiEndpoint.USER}/${uid}`,
        method: "DELETE",
      },
      hideMessage: false,
      callback,
    });
  }

  changeStatusUser(uid, data, callback) {
    this.fetch({
      apiConfig: {
        // apiUrl: this.apiUrl,
        url: `${config.apiEndpoint.USER}/${uid}`,
        method: "PUT",
        data,
      },
      hideMessage: false,
      callback,
    });
  }

  getDataMapRole(data, callback) {
    this.fetch({
      apiConfig: {
        url: config.apiEndpoint.ROLE,
        data,
      },
      // hideMessage: true,
      handleSuccess: (data = {}) => {
        callback && callback(data);
      },
    });
  }
}

export default User;
