import React, { Component } from 'react';
import classNames from 'classnames';
import { ContextMenu, Menu, MenuItem, Button, Intent, H3, ButtonGroup } from '@blueprintjs/core';
import { getAssetImageUrl } from 'src/helpers';

import './index.less';

const PeopleTextBox = ({
                     prefixCls = 'app-people',
                     className,
                     dataList = [],
                     value = []
                   }) => {
  return (
    <div className={classNames({
      [`${prefixCls}-box`]: value.length > 0
    }, className)}>

    </div>
  )
};

export default PeopleTextBox
