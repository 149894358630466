import moment from "moment";
import { capitalizeFirstLetter } from "src/helpers";

import "moment/locale/en-gb";
import i18next from "i18next";

const defaultErrorMessageTranscode = "Có lỗi xảy ra.";
// function trans(param) {
//   return i18next.t(param);
// }
export const channelStatusMap = {
  0: "Hide", // "Ẩn",
  1: "Show", //"Hiện",
  // 2: "Show Timer", //"Hẹn giờ hiển thị",
};
export const platformMap = {
  1: "iOS",
  2: "Android",
  4: "Tizen",
  5: "WebOS",
  6: "Web",
};

export const historyTypeMap = {
  1: "LOGIN",
  2: "LOGOUT",
  3: "BLOCK",
  4: "UNBLOCK",
  5: "KICK",
  6: "DELETE ACCOUNT"
};

export const deviceMap = {
  1: "iOS",
  2: "Android",
  // 3: "Web",
  // 9: 'CMS'
};

export const choiceSourceMap = {
  "": "Không",
  low: "low",
  akamai: "akamai",
  h265: "h265",
  mb: "mb",
  "720p": "720p",
};

export const sourceMap = {
  low: "Low",
  akamai: "Akamai",
  h265: "H265",
  mb: "Mb",
  "720p": "720p",
};

export const providerMap = {
  sigma: "Sigma",
  drmtoday: "DRM Today",
};

export const productMap = {
  TVOD: "TVOD - Thuê lẻ",
  SVOD: "SVOD - Mua gói",
  AVOD: "AVOD - Miễn phí",
};

export const vodStateMap = {
  release: "Xuất bản",
  censor: "Kiểm duyệt",
  schedule: "Hẹn giờ",
  expired: "Hết hạn",
  hidden: "Không hiển thị",
};

export const vodDefinitionMap = {
  HD: "HD",
  SD: "SD",
  FullHD: "Full HD",
  "4K": "4K",
  "8K": "8K",
};

export const vodRatingMap = {
  // "-1": "Unrate",
  0: "G",
  1: "PG",
  2: "R-13",
  3: "R-16",
  4: "R-18",
};

export const vodSubTypeMap = {
  spotlight: "Spotlight",
  home: "Home",
  featured: "Featured",
};



export const timeline = {
  [`${moment().subtract(7, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(7, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(6, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(6, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(5, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(5, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(4, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(4, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(3, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(3, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(2, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(2, "day").format("dddd DD/MM")}`
  ),
  [`${moment().subtract(1, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(1, "day").format("dddd DD/MM")}`
  ),
  [`${moment().format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().format("dddd DD/MM")}`
  ),
  [`${moment().add(1, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(1, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(2, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(2, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(3, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(3, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(4, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(4, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(5, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(5, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(6, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(6, "day").format("dddd DD/MM")}`
  ),
  [`${moment().add(7, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(7, "day").format("dddd DD/MM")}`
  ),
};

export const dateMap = {
  [`${moment().subtract(7, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(7, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(6, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(6, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(5, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(5, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(4, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(4, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(3, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(3, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(2, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().subtract(2, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().subtract(1, "day").format("YYYYMMDD")}`]: "Yesterday",
  [`${moment().format("YYYYMMDD")}`]: "Today",
  [`${moment().add(1, "day").format("YYYYMMDD")}`]: "Tomorrow",
  [`${moment().add(2, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(2, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(3, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(3, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(4, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(4, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(5, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(5, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(6, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(6, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(7, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(7, "day").format("dddd (DD/MM)")}`
  ),
};

export const dateFromTodayMap = {
  [`${moment().format("YYYYMMDD")}`]: "Today",
  [`${moment().add(1, "day").format("YYYYMMDD")}`]: "Tomorrow",
  [`${moment().add(2, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(2, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(3, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(3, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(4, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(4, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(5, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(5, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(6, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(6, "day").format("dddd (DD/MM)")}`
  ),
  [`${moment().add(7, "day").format("YYYYMMDD")}`]: capitalizeFirstLetter(
    `${moment().add(7, "day").format("dddd (DD/MM)")}`
  ),
};

// export const stateVodMap = {
//   release: 'Xuất bản',
//   censor: 'Kiểm duyệt',
//   schedule: 'Hẹn giờ',
//   expired: 'Hết hạn',
//   hidden: 'Không hiển thị'
// };

// export const stateVodMap = {
//   0: 'Mới',
//   1: 'Xuất bản',
//   2: 'Gỡ xuống',
//   3: 'Hết hạn'
// };

export const stateVodMap = {
  0: "Draft", // "Nháp",
  1: "Pending", //"Chờ duyệt",
  // 2: "Submit", // "Chờ xuất bản",
  3: "Published", //"Đã xuất bản",
  4: "Expired", //"Hết hạn",
  5: "Take down", //"Hạ",
};

export const stateVodMapText = {
  MOI: 0,
  XUAT_BAN: 1,
  GO_XUONG: 2,
  HET_HAN: 3,
};

export const subTypeVodMap = {
  spotlight: "Spotlight",
  home: "Home",
  featured: "Featured",
};

export const scopeMap = {
  //'admin', 'leader', 'btv', 'ctv'
  // admin: 'Admin',
  // leader: 'Content Manager',
  // btv: 'Editor',
  // saleMarketing: 'Sale & Marketing',
  admin: {
    admin: "Admin",
    leader: "Content Manager",
    ctv: "Sale & Marketing"
  },
  leader: {
    btv: "Editor",
    // ctv: 'CTV'
  },
};

export const statusMap = {
  // 0: "Deactivate",
  1: "Active",
  2: "Ban",
};

export const transcodeState = {
  0: "New import", // "Mới import",
  1: "Transcode now", // "Đang transcode",
  2: "Transcode finished", //"Transcode xong",
  // 3: 'Đang chờ duyệt',
  // 4: 'Xuất bản',
  // 5: 'Hết hạn',
  // '-1': 'Không khả dụng',
  "-2": "error", //"Lỗi",
};

export const submitType = {
  create: "create",
  edit: "edit",
};

export const eventStatusMap = {
  init: "Khởi tạo",
  schedule: "Chưa bắt đầu",
  pre: "Đang chạy video banner",
  post: "Banner kết thúc sự kiện",
  running: "Đang diễn ra",
  stop: "Đang dừng",
  start_error: "Bắt đầu lỗi",
  error: "Lỗi",
  ended: "Đã kết thúc",
};

export const eventTypeMap = {
  1: "Kênh",
  3: "Trực tiếp",
};

export const stateVod = {
  0: "Mới",
  1: "Xuất bản",
  2: "Gỡ xuống",
};

export const stateGlobal = {
  0: "Deactivated", // "Ẩn",
  1: "Activated",
};

export const statusNode = {
  0: "Ẩn",
  1: "Hiện",
  // 2: "Hẹn giờ hiển thị",
};

export const mapColorProfile = {
  576: "#d99e0b",
  480: "#2965cc",
  360: "#ff6e4a",
  720: "#3dcc91",
  1080: "#48aff0",
};

export const entityName = {
  channel: "Channel",
  node: "Node",
  metadata: "Metadata",
  transcode: "Transcode",
  people: "People",
  genre: "Genre",
  nation: "Nation",
  plan: "Plan",
  studio: "Studio",
  label: "Label",
  approve: "Approve",
  zone: "Zone",
  "sdp-server": "SDP",
  dashboard: "Dashboard",
  notification: "Notification",
  user: "User",
  authorization: "Authorization",
  upload: "Upload",
  taxonomy: "Taxonomy",
  statistic: "Statistic",
  "cms-menu": "Menu",
  instructor: "Instructor",
  payment: "Payment",
  "user-customer": "User Customer"
};

export const methodName = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const vodTypeMap = {
  0: "Movie",
  1: "Series",
  2: "Episode",
};

export const resourcePathMap = {
  "/metadata/:id/publish": "Xuất bản",
  "/metadata/:id/transcode-state": "Quản lý transcode",
  "/metadata/statistics-transcode": "Thống kê transcode",
  "/metadata/check-exist/:id": "Kiểm tra ID metadata",
  "/metadata(.*)": "Quản lý metadata",
  "/metadata/:id": "Chi tiết metadata",
  "/metadata": "Xem danh sách metadata",
  "/channel(.*)": "Quản lý channel",
  "/user(.*)": "Quản lý user",
  "/user/role": "Quản lý role",
  "/node-selector(.*)": "Quản lý node nội dung",
  "/authorization/permission": "Quản lý permission",
  "/authorization/resource": "Quản lý resource",
  "/genre(.*)": "Quản lý thể loại",
  "/nation(.*)": "Quản lý quốc gia",
  "/people(.*)": "Quản lý people",
  "/studio(.*)": "Quản lý đơn vị cung cấp",
  "/plan(.*)": "Quản lý gói cước",
  "/label(.*)": "Quản lý label",
  "/notification(.*)": "Quản lý thông báo",
  "/zone(.*)": "Quản lý zone",
};

export const ecTranscode = {
  ASYNC_PROFILE: defaultErrorMessageTranscode,
  ASYNC_STREAM: defaultErrorMessageTranscode,
  BROKEN_PIPE: defaultErrorMessageTranscode,
  CODEC_NOTFOUND: defaultErrorMessageTranscode,
  DECODER_NOTFOUND: defaultErrorMessageTranscode,
  DEMUXER_NOTFOUND: defaultErrorMessageTranscode,
  ENCODER_NOTFOUND: defaultErrorMessageTranscode,
  END_OF_FILE: defaultErrorMessageTranscode,
  FIELD_REQUIRED: defaultErrorMessageTranscode,
  FILTER_NOTFOUND: defaultErrorMessageTranscode,
  GOP_INVALID: defaultErrorMessageTranscode,
  GPU_INVALID: defaultErrorMessageTranscode,
  INPUT_REQUIRED: defaultErrorMessageTranscode,
  INPUT_TIMEOUT: defaultErrorMessageTranscode,
  INTERNAL_ERROR: defaultErrorMessageTranscode,
  INVALID_ARGUMENT: defaultErrorMessageTranscode,
  LIB_NOTFOUND: defaultErrorMessageTranscode,
  MUXER_NOTFOUND: defaultErrorMessageTranscode,
  NAME_REQUIRED: defaultErrorMessageTranscode,
  OPTION_NOTFOUND: defaultErrorMessageTranscode,
  OUTPUT_DUPLICATE: defaultErrorMessageTranscode,
  OUTPUT_REQUIRED: defaultErrorMessageTranscode,
  OUTPUT_TIMEOUT: defaultErrorMessageTranscode,
  PORT_USED: defaultErrorMessageTranscode,
  PROFILE_REQUIRED: defaultErrorMessageTranscode,
  PROTOCOL_NOTFOUND: defaultErrorMessageTranscode,
  RTMP_HEVC: defaultErrorMessageTranscode,
  SCAN_ERROR: defaultErrorMessageTranscode,
  STREAM_NOT_FOUND: defaultErrorMessageTranscode,
  TARGET_REQUIRED: defaultErrorMessageTranscode,
  TOKEN_INVALID: defaultErrorMessageTranscode,
  UNKNOWN_ERROR: defaultErrorMessageTranscode,
  URL_READ_ERROR: defaultErrorMessageTranscode,
  CANNOT_OPEN_CONNECTION: "Không thể mở kết nối",
  CANT_READ_INPUT: "Không đọc được video nguồn",
  CONNECTION_REFUSED: "Kết nối bị chặn",
  CONNECTION_RESET_BY_PEER: "Kết nối bị ngắt",
  CONNECTION_TIMEOUT: "Kết nối quá hạn",
  ERROR_INITIALIZING_OUTPUT_STREAM: "Không thể ghi thư mục đích",
  ERROR_WHEN_GET_DRM: "Không thể khởi tạo Drm",
  INPUT_OUTPUT_ERROR: "Có lỗi khi đọc/ghi",
  IO_ERROR: "Có lỗi khi đọc/ghi",
  NAME_EXISTED: "Thư mục đích đang sử dụng",
  NO_ROUTE_TO_HOST: "Không thể kết nối",
  OUTPUT_EXISTED: "Thư mục đích đã tồn tại",
  UNABLE_TO_OPEN_RESOURCE: "Có lỗi khi đọc/ghi",
  VIDEO_TIME_TOO_LONG: "Video đích quá dài(>5h)",
  VIDEO_TIME_ZERO: "Video đích quá ngắn",
};

export const typeNode = {
  normal: "Normal",
  // ref: "Ref",
  // mix: "Mix",
  // label: "Label",
};

export const subTypeNode = {
  root: "Root",
  home: "Home",
  generic: "Generic",
  featured: "Featured",
  broad: "Broad",
  curated: "Curated",
  livechannellist: "Live",
  downloadable: "Downloadable",
};

export default {
  typeNode,
  subTypeNode,
  ecTranscode,
  channelStatusMap,
  statusNode,
  mapColorProfile,
  stateGlobal,
  stateVod,
  submitType,
  transcodeState,
  subTypeVodMap,
  platformMap,
  platformCastMap: {
    1: "iOS",
    2: "Android",
    4: "Tizen",
    5: "WebOS",
    // 6: 'Web'
  },
  loginTypeMap: {
    1: 'User',
    2: 'Guest'
  },
  deviceMap,
  choiceSourceMap,
  productMap,
  vodStateMap,
  vodDefinitionMap,
  vodRatingMap,
  vodSubTypeMap,
  dateMap,
  dateFromTodayMap,
  stateVodMap,
  stateVodMapText,
  scopeMap,
  scopeFullMap: {
    admin: "Admin",
    leader: "Content Manager",
    btv: "Editor",
    ctv: "Sale & Marketing"
  },
  statusMap,
  eventStatusMap,
  eventTypeMap,
  sourceMap,
  providerMap,
  entityName,
  methodName,
  vodTypeMap,
  resourcePathMap,
  timeline,
  timeType: {
    // m: 'Minute',
    h: 'Hour',
    d: 'Day',
    w: 'Week',
    M: 'Month'
  },
  checkboxMap: {
    0: "Deactivated", // "Tắt",
    1: "Activated", //"Bật",
  },
  menuTypeMap: {
    2: "Catalog", //"Node nội dung",
    6: "Kênh",
    71: "Sự kiện",
    72: "Đang phát sóng",
    9: "Gói cước",
    10: "Tài khoản",
  },
  homeSdpTypeMap: {
    1: "Text",
    2: "Image",
  },
  targetTypeMap: {
    "-1": "Bỏ qua",
    0: "Trang chủ",
    1: "Kênh",
    2: "VOD",
    3: "Sự kiện luồng ON",
    6: "Danh sách kênh",
    7: "Đăng nhập",
    9: "Gói cước",
    10: "Tài khoản",
    11: "Node nội dung",
    12: "Url",
  },
  homeEventTargetTypeMap: {
    1: "Kênh",
    3: "Sự kiện",
  },
  dailyDayMap: {
    1: "Mo",
    2: "Tu",
    3: "We",
    4: "Th",
    5: "Fr",
    6: "Sa",
    7: "Su",
    // 1: "T2",
    // 2: "T3",
    // 3: "T4",
    // 4: "T5",
    // 5: "T6",
    // 6: "T7",
    // 7: "CN",
  },
  stateVodPath: {
    0: "draft",
    1: "pending",
    // 2: "submit",
    3: "published",
    4: "expired",
    5: "takedown",
  },
  vodPath: {
    0: "single",
    1: "series",
    2: "series/episode",
    timeshift: "timeshift",
  },
  vodFromMap: {
    sync: "Đồng bộ",
    tdps: "TDPS",
    dms: "DMS",
    timeshift: "Timeshift",
    cms: "CMS",
  },
  channelEventMap: {
    highlight: "Đang phát sóng",
    live: "Trực tiếp",
    normal: "Thường",
  },
  epgStatusMap: {
    ok: "OK",
    not_found: "Không Có LPS",
    same_time: "Chương trình lồng nhau",
    too_long_time: "Chương trình quá dài",
  },
  inputONList: ["ON1", "ON2", "ON3", "ON4", "ON5", "ON6"],
  categoriesList: [
    "Đời Sống",
    "Phóng Sự",
    "Esport",
    "Kênh giải trí",
    "History",
    "Lịch sử",
    "Tin Tức",
    "NewRelease",
    "Kỹ Năng Sống",
    "Kênh VTVcab",
    "Xuyên không",
    "Mẹo Vặt",
    "Phiêu lưu mạo hiểm",
    "Tin tức",
    "Thể loại khác",
    "Âm nhạc",
    "Kiếm hiệp",
    "Tâm lý",
    "Suspense",
    "Cổ trang",
    "Nhóm kênh phổ biến",
    "Criminal",
    "ThreeDayRental",
    "Uncensored",
    "Truyền Hình Thực Tế",
    "Hình sự",
    "Kênh thiếu nhi",
    "Khoa học",
    "Ly kỳ",
    "Cinema",
    "Kênh quốc tế",
    "Nhóm kênh HTV",
    "Có đầu thu xem miễn phí",
    "Reminder",
    "Kênh truyền hình",
    "Chinh Phục",
    "Nhóm kênh SCTV",
    "Thời Trang",
    "Animation",
    "Family",
    "Gameshow",
    "Gia đình",
    "Lãng mạn",
    "Điều tra tội phạm",
    "Fitness",
    "Trang điểm",
    "Tuổi teen",
    "Công nghệ",
    "Kênh thể thao",
    "Ẩm thực",
    "Giả tưởng",
    "Võ thuật",
    "Khám Phá",
    "Kinh dị",
    "Khoa học giả tưởng",
    "Sport",
    "Anime",
    "Thriller",
    "Giáo dục",
    "4K",
    "Kênh mới",
    "Nhóm ưu đãi",
    "TalkShow",
    "Musical",
    "TV show",
    "Sitcom",
    "Du lịch",
    "Talk show",
    "Romance",
    "Drama",
    "Nhóm kênh nước ngoài",
    "Library",
    "Hoạt hình",
    "Kiến Trúc",
    "Hài",
    "Breaking News",
    "highlight",
    "Documentary",
    "Nhóm kênh tổng hợp",
    "Thiếu nhi",
    "Tin Nóng",
    "Trải nghiệm",
    "Kênh tin tức",
    "Thư giãn",
    "Hậu Trường",
    "Tình cảm",
    "Kênh phim",
    "Nhóm kênh VTC",
    "Nhóm kênh MAX",
    "Kênh VTV",
    "AFF Cup",
    "Animation-Comedy",
    "Hành động",
    "Thể thao",
    "Giải trí",
    "Phiêu lưu",
    "SciFi-Horror",
    "SciFi-Adventure",
    "Nghệ thuật",
  ],
  displayTypeMap: {
    1: "Landscape",
    2: "Portrait",
    3: "Cycle"
  },
  dataPushNotification: {
    // 1: "Channel",
    2: "Video",
    3: "Magazine"
  },
  filterPushNotificationMap: {
    0: "Pending",
    1: "Success",
  },
  searchCustomerMap: {
    1: "Search By Name",
    2: "Search By Email",
  },
  countries: {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia And Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island & Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KR: "Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And Sandwich Isl.",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis And Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
  platformPaymentMap: {
    1: "IOS_MOBILE",
    2: "ANDROID_MOBILE",
  },
  timeTypeMap: {
    hour: "Hour",
    day: "Day",
    week: "Week",
    month: "Month"
  },
  enableMap: {
    1: 'On',
    0: 'Off'
  },
  metadataProductTypeMap: {
    AVOD: 'AVOD-FREE',//FREE
    SVOD: 'SVOD'
  },
  screenTypeMap: {
    magazine: 'MAGAZINE',
    vod: 'VIDEO',
    header: 'VIDEO HEADER',
    magazine_header: "MAGAZINE HEADER"
  },
  screenTypeMapCategory: {
    vod: 'VIDEO RAIL',
    header: 'VIDEO HEADER',
    magazine: 'MAGAZINE RAIL',
    magazine_header: "MAGAZINE HEADER",
  },
  stateSpotlightMap: {
    1: "Running",
    2: "Pending",
    3:"Initial",
    4: "Finish"
  }
};
