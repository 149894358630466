import React, { Component } from "react";
import {
  BpBase,
  formatDateTime,
  confirmHandler,
  ButtonAction,
  useRouter,
} from "blueprint-hooks-ui";
import constants from "src/utils/constants";
import config from "src/utils/config";

import "./index.less";

const VodSeriesList = ({
  dataStorage,
  dataDetail,
  isTimeshift,
  isChangeType,
  handleCallback,
  isSelect,
  handleSelect = () => {
  },
  isAddRecord = true,
  vodLength
}) => {
  const router = useRouter();

  const fieldFilter = [
    {
      title: "Title", //"Tên VOD",
      dataIndex: "s",
    },
  ];

  const fieldList = ({ handleCloseDialog, refreshData }) => {
    let fieldExtra = [];

    if (isChangeType || isTimeshift) {
      fieldExtra = [
        {
          title: "Choose", // 'Chọn',
          dataIndex: "choice",
          key: "choice",
          align: "center",
          render: (value, record) => {
            const length = record.totalNoOfEpisodes - record.episodeNumber;

            if (record.episodeNumber !== record.totalNoOfEpisodes && vodLength <= length) {
              return (
                <ButtonAction
                  title=""
                  icon="add"
                  onClick={() => {
                    handleCallback(record);
                  }}
                />
              );
            }
          },
        },
      ];
    } else if (isSelect) {
      fieldExtra = [
        // {
        //   title: 'Danh sách tập',
        //   dataIndex: 'episodeList',
        //   key: 'episodeList',
        //   align: 'center',
        //   render: (value, record) => {
        //     return (
        //       <ButtonAction
        //         title=""
        //         icon="list"
        //         onClick={() => {
        //           AppManager.instance().openDialogEpisodeList(record, dataDetail, isSelect, handleSelect, () => {
        //             AppManager.instance().openMetadataTab();
        //           });
        //         }}
        //       />
        //     )
        //   }
        // },
        {
          title: "Select",
          dataIndex: "choice",
          key: "choice",
          align: "center",
          sorter: false,
          render: (value, record) => {
            if (record.state === constants.STATE_VOD_TYPE.PUBLISHED) {
              return (
                <ButtonAction
                  title=""
                  icon="add"
                  onClick={() => {
                    handleSelect(record);
                  }}
                />
              );
            }
          },
        },
      ];
    } else {
      fieldExtra = [
        // {
        //   title: 'Danh sách tập',
        //   dataIndex: 'episodeList',
        //   key: 'episodeList',
        //   align: 'center',
        //   render: (value, record) => {
        //     return (
        //       <ButtonAction
        //         title=""
        //         icon="list"
        //         onClick={() => {
        //           AppManager.instance().openDialogEpisodeList(record, dataDetail);
        //         }}
        //       />
        //     )
        //   }
        // },
        {
          title: "", // "Chọn",
          dataIndex: "choice",
          key: "choice",
          align: "center",
          render: (value, record) => {
            if (record.episodeNumber !== record.totalNoOfEpisodes) {
              return (
                <ButtonAction
                  title=""
                  icon="add"
                  onClick={() => {
                    confirmHandler({
                      title: "Xác nhận",
                      content: (
                        <div>
                          Do you want to move this content to series <b>{record.title}</b>
                          ?
                        </div>
                      ),
                      onOk: () => {
                        router.push(
                          "/media/vod/ingest/detail/series/" +
                          record.id +
                          "/" +
                          encodeURIComponent(dataDetail.id)
                        );
                      },
                    });
                  }}
                />
              );
            }
          },
        },
      ];
    }

    return [
      {
        title: "Index",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Title", // "Tên VOD",
        dataIndex: "title",
        key: "title",
        render: (value) => {
          return value;
        },
      },
      {
        title: "Episode",
        dataIndex: "episodeNumber",
        key: "episodeNumber",
        align: "right",
        render: (value, record) => {
          return (
            <div>
              {(record.episodeNumber || 0) +
              "/" +
              (record.totalNoOfEpisodes || 0)}
            </div>
          );
        },
      },
      ...fieldExtra,
    ];
  };

  const listPrams = {};

  if (isSelect) {
    listPrams.state = constants.STATE_VOD_TYPE.PUBLISHED;
  }

  return (
    <BpBase
      hasInView
      isTargetBlank
      isAddRecord={isAddRecord}
      isDeleteRecord={false}
      // isFetchList={false}
      fieldFilter={fieldFilter}
      fieldList={fieldList}
      apiConfig={{
        list: {
          url: config.apiEndpoint.METADATA,
          params: {
            isDeleted: 0,
            type: constants.VOD_TYPE.BO,
            screenType: "vod",
            ...listPrams,
          },
        },
      }}
      filterProps={{
        addRecordTo: "/media/vod/series/create",
        addRecordTargetBlank: isTimeshift,
      }}
    />
  );
};

export default VodSeriesList;
