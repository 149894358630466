import constants from "src/utils/constants";

const apiEndpoint = {
  // AUTH: '/auth',
  AUTH: "/authorization",
  PERMISSION: "/authorization/permission",
  GRANT: "/authorization/grant",
  RESOURCE: "/authorization/resource",
  USER: "/user",
  ROLE: "/user/role",
  CHANNEL: "/channel",
  CHANNEL_CATALOG: "/channel/catalog",
  CHANNEL_SCHEDULE: "/channel/schedule",
  CHANNEL_EVENT: "/channel/event",
  CHANNEL_SCHEDULE_EVENT: "/channel/schedule/event",
  CHANNEL_SCHEDULE_TIMESHIFT: "/channel/schedule/timeshift",
  CHANNEL_EXPORT: "/channel/export",
  METADATA: "/metadata",
  METADATA_TIMESHIFT: "/metadata/timeshift",
  METADATA_SINGLE: "/metadata/single",
  METADATA_SERIES: "/metadata/series",
  METADATA_TRANSCODE: "/metadata/transcode",
  METADATA_EPISODE: "/metadata/episode",
  IMPORT_METADATA: "/metadata/import-metadata",
  GET_NAME_SPOTLIGHT: "/metadata/getMetadataByIds",
  APPROVE: "/approve",
  NOTIFICATION: "/notification",
  NODE_SELECTOR: "/node-selector",
  CATEGORY: "/category/category",
  INSTRUCTOR: '/category/instructor',
  GENRE: "/genre",
  NATION: "/nation",
  PEOPLE: "/people",
  PLAN: "/metadata/plan",
  STUDIO: "/studio",
  LABEL: "/label",
  CUSTOMER_USER: "/frontend/user/cms/user",
  WORD_FILTER: "/replace-word/word",
  SESSION_CUSTOMER_USER: "/frontend/user/cms/user/session",
  BLOCK_CUSTOMER_USER: "/frontend/user/cms/block",
  HISTORY_CUSTOMER_USER: "/frontend/user/cms/user/history",
  KICK_SESSION_CUSTOMER: "/frontend/user/cms/user/session",
  CUSTOMER_SESSION: "/frontend/user/cms/user/session",
  STAT_TRANSCODE: "statistics-transcode",
  DASHBOARD: "/dashboard",
  TRANSCODE_PROGRESS: "/transcode/getProgress",
  TRANSCODE_MANAGER: "/transcode",
  ZONE: "/zone",
  ALL_USER: "/user/all",
  SDP_MENU: "/menu/menu",
  SDP_SPOTLIGHT: "/menu/spotlight",
  SDP_EVENT: "/menu/event",
  SDP_EVENT_ON: "/menu-jungomenu/on-event",
  SDP_CONFIG: "/menu/config",
  SDP_NOTIFICATION: "/notification/notification",
  STATISTIC: "/statistic",
  MENU_ADS: "/menu/ads",

  PAYMENT_PLAN: "/payment/plan/cms",
  PAYMENT_PLAN_DURATION: "/payment/planDuration/cms",
  TRANSACTION: "/payment/codePlan/cms",
  TRANSACTION_CREATE: "/payment/codePlan/cms/createItems",
  USER_PLAN_CHECK: "/payment/userPlan/cms",
  USER_PLAN_LIST: "/payment/userPlan/frontend",
  TRANSACTION_STATISTIC: "/payment/codePlan/statisticVoucherCode",
  ADS: "/ads",
  
};

const path = {
  grantRoleId: "/authorization/grant/:roleId",
  userChangePassword: "/user/change-password/:uid",
  userResetPassword: "/user/reset-password/:uid",
  userLogActivity: "/user/:uid/activity",
  metadata: "/metadata",
  metadataId: "/metadata/:id",
  metadataDuplicate: "/metadata/duplicate",
  metadataIdPublish: "/metadata/:id/publish",
  metadataIdTakedown: "/metadata/:id/takedown",
  metadataIdSubmit: "/metadata/:id/submit",
  metadataDelete: "/metadata/isDelete",
  channelScheduleByDay: "/channel/schedule/:channelId/:day",
  sdpHome: "/sdp-server/home",
  sdpOnEvent: "/sdp-server/on-event/events-cms",
  sdpNotification: "/sdp-server/notification",
  sdpSpotlight: "/menu/spotlight",
  sdpCategory: "/menu/menu",
  sdpEvent: "/sdp-server/event",
  sdpMenu: "/sdp-server/menu",
  sdpConfig: "/sdp-server/config",
  channelEPG: "/channel/epg",
  configMovieScreen: "/menu/config-movie-screen",
  notification: "/notification/notification",
  taxonomy: "/category/category",
  statisticUserNew: "/statistic/user/new",
  statisticUserActive: "/statistic/user/active",
  statisticUserCCU: "/statistic/user/ccu",
  statisticDeviceNew: "/statistic/device/new",
  statisticDeviceActive: "/statistic/device/active",
  statisticChannelView: "/statistic/channel/view",
  statisticChannelViewTop: "/statistic/channel/view-top",
  statisticVodView: "/statistic/vod/view",
  statisticVodViewTop: "/statistic/vod/view-top",
  PAYMENT_PLAN: "/plan",
  PAYMENT_TRANSACTION: "/codePlan",
  INSTRUCTOR: '/category/instructor',
  cmsMenuRole: '/cms-menu/role',
  cmsStatisticMenu: '/cms-menu/statistic',
  cmsMenuCustomer: '/customer',
  approveRejectMetadata: '/metadata/:id/action'
};

export default {
  pageTitle: "Black Belt+",
  homeUrl: "/",
  redirectUrl: "/login",
  // assetUrl: "https://assets.jungotv.app",
  assetUrl: "https://assets-blackbelt.jungotv.app",
  baseImageUrl: "https://rsstv.gviet.vn/sctv",
  openPages: ["/login"],
  cdnUrl: "https://cdnwebonplay.gviet.vn",
  playerClientId: "5b4e9f81cac95235fe7c1058",
  // eventClientId: '5b51671fcac95235fe85cbf7',

  channelBlackListEPG: [
    "kplus-1-hd",
    "kplus-pm-hd",
    "kplus-ns-hd",
    "kplus-pc-hd",
  ],
  eventClientId: "",
  secretKeyUploadImage: "rPJwdQ2jTSbP",
  secretKeyEPG: "PGT084lPh@!v5T",
  assetKey: "relativePath",
  checkVodId: "metadataId",
  timeBuffer: 1 / 6,
  channelDefault: "VTV1_HD",
  dataMenu: [
    {
      text: "Dashboard",
      icon: "dashboard",
      route: "dashboard",
    },
    {
      text: "Config Movie Screen",
      icon: "cog",
      route: "config-screen",
      resource: constants.RESOURCE_NAME.SDP,
      path: path.configMovieScreen,
    },
    {
      text: "Manage Content",
      icon: "video",
      route: "media",
      resource: constants.RESOURCE_NAME.CMS_MENU,
      path: "/cms-menu/manage-content",
      childrens: [
        {
          text: "Magazine",
          route: "magazine",
          resource: constants.RESOURCE_NAME.METADATA,
        },
        {
          text: "Video",
          route: "vod",
          childrens: [
            // {
            //   text: "Transcode",
            //   route: "ingest",
            // },
            {
              text: "Movie",
              route: "single",
              resource: constants.RESOURCE_NAME.METADATA,
            },
            {
              text: "Series",
              route: "series",
              resource: constants.RESOURCE_NAME.METADATA,
            },
            {
              text: "Episode",
              route: "series/episode",
              resource: constants.RESOURCE_NAME.METADATA,
              hide: true,
            },
            
          ],
        },
        {
          text: "Trash",
          route: "archive",
          resource: constants.RESOURCE_NAME.METADATA,
        },
        {
          text: "Catalog",
          route: "node-selector",
          resource: constants.RESOURCE_NAME.NODE,
          path: apiEndpoint.NODE_SELECTOR,
        },
        // {
        //   text: "Live channel",
        //   route: "channel",
        //   resource: constants.RESOURCE_NAME.CHANNEL,
        // },
        {
          text: "Taxonomy",
          route: "taxonomy",
          resource: constants.RESOURCE_NAME.TAXONOMY,
          path: path.taxonomy,
        },
        {
          text: "Instructor",
          route: "instructor",
          resource: constants.RESOURCE_NAME.INSTRUCTOR,
          path: apiEndpoint.INSTRUCTOR
        }
      ],
    },
    {
      text: "Review content",
      route: "approve",
      icon: "endorsed",
      resource: constants.RESOURCE_NAME.APPROVE,
      path: apiEndpoint.APPROVE,
    },
    {
      text: "Payment",
      route: "payment",
      icon: "credit-card",
      childrens: [
        {
          text: "Package",
          route: "plan",
          icon: "briefcase",
          // path: path.PACKAGE,
          resource: constants.RESOURCE_NAME.SDP,
          path: apiEndpoint.PAYMENT_PLAN
        },
        {
          text: "Transaction",
          route: "transaction",
          icon: "code",
          resource: constants.RESOURCE_NAME.PAYMENT,
          path: apiEndpoint.TRANSACTION
        },
        {
          text: "Transaction Statistic",
          route: "transaction-statistic",
          icon: "grouped-bar-chart",
          resource: constants.RESOURCE_NAME.PAYMENT,
          path: apiEndpoint.TRANSACTION_STATISTIC
        }
      ]
    },
    {
      text: "Ads",
      route: "ads",
      icon: "badge",
      resource: constants.RESOURCE_NAME.SDP,
      path: apiEndpoint.ADS
    },
    {
      text: "Manage Notification",
      icon: "notifications",
      route: "notification",
      resource: constants.RESOURCE_NAME.NOTIFICATION,
      path: path.notification,
    },
    // {
    //   text: "Filter Word In Chat",
    //   icon: "chat",
    //   route: "word-filter",
    //   // resource: constants.RESOURCE_NAME.NOTIFICATION,
    //   // path: path.notification,
    // },
    {
      text: "Statistic",
      icon: "chart",
      route: "statistic",
      // resource: constants.RESOURCE_NAME.CMS_MENU,
      // path: path.cmsStatisticMenu,
      childrens: [
        {
          text: "User",
          route: "user",
          childrens: [
            {
              text: "New user",
              route: "new",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticUserNew
            },
            {
              text: "Active user",
              route: "active",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticUserActive
            },
            {
              text: "CCU",
              route: "ccu",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticUserCCU
            }
          ]
        },
        {
          text: "Device",
          route: "device",
          childrens: [
            {
              text: "New device",
              route: "new",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticDeviceNew
            },
            {
              text: "Active device",
              route: "active",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticDeviceActive
            }
          ]
        },
        // {
        //   text: "Channel",
        //   route: "channel",
        //   childrens: [
        //     {
        //       text: "View Channel",
        //       route: "view",
        //       // resource: constants.RESOURCE_NAME.STATISTIC,
        //       // path: path.statisticChannelView
        //     },
        //     {
        //       text: "Top Channel",
        //       route: "top",
        //       // resource: constants.RESOURCE_NAME.STATISTIC,
        //       // path: path.statisticChannelViewTop
        //     }
        //   ]
        // },
        {
          text: "Video",
          route: "vod",
          childrens: [
            {
              text: "View video",
              route: "view",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticVodView
            },
            {
              text: "Top video",
              route: "top",
              // resource: constants.RESOURCE_NAME.STATISTIC,
              // path: path.statisticVodViewTop
            }
          ]
        }
      ]
    },
    {
      text: "Account",
      icon: "user",
      route: "user",
      childrens: [
        {
          text: "User",
          route: "list",
          resource: constants.RESOURCE_NAME.USER,
          path: apiEndpoint.USER,
        },
        {
          text: "Role",
          route: "role",
          resource: constants.RESOURCE_NAME.CMS_MENU,
          path: path.cmsMenuRole,
          // isAdmin: true
        },
        {
          text: "Permission",
          route: "permission",
          resource: constants.RESOURCE_NAME.AUTHORIZATION,
          path: apiEndpoint.PERMISSION,
        },
      ],
    },
    {
      text: "Account information",
      route: "profile",
      icon: "follower",
      hide: true,
    },
    {
      text: "Customer",
      route: "customer",
      icon: "follower",
      path: path.cmsMenuCustomer,
      resource: constants.RESOURCE_NAME.USER_CUSTOMER
    },
  ],
  apiEndpoint,
  apiUploadFile: "https://api-blackbelt.jungotv.app/upload/metadata",
  apiUploadOther: "https://api-blackbelt.jungotv.app/assets/other/upload",
  apiUploadMedia: "https://api-blackbelt.jungotv.app/assets/media/upload",
  eventUploadFile: "http://api-vtvcabbox.gviet.vn/ota/app-partner/upload-file",
  eventUploadImage: "https://upstv.gviet.vn:3743/upload",
  apiEPG: "https://onlps.gviet.vn",
  apiJungoTv: "https://api-blackbelt.jungotv.app",
  apiUpload: "https://api-blackbelt.jungotv.app/upload",
  apiAssets: "https://assets.jungotv.app",
  apiPlan: "http://plan-staging.vtvcab.local:8080",
  apiVod: "http://cms-vtvcab.gviet.vn",
  path,
  apiTestLocal14: "http://192.168.89.220:30014",
  apiTestLocal17: "http://192.168.89.220:30017",
  apiTestLocal15: "http://192.168.89.220:30015",
};
