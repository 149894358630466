import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute, ErrorBoundary } from "blueprint-hooks-ui";
import config from "src/utils/config";
import constants from "src/utils/constants";

// auth
import Login from "../pages/auth/login/loadable";

// app
import Dashboard from "../pages/app/dashboard/loadable";
import Welcome from "../pages/app/welcome/loadable";
import Metadata from "../pages/app/metadata/loadable";
import Statistic from "../pages/app/statistic/loadable";
import Notification from "../pages/app/notification/loadable";
import Profile from "../pages/app/profile/loadable";
import Approve from "../pages/app/approve/loadable";

// media
import NodeSelector from "../pages/app/media/node-selector/loadable";
import Taxonomy from "../pages/app/media/category/loadable";
import Channel from "../pages/app/media/channel/loadable";
import BroadcastSchedule from "../pages/app/media/channel/broadcast-schedule/loadable";
import VodIngest from "../pages/app/media/vod/ingest/loadable";
import Magazine from "../pages/app/media/magazine/loadable";
import MagazineDetail from "../pages/app/media/magazine/detail/loadable";
import VodSingle from "../pages/app/media/vod/single/loadable";
import VodArchive from "../pages/app/media/vod/archive/loadable";
import VodSingleDetail from "../pages/app/media/vod/single/detail/loadable";
import VodSeries from "../pages/app/media/vod/series/loadable";
import VodSeriesDetail from "../pages/app/media/vod/series/detail/loadable";
import VodSeriesEpisodes from "../pages/app/media/vod/series/episodes/loadable";
import VodEpisodesDetail from "../pages/app/media/vod/series/episodes/detail/loadable";
import Instructor from "../pages/app/media/instructor/loadable";

// payment
import PaymentPlan from "../pages/app/payment-service/package/loadable";
import PaymentPlanDuration from "../pages/app/payment-service/package/duration/loadable";
import PaymentVoucherCode from "../pages/app/payment-service/transaction/loadable";
import PaymentVoucherStatistic from "../pages/app/payment-service/transaction-statistic/loadable";

// write
import Home from "../pages/app/write/home/loadable";
import Ads from "../pages/app/ads/loadable";

// statistic
import StatisticNewUser from "../pages/app/statistic/user/new/loadable";
import StatisticActiveUser from "../pages/app/statistic/user/active/loadable";
import StatisticCcuUser from "../pages/app/statistic/user/ccu/loadable";
import StatisticNewDevice from "../pages/app/statistic/device/new/loadable";
import StatisticActiveDevice from "../pages/app/statistic/device/active/loadable";
import StatisticChannelView from "../pages/app/statistic/channel/view/loadable";
import StatisticChannelViewTop from "../pages/app/statistic/channel/view-top/loadable";
import StatisticVodView from "../pages/app/statistic/vod/view/loadable";
import StatisticVodViewTop from "../pages/app/statistic/vod/view-top/loadable";

// user
import UserList from "../pages/app/user/list/loadable";
import UserPermission from "../pages/app/user/permission/loadable";
import UserRole from "../pages/app/user/role/loadable";
import loadable from "@loadable/component";

const Customer = loadable(() => import("../pages/app/CustomerCustom"));
const CustomerSession = loadable(() => import("../pages/app/customer/session"));
const WordFilter = loadable(() => import("../pages/app/WordFilter"));

export const routes = [
  {
    path: "/",
    component: Welcome,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/statistic",
    component: Statistic,
  },
  {
    path: "/config-screen",
    component: Home,
  },
  {
    path: "/media/node-selector",
    component: NodeSelector,
    // authProps: {
    //   resource: constants.RESOURCE_NAME.NODE,
    // },
  },
  {
    path: "/media/instructor",
    component: Instructor
  },
  {
    path: "/media/taxonomy",
    component: Taxonomy,
  },
  {
    path: "/media/channel",
    component: Channel,
  },
  {
    path: "/media/channel/broadcast-schedule",
    component: BroadcastSchedule,
  },
  {
    path: "/media/magazine",
    component: Magazine
  },
  {
    path: "/media/magazine/:recordId",
    component: MagazineDetail
  },
  {
    path: "/media/archive",
    component: VodArchive,
  },
  {
    path: "/media/vod/ingest",
    component: VodIngest,
  },
  {
    path: "/media/vod/single",
    component: VodSingle,
  },
  {
    path: "/media/vod/single/:recordId",
    component: VodSingleDetail,
  },
  {
    path: "/media/vod/series",
    component: VodSeries,
  },
  {
    path: "/media/vod/series/:recordId",
    component: VodSeriesDetail,
  },
  {
    path: "/media/vod/series/episode/:vodId",
    component: VodSeriesEpisodes,
  },
  {
    path: "/media/vod/series/episode/:vodId/:recordId",
    component: VodEpisodesDetail,
  },
  {
    path: "/payment/plan",
    component: PaymentPlan
  },
  {
    path: "/payment/plan-duration/:planId",
    component: PaymentPlanDuration
  },
  {
    path: "/payment/transaction",
    component: PaymentVoucherCode
  },
  {
    path: "/payment/transaction-statistic",
    component: PaymentVoucherStatistic
  },
  {
    path: "/ads",
    component: Ads
  },
  {
    path: "/user/list",
    component: UserList,
  },
  {
    path: "/statistic/user/new",
    component: StatisticNewUser,
  },
  {
    path: "/statistic/user/active",
    component: StatisticActiveUser,
  },
  {
    path: "/statistic/user/ccu",
    component: StatisticCcuUser,
  },
  {
    path: "/statistic/device/active",
    component: StatisticActiveDevice,
  },
  {
    path: "/statistic/device/new",
    component: StatisticNewDevice,
  },
  {
    path: "/statistic/channel/view",
    component: StatisticChannelView,
  },
  {
    path: "/statistic/channel/top",
    component: StatisticChannelViewTop,
  },
  {
    path: "/statistic/vod/view",
    component: StatisticVodView,
  },
  {
    path: "/statistic/vod/top",
    component: StatisticVodViewTop,
  },
  {
    path: "/user/permission",
    component: UserPermission,
  },
  {
    path: "/user/role",
    component: UserRole,
  },
  // {
  //   path: "/customer",
  //   component: Customer,
  // },
  {
    path: "/customer/session/:uid",
    component: CustomerSession,
  },
  {
    path: "/notification",
    component: Notification,
  },
  {
    path: "/approve",
    component: Approve,
  },
  {
    path: "/customer",
    component: Customer,
  },
  // {
  //   path: "/word-filter",
  //   component: WordFilter,
  // },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "*",
    component: () => <ErrorBoundary title="404 - Trang không tồn tại" />,
  },
];

export default (
  <Switch>
    {routes.map(
      ({ path, exact = true, component, notPrivate, ...routeProps }) => {
        if (notPrivate) {
          return <Route exact={exact} path={path} component={component} />;
        } else {
          return (
            <PrivateRoute
              exact={exact}
              path={path}
              component={component}
              homeUrlByScope={(scope) => {
                if (scope === constants.SCOPE_TYPE.ADMIN) {
                  return `/dashboard`
                  // return `/`
                } else {
                  
                  return `/dashboard`
                  // return `/`
                }
              }}
              {...routeProps}
            />
          );
        }
      }
    )}
  </Switch>
);
