import React, { useEffect } from "react";
import { Button, MenuItem, Icon } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import _cloneDeep from 'lodash/cloneDeep';
import { useDeepEffect } from "react-context-hooks-store";
import { useSetState } from "blueprint-hooks-ui";
import { formatListToTree } from "src/helpers";

import "./index.less";

const highlightText = (text, query) => {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens = [];

  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
};

const escapeRegExpChars = (text) => {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

const filter = (query, item, _index, exactMatch) => {
  const normalizedTitle = item.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const getCurrentItem = (value, dataMap) => {
  const currentItem = {};

  if (dataMap instanceof Object) {
    currentItem.title = dataMap[value];
    currentItem.value = value;
  }

  // console.log('currentItem', currentItem);

  return currentItem;
};

const TreeSelect = ({
  prefixCls = 'app-tree-select',
  dataList = [],
  handleChange = () => {
  },
  value,
  disabled = false
}) => {
  const [{ currentItem, data }, setState] = useSetState({
    currentItem: dataList.find(item => item.id === value) || {},
    data: dataList
  });

  const handleValueChange = (item) => {
    setState({
      currentItem: item
    }, () => {
      handleChange(item.id);
    });
  };

  const handleFilter = (query) => {
    const newData = dataList.filter(item => {
      const normalizedTitle = item.title.toLowerCase();
      const normalizedQuery = query.toLowerCase();

      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    });

    const cloneData = _cloneDeep(newData);

    cloneData.forEach((item) => {
      if (item.parentId && item.parentId !== 'root' && !newData.find(i => i?.id === item?.parentId)) {
        const parentItem = dataList.find(i => i?.id === item.parentId);

        newData.push(parentItem)
      }
    });

    setState({
      data: newData
    })
  };

  // console.log('dataList xxx', dataList);

  useEffect(() => {
    setState({
      currentItem: dataList.find(item => item.id === value) || {},
      data: dataList
    })
  }, [dataList && JSON.stringify(dataList)]);

  // console.log('currentItem', currentItem);

  const renderChildren = ({lever = 1, item, modifiers, query }) => {
    
    const text = `${item.title}`;

    if (item.children) {
      return (
        <>
          <MenuItem
            active={item.id === currentItem.id}
            disabled={modifiers.disabled}
            // label={item.text}
            key={item.rank}
            onClick={() => handleValueChange(item)}
            text={highlightText(text, query)}
          />
          <ul className={`${prefixCls}-children`}>
            {
              item.children.map(item => {
                const text = `${item.title}`;
                if(item.children) {
                  let nextLever = lever + 1;
                  return renderChildren({ lever: nextLever,  item, modifiers, query })
                }
                return (
                  <MenuItem
                    active={item.id === currentItem.id}
                    disabled={modifiers.disabled}
                    key={item.rank}
                    onClick={() => handleValueChange(item)}
                    text={highlightText(text, query)}
                  />
                );
              })
            }
          </ul>
        </>
      );
    } else {
      return (
        <MenuItem
          // active={item.title === currentItem.title}
          disabled={modifiers.disabled}
          // label={item.text}
          key={item.rank}
          onClick={() => handleValueChange(item)}
          text={highlightText(text, query)}
        />
      );
    }
  }

  return (
    <div className={prefixCls}>
      <Select
        filterable={true}
        popoverProps={{ minimal: true }}
        items={formatListToTree(data)}
        itemRenderer={(item, { handleClick, modifiers, query }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }

          return renderChildren({item, modifiers, query })
        }}
        noResults={(
          <MenuItem disabled={true} text="No results." />
        )}
        onQueryChange={(query) => {
          handleFilter(query)
        }}
      // onItemSelect={handleValueChange}
      >
        <Button
          rightIcon="caret-down"
          text={currentItem.title || 'None'}
          disabled={disabled}
        >
          {
            currentItem && currentItem.title
            &&
            <span
              className={`${prefixCls}-clear`}
              onClick={() => {
                handleValueChange({})
              }}
            >
              <Icon icon="small-cross" />
            </span>
          }
        </Button>
      </Select>
    </div>
  )
};

export default TreeSelect