import React, {Component} from "react";
import {
  BpBase,
  formatDateTime,
  confirmHandler,
  ButtonAction,
  useRouter,
} from "blueprint-hooks-ui";
import {formatListToTree, checkStatusBySchedule} from "src/helpers";
import config from "src/utils/config";

import "./index.less";
import {useTranslation} from "react-i18next";

const NodeSelectorList = ({dataDetail, handleSelectNode, dataList, screenType}) => {
  const router = useRouter();
  const {t} = useTranslation();

  const fieldFilter = [
    {
      title: t("Title"), //"Tên node",
      dataIndex: "s",
    },
  ];

  const fieldList = () => {
    return [
      {
        title: t("Title"), // "Tên VOD",
        dataIndex: "title",
        // key: "title",
      },
      {
        title: "", //"Chọn",
        dataIndex: "choice",
        key: "choice",
        align: "center",
        render: (value, record) => {
          if (checkStatusBySchedule(record) && dataList) {
            let dataRecord = dataList.filter((e) => {
              if(!screenType) {
                return e.id !== record.id
              } 
              if (e.id !== record.id || (e.id === record.id && e.screenType !== screenType)  ) return true;
              return false;
            });
            if (dataRecord) {
              let flag = false;
              if (dataRecord.length < dataList.length) {
                flag = true;
              }
              return (
                <ButtonAction
                  icon="add"
                  disabled={flag}
                  onClick={() => {
                    handleSelectNode(record);
                  }}
                />
              );
            }
          }
        },
      },
    ];
  };

  const sortDataList = (list = []) => {
    if (list instanceof Array) {
      list.sort((a, b) => a.rank - b.rank);
      list.map((item) => {
        if (item.children && item.children instanceof Array) {
          return sortDataList(item.children);
        }
      });
    }
    return list;
  };

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isEditRecord={false}
        isDeleteRecord={false}
        // isFetchList={false}
        fieldFilter={fieldFilter}
        fieldList={fieldList}
        apiConfig={{
          list: {
            // apiUrl: config.apiTestLocal15,
            url: config.apiEndpoint.NODE_SELECTOR,
            params: {
              status: 1,
              limit: 1000
            }
          },
        }}
        formatDataList={(dataList) => sortDataList(formatListToTree(dataList))}
        listProps={{
          expandIconAsCell: true,
          isPagination: false,
          isNextPrev: false,
        }}
      />
    </div>
  );
};

export default NodeSelectorList;
