import constants from 'src/utils/constants';

let initialState = {
  theme: 'white',
  mutedPlayer: false,
  isLyric: false,
  showLogin: true,
  dataMenu: [],
  homeConfig: {},
  initialChannel: 'VTV1_HD',
  timeAfter: {},
  userInfo: {},
  isOpenNotify: false
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case `common/${constants.ACTION_TYPE.UPDATE_STATE}`:
      return {
        ...state,
        ...payload
      };

    case constants.ACTION_TYPE.SHOW_MODAL:
      return {
        ...state,
        showModal: true
      };

    case constants.THEME_ACTION_TYPE.CHANGE_THEME:
      return {
        ...state,
        theme: state.theme === 'white' ? 'dark' : 'white'
      };

    case constants.ACTION_TYPE.TOGGLE_NOTIFY:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default reducer
