import React from "react";
import raf from "raf";
import Url from "url";
import _findIndex from "lodash/findIndex";
import _pickBy from "lodash/pickBy";
import _identity from "lodash/identity";
import _orderBy from "lodash/orderBy";
import _merge from "lodash/merge";
import _unionBy from "lodash/unionBy";
import _keyBy from "lodash/keyBy";
import _mapValues from "lodash/mapValues";
import moment from "moment";
import md5 from "md5";
import { messageHandler, useAuth } from "blueprint-hooks-ui";
import { Tooltip, Icon, Tag, Intent } from "@blueprintjs/core";
import localForage from "localforage";
import config from "../utils/config";
import constants from "../utils/constants";
import jwtDecode from "jwt-decode";

const easeInOutCubic = (t, b, c, d) => {
  const cc = c - b;
  t /= d / 2;
  if (t < 1) {
    return (cc / 2) * t * t * t + b;
  } else {
    return (cc / 2) * ((t -= 2) * t * t + 2) + b;
  }
};

const getCurrentScrollTop = () => {
  return (
    window.pageYOffset ||
    document.body.scrollTop ||
    document.documentElement.scrollTop
  );
};

const setScrollTop = (value) => {
  document.body.scrollTop = value;
  document.documentElement.scrollTop = value;
};

export const scrollToTop = (delay = 450) => {
  const scrollTop = getCurrentScrollTop();

  const startTime = Date.now();

  const frameFunc = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;

    setScrollTop(easeInOutCubic(time, scrollTop, 0, delay));

    if (time < delay) {
      raf(frameFunc);
    } else {
      setScrollTop(0);
    }
  };

  raf(frameFunc);
};

export const randomSecretId = (limit = 11) => {
  const characters =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let secretKey = "";

  for (let i = 0; i < limit; i++) {
    secretKey += characters.substr(0 | (Math.random() * characters.length), 1);
  }

  return secretKey;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getScroll = (target, top) => {
  if (typeof window === "undefined") {
    return 0;
  }

  const prop = top ? "pageYOffset" : "pageXOffset";
  const method = top ? "scrollTop" : "scrollLeft";
  const isWindow = target === window;

  let ret = isWindow ? target[prop] : target[method];

  if (isWindow && typeof ret !== "number") {
    ret = document.documentElement[method];
  }

  return ret;
};

const pad = (string) => {
  return ("0" + string).slice(-2);
};

export const formatTime = (seconds) => {
  const date = new Date(seconds * 1000);
  const hh = pad(date.getUTCHours());
  const mm = pad(date.getUTCMinutes());
  const ss = pad(date.getUTCSeconds());
  const ms = date.getUTCMilliseconds();

  return `${hh}:${mm}:${ss}.${ms}`;
};

export const dateToSeconds = (date) => {
  const hour = Number(moment(date).format("HH"));
  const minute = Number(moment(date).format("mm"));
  const second = Number(moment(date).format("ss"));
  const ms = Number(moment(date).format("SSS"));

  return hour * 60 * 60 + minute * 60 + second + ms / 1000;
};

export const getSourceUrl = (record = {}) => {
  const { source } = record;

  if (source && (source.indexOf("http") > -1 || source.indexOf("https") > -1)) {
    return source;
  }

  if (source.indexOf("volume2/")) {
    return `${process.env.PLAYER_URL}/${source.replace("volume2/", "")}`;
  }

  return `${process.env.PLAYER_URL}/${source}`;
};

export const getPathnameFromUrl = (value) => {
  if (value && (value.indexOf("http") > -1 || value.indexOf("https") > -1)) {
    const urlParse = Url.parse(value);

    return urlParse.pathname;
  }

  return value;
};

export const getFilenameFromUrl = (value) => {
  if (value && (value.indexOf("http") > -1 || value.indexOf("https") > -1)) {
    return value.substring(value.lastIndexOf("/") + 1);
  }

  return value;
};

export const getAssetFile = (key, path) => {
  return {
    type: key,
    name: path,
  };
};

export const getAssetUrl = (name) => {
  if (
    name &&
    (name.indexOf("http") > -1 ||
      name.indexOf("https") > -1 ||
      name.indexOf("data:image") > -1)
  ) {
    return name;
  }

  return `${config.assetUrl}${name}`;
};

export const getAssetFileName = (value, assetKey) => {
  if (
    typeof value === "string" &&
    (value.indexOf("http") > -1 ||
      value.indexOf("https") > -1 ||
      value.indexOf("data:image") > -1)
  ) {
    return value;
  }

  return value[assetKey] || "";
};

export const getImageUrl = (value) => {
  let id;

  if (typeof value === "string") {
    id = value;
  }

  if (typeof value === "object") {
    id = value["id"];
  }

  if (
    typeof id === "string" &&
    (id.indexOf("http") > -1 ||
      id.indexOf("https") > -1 ||
      id.indexOf("data:image") > -1)
  ) {
    return id;
  }

  if (!id || (typeof id == "string" && isNaN(parseInt(id)))) {
    return id;
  }

  id = parseInt(id);

  const tmp = Math.floor(id / 10000);
  const path1 = Math.floor(tmp / 1000000) + 1;
  const path2 = Math.floor((tmp % 1000000) / 1000) + 1;

  return `${config.baseImageUrl}${path1}/${path2}/${id}.png`;
};

export const checkImageExists = (imageUrl, callback) => {
  const imageData = new Image();

  imageData.onload = function () {
    callback && callback(true);
  };

  imageData.onerror = function () {
    callback && callback(false);
  };

  imageData.src = imageUrl;
};

export const getAssetImageUrl = (name, options = {}) => {
  if (
    name &&
    (name.indexOf("http") > -1 ||
      name.indexOf("https") > -1 ||
      name.indexOf("data:image") > -1)
  ) {
    return name;
  }

  name = `posters/${name}`;

  const { hq = true, poster } = options;

  if (poster) {
    const dataName = name.split(".");

    name = `${dataName[0]}_Poster.${dataName[1]}`;
  }

  if (hq) {
    name = `hq/${name}`;
  }

  return `${config.assetUrl}/assets/images/${name}`;
};

export const getAssetFileUrl = (name) => {
  if (
    name &&
    (name.indexOf("http") > -1 ||
      name.indexOf("https") > -1 ||
      name.indexOf("data:image") > -1)
  ) {
    return name;
  }

  return `${config.assetUrl}/assets/images/hq/posters/${name}`;
};

export const getDataAsset = (data = [], key) => {
  const dataArray = [];

  data.forEach((item) => {
    if (item.type === key) {
      dataArray.push(getAssetUrl(item.name));
    }
  });

  return dataArray;
};

export const getDataImage = (data = [], options) => {
  const dataArray = [];

  data &&
    data.forEach((name) => {
      dataArray.push(getAssetImageUrl(name, options));
    });

  // console.log('dataArray', dataArray);

  return dataArray;
};

export const getDataIdentity = (data) => {
  if (data instanceof Object) {
    data = _pickBy(data, (value) => {
      return value || value === 0 || value === false;
    });
  }

  return data;
};

export const getFileName = (data = {}, key) => {
  let fileName;

  if (data[key] && data[key].length) {
    fileName = data.id
      ? `${data.id}-${key}${data[key].length}-${Date.now()}`
      : `${key}-${Date.now()}`;
  } else {
    fileName = data.id
      ? `${data.id}-${key}-${Date.now()}`
      : `${key}-${Date.now()}`;
  }

  return fileName;
};

export const getFileType = (file = {}) => {
  if (file.type) {
    return file.type.split("/")[1];
  } else {
    return file.name ? file.name.split(".").pop() : "";
  }
};

export const getUrlTimeshift = (config = {}, record = {}) => {
  const { id, startTime, endTime, startDate, endDate } = record;
  const { timeBuffer = 0 } = config;

  const startDay = moment(startDate).format("YYYYMMDD");
  const endDay = moment(endDate).format("YYYYMMDD");

  const start =
    moment(`${startDay} ${startTime}`, "YYYYMMDD HH:mm").unix() -
    timeBuffer * 60 * 60;
  const end =
    moment(`${endDay} ${endTime}`, "YYYYMMDD HH:mm").unix() +
    timeBuffer * 60 * 60;

  return `${config.url}${config.path
    .replace("{id}", id)
    .replace("{startTime}", start)
    .replace("{endTime}", end)}`;
};

export const getDurationByTimeshift = (source) => {
  const url = new URL(source);

  const searchParams = new URLSearchParams(url.search);

  const startTime = Number(searchParams.get("startTime"));
  const stopTime = Number(searchParams.get("stopTime"));

  const duration = stopTime - startTime;

  return Math.ceil(duration / 60);
};

export const formatUrlTimeshift = (source, timeBuffer) => {
  const url = new URL(source);

  const searchParams = new URLSearchParams(url.search);

  const startTime = Number(searchParams.get("startTime"));
  const stopTime = Number(searchParams.get("stopTime"));

  searchParams.set("startTime", startTime - timeBuffer * 60 * 60);
  searchParams.set("stopTime", stopTime + timeBuffer * 60 * 60);

  return url.origin + url.pathname + `?${searchParams.toString()}`;
};

export const getUrlTimeshiftToSource = (source, timeChange) => {
  const url = new URL(source);

  const searchParams = new URLSearchParams(url.search);

  const startTime = Number(searchParams.get("startTime"));

  searchParams.set("startTime", startTime + timeChange[0]);
  searchParams.set("stopTime", startTime + timeChange[1]);

  return url.origin + url.pathname + `?${searchParams.toString()}`;
};

export const getDataUrlTimeshift = (source) => {
  const url = new URL(source);

  const searchParams = new URLSearchParams(url.search);

  const startTime = Number(searchParams.get("startTime"));
  const stopTime = Number(searchParams.get("stopTime"));

  return {
    startTime,
    stopTime,
  };
};

export const getProgramCurrent = (list = []) => {
  const now = Date.now();

  const date = moment().format("YYYY-MM-DD");

  let newList = list.filter((item) => {
    return moment(`${date} ${item.time}`, "YYYY-MM-DD HH:mm").valueOf() < now;
  });

  const current = newList[newList.length - 1];

  const index = _findIndex(list, current);

  return {
    data: current,
    index,
  };
};

export const checkFieldExistsInObject = (data = {}, key = "") => {
  if (typeof data !== "object") {
    return false;
  } else if (data.length !== undefined) {
    return false;
  }

  if (key && key.toString) {
    key = key.toString();
  } else if (key && typeof key !== "string") {
    return false;
  } else {
    return false;
  }

  let isExists = false;

  Object.keys(data).map((keyInObject) => {
    if (keyInObject === key) {
      isExists = true;
    }
    return 0;
  });

  return isExists;
};

export const checkDataArray = (data = [], fields = [], params = {}) => {
  const { parentKey, parentTitle } = params;

  let messageError = "Dữ liệu chưa đúng";

  const isValid = data.every((dataItem, index) => {
    return fields.every((form) => {
      let dataValue;
      let isRequire;
      let isHide;

      if (form.isRequire) {
        if (typeof form.isRequire === "function") {
          isRequire = form.isRequire(dataItem);
        } else {
          isRequire = form.isRequire;
        }
      }

      if (form.isHide) {
        if (typeof form.isHide === "function") {
          isHide = form.isHide(dataItem);
        } else {
          isHide = form.isHide;
        }
      }

      if (form.parentKey) {
        if (form.parentType && form.parentType === constants.DATA_TYPE.ARRAY) {
          if (!dataItem[form.parentKey]) {
            dataItem[form.parentKey] = [];
          }

          if (form.getDataValue) {
            dataValue = form.getDataValue(
              dataItem[form.parentKey],
              form.dataIndex
            );
          } else {
            dataValue = dataItem[form.parentKey][form.dataIndex];
          }
        } else {
          if (!dataItem[form.parentKey]) {
            dataItem[form.parentKey] = {};
          }

          dataValue = dataItem[form.parentKey][form.dataIndex];
        }
      } else {
        dataValue = dataItem[form.dataIndex];
      }

      // console.log('dataValue', index, form.dataIndex);

      if (!isHide && isRequire) {
        if (
          !checkFieldExistsInObject(dataItem, form.dataIndex) ||
          dataValue === undefined ||
          dataValue === ""
        ) {
          messageError = `Vui lòng nhập ${form.title} hàng ${index + 1
            } của ${parentTitle}`;

          return false;
        }
      }

      return true;
    });
  });

  return {
    messageError,
    isValid,
  };
};

export const checkDataForm = (data = {}, options) => {
  let messageError = "Dữ liệu chưa đúng";

  function checkValid(fields = []) {
    // let isValid = true;
    // const newFields = [];
    //
    // fields.forEach((form) => {
    //   newFields.push(form);
    //
    //   if (form.fieldForm) {
    //     form.fieldForm.forEach((child) => {
    //       newFields.push({
    //         ...child,
    //         dataIndex: `${form.dataIndex}-${child.dataIndex}`
    //       })
    //     })
    //   }
    // });

    const isValid = fields.every((form) => {
      let dataValue;
      let isRequire;
      let isHide;

      if (form.isRequire) {
        if (typeof form.isRequire === "function") {
          isRequire = form.isRequire(data);
        } else {
          isRequire = form.isRequire;
        }
      }

      if (form.isHide) {
        if (typeof form.isHide === "function") {
          isHide = form.isHide(data);
        } else {
          isHide = form.isHide;
        }
      }

      if (form.parentKey) {
        if (form.parentType && form.parentType === constants.DATA_TYPE.ARRAY) {
          if (!data[form.parentKey]) {
            data[form.parentKey] = [];
          }

          if (form.getDataValue) {
            dataValue = form.getDataValue(data[form.parentKey], form.dataIndex);
          } else {
            dataValue = data[form.parentKey][form.dataIndex];
          }
        } else {
          if (!data[form.parentKey]) {
            data[form.parentKey] = {};
          }

          dataValue = data[form.parentKey][form.dataIndex];
        }
      } else {
        dataValue = data[form.dataIndex];
      }

      if (!isHide && isRequire) {
        if (
          !checkFieldExistsInObject(data, form.dataIndex) ||
          dataValue === undefined ||
          dataValue === ""
        ) {
          messageError = `Vui lòng nhập ${form.title}`;

          return false;
        }
      }

      if (form.fieldForm) {
        const dataCheck = checkDataArray(data[form.dataIndex], form.fieldForm, {
          parentKey: form.dataIndex,
          parentTitle: form.title,
        });

        messageError = dataCheck.messageError;

        return dataCheck.isValid;
      }

      return true;
    });

    if (!isValid) {
      messageHandler.warning(messageError);
    }

    return isValid;
  }

  let fields = [];

  if (options instanceof Array) {
    fields = options;

    return checkValid(fields);
  }

  if (options instanceof Object) {
    let { fieldForm = [], fieldTab = [], fieldPanel = [] } = options;

    if (fieldForm instanceof Function) {
      fieldForm = fieldForm();
    }

    if (fieldTab instanceof Function) {
      fieldTab = fieldTab();
    }

    if (fieldPanel instanceof Function) {
      fieldPanel = fieldPanel();
    }

    if (fieldPanel.length === 0 && fieldTab.length > 0) {
      fieldTab.forEach(({ fieldForm }) => {
        fields.push(...fieldForm);
      });

      return checkValid(fields);
    }

    if (fieldTab.length === 0 && fieldPanel.length > 0) {
      fieldTab.forEach(({ fieldForm }) => {
        fields.push(...fieldForm);
      });

      return checkValid(fields);
    }

    return checkValid(fieldForm);
  }

  return false;
};

export const getDataDetail = (fields) => {
  const dataDetail = {};

  if (fields instanceof Function) {
    fields = fields();
  }

  if (fields instanceof Array) {
    fields.forEach((field) => {
      if (field.defaultValue) {
        dataDetail[field.dataIndex] = field.defaultValue;

        if (field.updateField) {
          dataDetail[field.updateField.key] = field.updateField.change
            ? field.updateField.change(field.defaultValue)
            : field.defaultValue;
        }
      }
    });

    return dataDetail;
  }

  if (fields instanceof Object) {
    let { fieldForm = [], fieldTab = [], fieldPanel = [] } = fields;

    if (fieldForm instanceof Function) {
      fieldForm = fieldForm();
    }

    if (fieldTab instanceof Function) {
      fieldTab = fieldTab();
    }

    if (fieldPanel instanceof Function) {
      fieldPanel = fieldPanel();
    }

    if (fieldPanel.length === 0 && fieldTab.length > 0) {
      fieldTab.forEach(({ fieldForm }) => {
        fieldForm.forEach((field) => {
          if (field.defaultValue) {
            dataDetail[field.dataIndex] = field.defaultValue;

            if (field.updateField) {
              dataDetail[field.updateField.key] = field.updateField.change
                ? field.updateField.change(field.defaultValue)
                : field.defaultValue;
            }
          }
        });
      });

      return dataDetail;
    }

    if (fieldTab.length === 0 && fieldPanel.length > 0) {
      fieldTab.forEach(({ fieldForm }) => {
        fieldForm.forEach((field) => {
          if (field.defaultValue) {
            dataDetail[field.dataIndex] = field.defaultValue;

            if (field.updateField) {
              dataDetail[field.updateField.key] = field.updateField.change
                ? field.updateField.change(field.defaultValue)
                : field.defaultValue;
            }
          }
        });
      });

      return dataDetail;
    }

    fieldForm.forEach((field) => {
      if (field.defaultValue) {
        dataDetail[field.dataIndex] = field.defaultValue;

        if (field.updateField) {
          dataDetail[field.updateField.key] = field.updateField.change
            ? field.updateField.change(field.defaultValue)
            : field.defaultValue;
        }
      }
    });

    return dataDetail;
  }
};

export const formatListToTree = (list = []) => {
  let map = {},
    node,
    roots = [],
    i,
    level,
    indexCheckNoParent = [];

  // console.log('list', list);

  // list.map((item, index) => {
  //   if (item.parentId && item.parentId !== 'root' && list.filter((a) => a.id === item.parentId).length === 0) {
  //     item.parentId = 'root';
  //   }
  // });
  for (i = 0; i < list.length; i++) {
    if(list[i]) {
      map[list[i].id] = i;
    }

    // list[i].children = [];
  }

  for (i = 0; i < list.length; i++) {
    if(list[i]) {
      node = list[i];

      // level = 0;

      if (node.parentId && node.parentId !== "root") {
        if (list[map[node.parentId]]) {
          if (!list[map[node.parentId]].children) {
            list[map[node.parentId]].children = [];
          }

          if (
            list[map[node.parentId]].children.findIndex(
              (o) => o.id === node.id
            ) === -1
          ) {
            list[map[node.parentId]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      } else {
        roots.push(node);
      }
    }
  }

  // console.log('roots', roots);

  return roots;
};

export const formatDataToMap = (data, options = {}) => {
  const { keyName = "id", valueName = "name" } = options;

  const objMap = {};

  if (data instanceof Array) {
    data.forEach((item) => {
      objMap[item[keyName]] = item[valueName];
    });
  }

  return objMap;
};

export const formatBooleanToNumber = (value) => {
  if (value) {
    return 1;
  }

  return 0;
};

export const formatDateTime = (value, stringFormat = "DD-MM-YYYY HH:mm:ss") => {
  if (!value) {
    return "";
  }

  return moment(value).format(stringFormat);
};

export const formatStateColor = (data, text) => {
  let color;
  if (data === -2 || data === -1 || data === "-2" || data === 4) {
    color = "#c23030";
  } else if (data === 3) {
    color = "#0f9960";
  } else if (data === 1) {
    color = "#90d1f1";
  } else if (data === 5) {
    color = "#d99e0b";
  } else if (data === 2) {
    color = "#27aff0";
  } else if (data === 0) {
    color = "#182026";
  } else {
    color = "#48aff0";
  }

  return <span style={{ color }}>{text}</span>;
};

export const formatStatusColor = (data, text) => {
  let color;

  if (data === "start_error" || data === "errror") {
    color = "#c23030";
  } else if (
    data === "init" ||
    data === "running" ||
    data === "schedule" ||
    data === "running"
  ) {
    color = "#0f9960";
  } else if (data === "stop") {
    color = "#d99e0b";
  } else if (data === "ended") {
    color = "#27aff0";
  } else {
    color = "#182026";
  }

  return <span style={{ color }}>{text}</span>;
};

export const changeToSlug = (title, options = {}) => {
  const { spaceReplace, notSpace, upperCase, } = options;

  if (!title) {
    return "black-belt";
  }

  let slug = title.toLowerCase();

  const from =
    "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịỳýỷỹỵäëïîöüûñç";
  const to =
    "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiyyyyyaeiiouunc";

  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(RegExp(from[i], "gi"), to[i]);
  }

  //Xóa các ký tự đặt biệt
  slug = slug.replace(/[^ a-z0-9]/g, "");
  // slug = slug.replace(/[`~!@#|$%^&*()+=,.\/?><'":;_]/gi, '');

  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-/gi, "-");
  slug = slug.replace(/\-\-/gi, "-");

  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  slug = slug.replace(/\@\-|\-\@|\@/gi, "");

  //Đổi khoảng trắng thành ký tự gạch ngang
  if (notSpace) {
    slug = slug.replace(/ /gi, "");
    slug = slug.replace(/-/gi, "");
  } else {
    slug = slug.replace(/ /gi, spaceReplace || "-");
  }

  if (upperCase) {
    slug = slug.toUpperCase();
  }

  return slug;
};

export const checkTextUnicode = (title) => {
  if (!title) {
    return true;
  }

  let slug = title.toLowerCase();

  const from =
    "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịỳýỷỹỵäëïîöüûñç";
  const to =
    "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiyyyyyaeiiouunc";

  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(RegExp(from[i], "gi"), to[i]);
  }

  slug = slug.replace(/[-`~!@#|$%^&*()+=,.\/?><'":;_]/gi, "");

  return !new RegExp(/[^ a-z0-9]/g).test(slug);
};

export const getUid = async () => { };

export function formatUnicon(pattern, data) {
  try {
    const regex = new RegExp("\\${(.*?)\\}", "g");
    let arr = pattern.match(regex);
    for (let arg of arr) {
      arg = arg.substring(2, arg.length - 1);
      pattern = pattern.replace(`$\{${arg}}`, getProperty(data, arg));
    }
  } catch (e) { }
  return pattern;
}

function getProperty(obj, prop) {
  let parts = prop.split(".");

  if (Array.isArray(parts)) {
    let last = parts.pop(),
      l = parts.length,
      i = 1,
      current = parts[0];

    while ((obj = obj[current]) && i < l) {
      current = parts[i];
      i++;
    }

    if (obj) {
      return obj[last] || "";
    }
  } else {
    return prop;
  }
}

export const getArrayUniqueValue = (arr = []) => {
  return arr.filter(function (item, index) {
    return arr.indexOf(item) === index;
  });
};

export const listToTreeComponent = ({
  list = [],
  selectedKey = [],
  expandedKey = [],
  iconSet = { default: "document", selected: "tick-circle" },
  parentKey = "parentId",
}) => {
  let map = {},
    node,
    roots = [],
    i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].childNodes = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = { ...list[i], label: list[i].title };
    let isSelected = false,
      icon = iconSet.default;

    if (selectedKey instanceof Array) {
      if (selectedKey.indexOf(node.id) !== -1) {
        isSelected = true;
      }
    } else {
      isSelected = selectedKey === node.id;
    }

    if (isSelected) {
      icon = iconSet.selected;
    } else {
      icon = iconSet.default;
    }

    const isDisabled = !checkStatusBySchedule(node);

    if (node[parentKey] && list[map[node[parentKey]]]) {
      list[map[node[parentKey]]].childNodes.push({
        ...node,
        icon,
        // disabled: isDisabled,
        isSelected: selectedKey instanceof Array ? false : isSelected,
        isExpanded:
          expandedKey instanceof Array && expandedKey.indexOf(node.id) !== -1,
      });
    } else {
      roots.push({
        ...node,
        icon,
        // disabled: isDisabled,
        isExpanded:
          expandedKey instanceof Array && expandedKey.indexOf(node.id) !== -1,
        isSelected: selectedKey instanceof Array ? false : isSelected,
      });
    }
  }

  return removeChildNodeEmpty(
    roots.map((node) => {
      if (node.childNodes.length > 0) {
        return {
          ...node,
          // icon: null,
        };
      } else {
        return node;
      }
    })
  );
};

const removeChildNodeEmpty = (arrNode = []) => {
  arrNode instanceof Array &&
    arrNode.map((node) => {
      if (node.childNodes && node.childNodes.length === 0) {
        delete node.childNodes;
      } else {
        return removeChildNodeEmpty(node.childNodes);
      }
    });

  return arrNode;
};

export const getKeyExpaned = (list, selectedKey, expandedKeyDefault = []) => {
  let expandedKey = [...expandedKeyDefault];
  let objectParent = {};
  if (list instanceof Array) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.parentId) {
        objectParent[item.id] = item.parentId;
      }
      if (selectedKey instanceof Array) {
        if (selectedKey.indexOf(item.id) !== -1) {
          if (item.parentId && expandedKey.indexOf(item.parentId) === -1) {
            expandedKey.push(item.parentId);
          }
        }
      } else {
        if (selectedKey === item.id) {
          if (item.parentId && expandedKey.indexOf(item.parentId) === -1) {
            expandedKey.push(item.parentId);
          }
        }
      }
    }
  }
  let checkExistParent = false;
  expandedKey.map((keyExpand) => {
    if (
      expandedKeyDefault.indexOf(keyExpand) === -1 &&
      objectParent[keyExpand]
    ) {
      checkExistParent = true;
    }
  });

  if (checkExistParent) {
    return getKeyExpaned(list, expandedKey, expandedKey);
  } else {
    return expandedKey;
  }
};

export const getRouteTimeshift = (record) => {
  if (record.type === constants.VOD_TYPE.LE) {
    return `/media/vod/single/${record.id}`;
  }

  if (record.type === constants.VOD_TYPE.EPISODE) {
    return `/media/vod/series/episode/${record.parentId}/${record.id}`;
  }
};

export const getUser = ({ uid, userMap }) => {
  let stringReturn = "";

  if (uid) {
    if (userMap && Object.keys(userMap).length && userMap[uid]) {
      stringReturn = userMap[uid];
    } else if (userMap && Object.keys(userMap).length) {
      return <div className="cl-red3">{"User does not exist"}</div>;
    } else {
      stringReturn = "";
    }
  }
  return stringReturn;
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getOrderDefault = (dataList = [], orderName = "rank") => {
  dataList = _orderBy(dataList, [orderName], ["asc"]);

  const lastItem = dataList[dataList.length - 1] || {};

  return Number(lastItem[orderName] || 0) + 1;
};

export const formatSecondToTime = (seconds) => {
  const date = new Date(seconds * 60 * 1000);

  let hh = date.getUTCHours();
  let mm = date.getUTCMinutes();

  return `${hh >= 10 ? hh : `0${hh}`}:${mm >= 10 ? mm : `0${mm}`}`;
};

export const getStatusTextByTime = (record) => {
  const { time = {}, isDaily, daily = {} } = record;
  // const { showTime = new Date(), endTime = new Date() } = time;
  const showTime = time?.showTime;
  const endTime = time?.endTime;
  let text;
  let color;
  if (Date.now() < moment.unix(showTime).valueOf()) {
    text = "Hide"; //"Chưa hiển thị";
  }

  if (
    Date.now() > moment.unix(showTime).valueOf() &&
    Date.now() < moment.unix(endTime).valueOf()
  ) {
    // text = 'Đang hiển thị';

    if (isDaily) {
      const weekDay = moment().isoWeekday();
      // console.log(object);
      if (daily.days.indexOf(weekDay) > -1) {
        const { showTime, endTime } = daily.time;

        const today = moment().format("DD-MM-YYYY");

        return getStatusTextByTime({
          time: {
            showTime: moment(
              `${today} ${formatSecondToTime(showTime)}`,
              "DD-MM-YYYY HH:mm"
            ).unix(),
            // startTime: moment(
            //   `${today} ${formatSecondToTime(startTime)}`,
            //   "DD-MM-YYYY HH:mm"
            // ),
            endTime: moment(
              `${today} ${formatSecondToTime(endTime)}`,
              "DD-MM-YYYY HH:mm"
            ).unix(),
          },
        });
      } else {
        text = "Pending schedule"; // "Chưa đến ngày đặt lịch";
      }
    } else {
      if (
        Date.now() > moment.unix(showTime).valueOf() &&
        Date.now() < moment.unix(endTime).valueOf()
      ) {
        // isTooltip = true;
        text = "Pending"; // "Chưa diễn ra";
      }

      if (
        Date.now() > moment.unix(showTime).valueOf() &&
        Date.now() < moment.unix(endTime).valueOf()
      ) {
        // isTooltip = true;
        text = "Now"; // "Đang diễn ra";
      }
    }
  }

  if (Date.now() > moment.unix(endTime).valueOf()) {
    text = "Finished"; //"Đã kết thúc";
  }
  return text;
}

export const getStatusByTime = (record) => {
  const { time = {}, isDaily, daily = {} } = record;

  const { showTime, endTime } = time;

  let text;
  let color;
  let isTooltip;
  let textTooltip;

  if (Date.now() < moment.unix(showTime).valueOf()) {
    text = "Hide"; //"Chưa hiển thị";
    color = "#f29d49";
  }

  if (
    Date.now() > moment.unix(showTime).valueOf() &&
    Date.now() < moment.unix(endTime).valueOf()
  ) {
    // text = 'Đang hiển thị';
    // color = '#15b371';

    if (isDaily) {
      const weekDay = moment().isoWeekday();
      // console.log(object);
      if (daily.days.indexOf(weekDay) > -1) {
        const { showTime, endTime } = daily.time;

        const today = moment().format("DD-MM-YYYY");

        return getStatusByTime({
          time: {
            showTime: moment(
              `${today} ${formatSecondToTime(showTime)}`,
              "DD-MM-YYYY HH:mm"
            ).unix(),
            // startTime: moment(
            //   `${today} ${formatSecondToTime(startTime)}`,
            //   "DD-MM-YYYY HH:mm"
            // ),
            endTime: moment(
              `${today} ${formatSecondToTime(endTime)}`,
              "DD-MM-YYYY HH:mm"
            ).unix(),
          },
        });
      } else {
        text = "Pending schedule"; // "Chưa đến ngày đặt lịch";
        color = "#f29d49";
      }
    } else {
      if (
        Date.now() > moment.unix(showTime).valueOf() &&
        Date.now() < moment.unix(endTime).valueOf()
      ) {
        // isTooltip = true;
        text = "Pending"; // "Chưa diễn ra";
        color = "#2b95d6";
      }

      if (
        Date.now() > moment.unix(showTime).valueOf() &&
        Date.now() < moment.unix(endTime).valueOf()
      ) {
        // isTooltip = true;
        text = "Now"; // "Đang diễn ra";
        color = "#15b371";
      }
    }
  }

  if (Date.now() > moment.unix(endTime).valueOf()) {
    text = "Finished"; //"Đã kết thúc";
    color = "#f55656";
  }

  return (
    text && (
      <>
        {isTooltip && (
          <Tooltip content={textTooltip} boundary="flip">
            <p style={{ marginTop: "5px", marginBottom: 0, color }}>
              {text}
              <Icon
                style={{ marginBottom: "1px", marginLeft: "4px" }}
                icon="info-sign"
                iconSize={12}
              />
            </p>
          </Tooltip>
        )}
        {!isTooltip && (
          <p style={{ marginTop: "5px", marginBottom: 0, color }}>{text}</p>
        )}
      </>
    )
  );
};

export const checkStatusBySchedule = (record = {}) => {
  let hasStatus;

  const status = record.status || record.activated;

  if (status === constants.STATUS_TYPE.ACTIVE) {
    hasStatus = true;
  }

  if (status === constants.STATUS_TYPE.SCHEDULE) {
    const showTime = record.showTime;
    const downTime = record.downTime;

    if (
      showTime * 1000 < Date.now() ||
      (downTime && downTime * 1000 > Date.now())
    ) {
      hasStatus = true;
    }
  }

  return hasStatus;
};

export const getTotalCount = (dataList = []) => {
  const number = [];

  dataList.map((item) => {
    if (
      item._id &&
      (item._id.type === constants.VOD_TYPE.LE ||
        item._id.type === constants.VOD_TYPE.BO ||
        item._id.type === constants.VOD_TYPE.TRANSCODE)
    ) {
      number.push(Number(item.count));
    }
  });

  return number.reduce((a, b) => {
    return a + b;
  }, 0);
};

export const getIdsByDetail = (dataDetail = {}, key) => {
  let dataItem = dataDetail[key];

  if (!dataItem) {
    return;
  }

  if (typeof dataItem === "string") {
    dataItem = [dataItem];
  }

  return [...dataItem, ""];
};

export const getDataMapLite = (data = [], dataMap) => {
  const newMap = {};

  data.forEach((id) => {
    if (dataMap[id]) {
      newMap[id] = dataMap[id];
    }
  });

  return newMap;
};

export const checkValidateIdByMap = (values = [], dataMap = {}) => {
  let isValid;

  values.forEach((id) => {
    if (!isValid) {
      isValid = !!dataMap[id];
    }
  });

  return isValid;
};

export const formatArrayToMap = (list) => {
  const dataMap = {};

  list.forEach((key) => {
    dataMap[key] = key;
  });

  return dataMap;
};

export const getDefinition = (levels = []) => {
  const maxLevel = levels.length > 0 ? levels.length - 1 : 0;

  const levelItem = levels[maxLevel] || {};

  const height = levelItem.height;

  const levelMap = {
    240: "SD",
    360: "SD",
    480: "SD",
    720: "HD",
    1080: "FullHD",
  };

  return levelMap[height];
};

export const getSignatureEPG = (params = {}) => {
  const array = [];

  Object.keys(params).map((key) => {
    array.push(params[key]);
  });

  array.push(config.secretKeyEPG);

  return md5(array.join("@"));
};

export const formatDateToLabel = (date) => {
  let label;

  switch (moment(date).format("DD-MM-YYYY")) {
    case moment().format("DD-MM-YYYY"):
      label = <Tag intent={Intent.DANGER}>Today</Tag>;
      break;

    case moment().subtract(1, "day").format("DD-MM-YYYY"):
      label = <Tag intent={Intent.SUCCESS}>Yesterday</Tag>;
      break;

    case moment().add(1, "day").format("DD-MM-YYYY"):
      label = <Tag intent={Intent.WARNING}>Tomorrow</Tag>;
      break;

    default:
      label = "";
      break;
  }

  return <div style={{ textAlign: "center" }}>{label}</div>;
};

export const getMessageTextEvent = (data = {}, submitType) => {
  const maxHour = 24;

  const { preTime, startTime, stopTime } = data;

  if (
    submitType === constants.SUBMIT_TYPE.CREATE &&
    moment(preTime).valueOf() < moment().subtract(5, "minute").valueOf()
  ) {
    return "Thời gian hiển thị phải lớn hơn hoặc bằng thời gian hiện tại!";
  }

  if (
    (submitType === constants.SUBMIT_TYPE.CREATE &&
      moment(startTime).valueOf() < moment().subtract(5, "minute").valueOf()) ||
    moment(startTime).valueOf() < moment(preTime).add(5, "minute").valueOf()
  ) {
    return "Thời gian bắt đầu phải lớn hơn hoặc bằng thời gian hiện tại và lớn hơn thời gian hiển thị ít nhất 5 phút!";
  }

  if (
    (submitType === constants.SUBMIT_TYPE.CREATE &&
      moment(stopTime).valueOf() < moment().subtract(5, "minute").valueOf()) ||
    moment(stopTime).valueOf() < moment(startTime).add(5, "minute").valueOf()
  ) {
    return "Thời gian kết thúc phải lớn hơn hoặc bằng thời gian hiện tại và lớn hơn thời gian bắt đầu ít nhất 5 phút!";
  }

  if (
    moment(startTime).add(maxHour, "hour").valueOf() <
    moment(stopTime).valueOf() ||
    moment(stopTime).valueOf() < moment(startTime).add(5, "minute").valueOf()
  ) {
    return `Thời gian kết thúc phải lớn hơn thời gian bắt đầu có sóng ít nhất 5 phút và nhỏ hơn hoặc bằng ${maxHour} giờ!`;
  }
};

export const getMessageTextSDP = (data = {}) => {
  const { time = {}, isDaily } = data;

  const { showTime, startTime, endTime } = time;

  // if (showTime && moment(showTime).valueOf() < moment().valueOf()) {
  //   return 'Thời gian hiển thị phải lớn hơn hoặc bằng thời gian hiện tại!';
  // }

  if (
    !isDaily &&
    startTime &&
    showTime &&
    moment(startTime).valueOf() < moment(showTime).valueOf()
  ) {
    return "Thời gian bắt đầu phải lớn hơn thời gian hiển thị!";
  }

  if (
    endTime &&
    startTime &&
    moment(endTime).valueOf() < moment(startTime).valueOf()
  ) {
    return "Thời gian kết thúc phải lớn hơn thời gian bắt đầu!";
  }
};

export const stringToId = (str) => {
  str = str || "";
  const from =
    "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịỳýỷỹỵäëïîöüûñç";
  const to =
    "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiyyyyyaeiiouunc";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\\-]/g, "-")
    .replace(/-+/g, "-");

  return str;
};

export const getAllFunctionByClass = (Classes) => {
  const functions = {};

  const names = Object.getOwnPropertyNames(Classes.prototype).filter(
    (x) => x !== "constructor"
  );

  names.forEach((name) => {
    functions[name] = (data, record, callback) => {
      return Classes.instance()[name](data, record, callback);
    }
  });

  return functions;
};

export const formatArrayChart = (data = {}) => {
  const getValue = (item) => {
    let value;

    if (item.group_2) {
      value = item.group_2.value
    } else {
      value = item.doc_count || item.count
    }

    return value
  }

  if (data instanceof Array) {
    return data.map(item => ({
      key: item.key,
      value: getValue(item)
    }))
  }

  const { aggregations = {} } = data;
  const { group_1 = {} } = aggregations;
  const { buckets = [] } = group_1;

  return buckets.map(item => ({
    key: item.key,
    value: getValue(item)
  }))
}

export const mergeArrayFormObject = (data = {}, config = {}) => {
  const { keyByName = 'key', valueName = 'count' } = config;

  let obj = {};

  Object.keys(data).forEach(key => {
    const dataKeys = data[key];

    if (dataKeys instanceof Array) {
      const item = _mapValues(_keyBy(dataKeys, keyByName), (value) => {
        return {
          [keyByName]: value[keyByName],
          [key]: value[valueName]
        }
      })

      obj = _merge(obj, item)
    }
  });

  return Object.values(obj).map(data => data)
}

export const formatTimeChart = (value, dataFilter, isValidate) => {
  const { timeType } = dataFilter;

  let formatText;
  let formatQueryText;
  let prefix;

  switch (timeType) {
    case constants.TIME_TYPE.MINUTE:
      formatText = 'HH:mm DD-MM';
      formatQueryText = 'YYYYMMDDHHmm'
      break;

    case constants.TIME_TYPE.HOUR:
      formatText = 'HH:mm DD-MM';
      formatQueryText = 'YYYYMMDDHH'
      break;

    case constants.TIME_TYPE.WEEK:
      const week = moment(value).format("WW");
      const monday = moment(value).day("Monday").week(week).format("DD-MM");
      const sunday = moment(value).day("Sunday").week(week).format("DD-MM");

      return `${monday} / ${sunday}`;

    case constants.TIME_TYPE.MONTH:
      formatText = 'MM-YYYY';
      break;

    default:
      formatText = 'DD-MM';
      formatQueryText = 'YYYYMMDD'
      break;
  }

  let text;

  if (isValidate) {
    text = moment(value, formatQueryText).format(formatText);
  } else {
    text = moment(value).format(formatText);
  }

  if (prefix) {
    return prefix + text;
  }

  return text;
}

export const getMinMaxDateChart = () => {
  return {
    minDate: (data) => {
      return moment().subtract(30, 'day')
    },
    maxDate: (data) => {
      const { timeType } = data;

      console.log('data', data);

      if (timeType) {

      }

      return moment()
    }
  }
};
