import React, { createElement } from "react";
import moment from "moment";
import config from "src/utils/config";
import Manager from "./manager";
import constants from "../../../utils/constants";
import { messageHandler, saveAs, getFilename, confirmHandler } from "blueprint-hooks-ui";
import { Toast, Toaster, Intent } from "@blueprintjs/core"

let instanceId = null;

class Global extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Global();
    }
    return instanceId;
  }

  checkExistById(data, apiEndpoint, callback, keyCheck) {
    this.fetch({
      apiConfig: {
        url: `${apiEndpoint}/${keyCheck ? data[keyCheck] : data.id}`,
      },
      hideMessageFail: true,
      handleSuccess: (data = {}) => {
        // const { exist } = data;
        //
        // if (exist) {
        //   messageHandler.warning('ID đã tồn tại!')
        // } else {
        //   callback && callback();
        // }
      },
      handleFail: () => {
        callback && callback();
      },
      // handleAction: (data) => {
      //   console.log('data checkExist', data);
      // }
    });
  }

  query(url, param, callbackSuccess, callbackFail, method) {
    this.fetch({
      apiConfig: {
        url: `${url + param}`,
        method: method ? method : "GET",
      },
      handleSuccess: (data = {}) => {
        callbackSuccess && callbackSuccess(data);
      },
      handleFail: (err) => {
        callbackFail && callbackFail(err);
      },
    });
  }
  blockCustomer(apiConfig, callbackSuccess, callbackFail) {
    this.fetch({
      apiConfig: {
        ...apiConfig,
        // url: `${url + param}`,
        // method: "PUT",
      },
      handleSuccess: (data = {}) => {
        callbackSuccess && callbackSuccess(data);
      },
      handleFail: (err) => {
        callbackFail && callbackFail(err);
      },
    });
  }
  kickSession(url, param, callbackSuccess, callbackFail) {
    this.fetch({
      apiConfig: {
        url: `${url + param}`,
        method: "DELETE",
      },
      handleSuccess: (data = {}) => {
        callbackSuccess && callbackSuccess(data);
      },
      handleFail: (err) => {
        callbackFail && callbackFail(err);
      },
    });
  }
  getArrayNameVOD(strArray, callbackSuccess, callbackFail) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.GET_NAME_SPOTLIGHT}?ids=${strArray}`,
        method: "GET",
      },
      handleSuccess: (data = {}) => {
        callbackSuccess && callbackSuccess(data);
      },
      handleFail: (err) => {
        callbackFail && callbackFail(err);
      },
    });
  }
  getArrayNameCatalog(strArray, callbackSuccess, callbackFail) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.NODE_SELECTOR}?ids=${strArray}`,
        method: "GET",
      },
      handleSuccess: (data = {}) => {
        callbackSuccess && callbackSuccess(data);
      },
      handleFail: (err) => {
        callbackFail && callbackFail(err);
      },
    });
  }
  export({ apiEndpoint, data, callback, filename = 'file', apiConfig = {} }) {
    if (data?.p) {
      data.p = data.p - 1;
    }

    const detectSafari = () => {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') !== -1) {
        if (ua.indexOf('chrome') > -1) {
          return false
        } else {
          return true
        }
      } return false;
    }

    this.fetch({
      apiConfig: {
        url: `${apiEndpoint}/export`,
        ...apiConfig,
        // responseType: 'blob',
        timeout: null,
        data: {
          ...data
        }
      },
      hideMessage: true,
      // messageTimeout: detectSafari() ? 10 : 3,
      callback: (data, headers) => {
        const toast = Toaster.create({
          position: "top",
        })
        toast.show({
          message: detectSafari() ? 'File is downloading. Please wait some minute!' : 'Export data success',
          intent: 'success',
          timeout: 5000
        })
        function downloadPDF(url) {
          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = function (e) {
            if (this.status === 200) {
              var myBlob = this.response;
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(myBlob);
              const urlSplited = data.DATA.split('/');
              const name = urlSplited[urlSplited.length - 1];
              link.download = name;
              link.click();
            }
          };
          xhr.send();
        }
        const urlSplited = data.DATA.split('/');
        const name = urlSplited[urlSplited.length - 1];
        console.log("name", name)
        if (detectSafari()) {

          downloadPDF(data.DATA);
        } else {
          window.open(data.DATA, '_parent');
        }
      },
    })
  }

  handleExport({
    apiConfig,
    data
  }) {
    return confirmHandler({
      title: 'Notification',
      content: 'Are you doing export data?',
      onOk: () => {
        this.export({
          apiEndpoint: '',
          apiConfig: apiConfig,
          data: data,
          filename: 'Metadata'
        })
      }
    })
  }
}

export default Global;
