import React, { useEffect } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { useSetState, request, fetchData, useAuth } from 'blueprint-hooks-ui';
import EventONList from 'src/components/blocks/EventONList';
import constants from 'src/utils/constants';

import './index.less';
import config from "../../../utils/config";

const ButtonChangeEventON = ({
                               data,
                               handleSetValueForm,
                               handleSelectEventON,
                               submitType,
                               openDialog,
                               storageKey = process.env.STORAGE_KEY || 'user',
                               tokenName = process.env.TOKEN_NAME || 'accessToken',
                             }) => {
  const auth = useAuth();

  const dataStorage = auth[storageKey];

  const token = dataStorage[tokenName];

  const [state, setState] = useSetState({
    eventName: null
  });

  const { eventName } = state;

  const sourceId = data.target && data.target.sourceId;

  useEffect(() => {
    if (submitType === constants.SUBMIT_TYPE.UPDATE) {
      fetchData({
        fetchApi: request({
          method: 'GET',
          url: `${config.apiEndpoint.SDP_EVENT_ON}/events-cms/${sourceId.replace('event_', '')}`,
          token,
          auth
        }),
        handleSuccess: (data = {}) => {
          setState({
            eventName: data.name
          })
        },
        hideMessage: true
      })
    }
  }, []);

  return (
    <>
      {
        sourceId
        &&
        <span style={{ marginRight: '5px' }}>{eventName || sourceId}</span>
      }
      <Button
        intent={Intent.PRIMARY}
        text={sourceId ? 'Đổi' : 'Chọn'}
        onClick={() => {
          openDialog({
            dialogTitle: 'Chọn sự kiện luồng ON',
            dialogContent: (
              <EventONList
                handleSelectEventON={(record) => {
                  setState({
                    eventName: record.name
                  });

                  handleSelectEventON(record);
                }}
              />
            ),
            dialogHideFooter: true
          })
        }}
      />
    </>
  )
};

export default ButtonChangeEventON
