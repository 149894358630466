import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Checkbox, Tooltip, Position, Intent, Button } from '@blueprintjs/core';
import { BpPopoverBox, BpForm, useSetState, fetchData, useAuth, request, formatDateTime } from 'blueprint-hooks-ui';
import constants from 'src/utils/constants';
import define from 'src/utils/define';

import './index.less';

const checkTime = (value) => {
  let intent = Intent.NONE;

  if (Date.now() > value * 1000) {
    intent = Intent.DANGER
  }

  return {
    intent
  }
};

const StatusBox = ({
                     value,
                     record,
                     apiConfig,
                     statusName = 'status',
                     refreshData = () => {
                     },
                     storageKey = process.env.STORAGE_KEY || 'user',
                     tokenName = process.env.TOKEN_NAME || 'accessToken',
                     scope
                   }) => {
  const auth = useAuth();

  const dataStorage = auth[storageKey];

  const token = dataStorage[tokenName];

  const { intent } = checkTime(value);

  const [, render] = useState();

  const reRender = useCallback(() => {
    render({});
  }, []);

  useEffect(() => {
    reRender();
  }, [value]);

  // const { checked, tooltipText, intent, className } = checkStatus(value, record);

  const [state, setState] = useSetState({
    isOpen: false
  });


  const handleOpen = () => {
    setState({
      isOpen: true
    })
  };

  const handleClose = () => {
    setState({
      isOpen: false
    })
  };

  const vodState = record.state;

  return (
    <div>
      <div className={classNames('app-date-box-time', {
        ['app-date-box-danger']: intent === Intent.DANGER
      })}>
        <div>{formatDateTime(value * 1000, 'DD-MM-YYYY')}</div>
        <div>{formatDateTime(value * 1000, 'HH:mm:ss')}</div>
      </div>
      {
        (vodState === constants.STATE_VOD_TYPE.EXPIRED && scope !== constants.SCOPE_TYPE.ADMIN)
        &&
        <div className="app-date-box-btn">
          <BpPopoverBox
            isFetchApi
            width={260}
            title={`${record.title}`}
            defaultValues={{
              expireDate: value,
              availabilityDate: record.availabilityDate
            }}
            content={(
              <BpForm
                fieldForm={[
                  {
                    title: 'Ngày hiển thị',
                    dataIndex: 'availabilityDate',
                    type: constants.FORM_TYPE.DATE_TIME,
                    isRequired: true,
                    changeValue: (value) => {
                      return moment(value).unix();
                    },
                    formatValue: (value) => {
                      return value && moment.unix(value).toDate();
                    },
                    minDate: (data) => {
                      return moment();
                    },
                    isHide: true
                  },
                  {
                    title: 'Ngày hết hạn',
                    dataIndex: 'expireDate',
                    type: constants.FORM_TYPE.DATE_TIME,
                    changeValue: (value) => {
                      return moment(value).unix();
                    },
                    formatValue: (value) => {
                      return value && moment.unix(value).toDate();
                    },
                    minDate: (data) => {
                      if (data.availabilityDate) {
                        if (data.availabilityDate * 1000 < Date.now()) {
                          return moment().add(1, 'day')
                        } else {
                          return moment.unix(data.availabilityDate).add(1, 'day')
                        }
                      } else {
                        return moment().add(1, 'day')
                      }
                    },
                    // isHide: ({ data }) => {
                    //   return vodId || (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
                    // },
                    helperText: 'Bỏ trống nếu nội dung có bản quyền vĩnh viễn.',
                    // isCol: true,
                    // colSpan: 4,
                  },
                ]}
              />
            )}
            isOpen={state.isOpen}
            onClose={handleClose}
            position={Position.LEFT}
            onOk={(data) => {
              fetchData({
                fetchApi: request({
                  method: 'PUT',
                  ...apiConfig,
                  url: `${apiConfig.url}/${record.id}`,
                  data: {
                    ...apiConfig.data,
                    ...data
                  },
                  token,
                  auth
                }),
                handleSuccess: (data) => {
                  handleClose();

                  refreshData();
                }
              })
            }}
          >
            <Button
              icon="calendar"
              small
              onClick={handleOpen}
            />
          </BpPopoverBox>
        </div>
      }
    </div>
  )
};

export default StatusBox
