import React from 'react';
import config from 'src/utils/config';
import Manager from './manager';

let instanceId = null;

class Global extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Global();
    }
    return instanceId;
  }
}

export default Global;
