import React from 'react';
import config from 'src/utils/config';
import Manager from './manager';

let instanceId = null;

class User extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new User();
    }
    return instanceId;
  }
}

export default User;
