import React from "react";
import { BpTabs } from "blueprint-hooks-ui";
import VodSingleList from "src/components/blocks/VodSingleList";
import VodSeriesList from "src/components/blocks/VodSeriesList";
import VodTimeshiftList from "src/components/blocks/VodTimeshiftList";
import MagazineList from "src/components/blocks/MagazineList";

import "./index.less";

const MetadataTab = ({ handleSelect, screenType}) => {
  return (
    <BpTabs
      hasInview
      className="app-metadata-tab"
      tabList={[
        {
          id: "single",
          title: "Movie",
          icon: "film",
          hide: screenType && screenType !== "vod",
          panel: (
            <VodSingleList
              isAddRecord={false}
              isSelect
              handleSelect={handleSelect}
            />
          ),
        },
        {
          id: "series",
          title: "Series",
          icon: "video",
          hide: screenType && screenType !== "vod",
          panel: (
            <VodSeriesList
              isAddRecord={false}
              isSelect
              handleSelect={handleSelect}
            />
          ),
        },
        {
          id: "magazine",
          title: "Magazine",
          icon: "paper",
          hide: screenType && screenType === "vod",
          panel: (
            <MagazineList 
              isAddRecord={false}
              isSelect
              handleSelect={handleSelect}
            />
          )
        }
      ]}
    />
  );
};

export default MetadataTab;
