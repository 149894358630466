import React, { useRef, useEffect } from "react";
import { Icon } from "@blueprintjs/core";
import { formatDateTime, moment } from "blueprint-hooks-ui";

import "./index.less";

const TimeBox = ({ time }) => {
  return (
    <div className="app-time-box">
      {time.showTime && (
        <div className="app-time-item">
          <label title="Thời gian hiển thị">Show Date</label>
          <span>
            {moment.unix(time.showTime).format("DD-MM-YYYY HH:mm:ss")}
          </span>
          <div className="app-time-arrow">
            <Icon icon="arrow-down" />
          </div>
        </div>
      )}
      {/* {time.startTime && (
        <div className="app-time-item">
          <label title="Thời gian bắt đầu">Bắt đầu</label>
          <span>
            {moment.unix(time.startTime).format("DD-MM-YYYY HH:mm:ss")}
          </span>
          <div className="app-time-arrow">
            <Icon icon="arrow-down" />
          </div>
        </div>
      )} */}
      {time.endTime && (
        <div className="app-time-item">
          <label title="Thời gian kết thúc">End Date</label>
          {moment.unix(time.endTime).format("DD-MM-YYYY HH:mm:ss")}
        </div>
      )}
    </div>
  );
};

export default TimeBox;
