import constants from "src/utils/constants";

let initialState = {
  theme: "white",
  dataState: {},
  dataSplash: {},
  // dataHome: {},
  dataSearch: [],
  dataDetail: {},
  dataRelate: {},
  dataList: {},
  dataMedia: {},
  dataMediaSource: {},
  dataMediaState: {},
  dataCatalog: {},
  dataCatalogMovie: [],
  dataCatalogChannel: [],
  dataCatalogMenu: {},
  dataProgram: {},
  dataAbout: "",
  dataModal: {},
  showModal: false,
  dataKaraokePlay: {},
  dataKaraokeSelected: [],
  positionUid: [],
  users: [],
  profile: {},
  notifications: {
    number: "",
    data: [],
  },
  dataHomeConfig: {},
  broadcastSchedule: {
    total: [],
    listProgramChannel: [],
    listLogoChannel: [],
    hasMore: true,
    day: "",
    changeDay: false,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case constants.ACTION_TYPE.UPDATE_STATE:
      const dataUpdate = {};

      if (payload.dataDetail) {
        dataUpdate.dataDetail = Object.assign(
          {},
          state.dataDetail,
          payload.dataDetail
        );
      }

      if (payload.dataRelate) {
        dataUpdate.dataRelate = Object.assign(
          {},
          state.dataRelate,
          payload.dataRelate
        );
      }

      if (payload.dataState) {
        dataUpdate.dataState = Object.assign(
          {},
          state.dataState,
          payload.dataState
        );
      }

      if (payload.dataMediaState) {
        dataUpdate.dataMediaState = Object.assign(
          {},
          state.dataMediaState,
          payload.dataMediaState
        );
      }

      if (payload.dataMediaSource) {
        dataUpdate.dataMediaSource = Object.assign(
          {},
          state.dataMediaSource,
          payload.dataMediaSource
        );
      }

      if (payload.dataProgram) {
        dataUpdate.dataProgram = Object.assign(
          {},
          state.dataProgram,
          payload.dataProgram
        );
      }

      if (payload.dataCatalog) {
        dataUpdate.dataCatalog = Object.assign(
          {},
          state.dataCatalog,
          payload.dataCatalog
        );
      }

      if (payload.dataCatalogMenu) {
        dataUpdate.dataCatalogMenu = Object.assign(
          {},
          state.dataCatalogMenu,
          payload.dataCatalogMenu
        );
      }

      if (payload.dataModal) {
        dataUpdate.dataModal = Object.assign(
          {},
          state.dataModal,
          payload.dataModal
        );
      }

      return {
        ...state,
        ...payload,
        ...dataUpdate,
      };

    case constants.ACTION_TYPE.SHOW_MODAL:
      const dataModal = Object.assign({}, state.dataModal, payload.dataModal);

      return {
        ...state,
        ...payload,
        dataModal,
        showModal: true,
      };

    case constants.ACTION_TYPE.HIDE_MODAL:
      return {
        ...state,
        ...payload,
        showModal: false,
      };

    case constants.THEME_ACTION_TYPE.CHANGE_THEME:
      return {
        ...state,
        theme: state.theme === "white" ? "dark" : "white",
      };

    case constants.ACTION_TYPE.GET_USERS:
      return {
        ...state,
        users: payload.users,
      };

    case constants.ACTION_TYPE.GET_PROFILE:
      return {
        ...state,
        profile: payload.profile,
      };

    case constants.ACTION_TYPE.SET_NOTIFY_INFO:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...payload,
        },
      };

    case constants.ACTION_TYPE.GET_SPOTLIGHT_SDP:
      return {
        ...state,
        dataSpotlight: payload.dataSpotlight,
      };

    case constants.ACTION_TYPE.GET_MENU_SDP:
      return {
        ...state,
        dataHomeMenu: payload.dataHomeMenu,
      };

    case constants.ACTION_TYPE.GET_EVENT_SDP:
      return {
        ...state,
        dataHomeEvent: payload.dataHomeEvent,
      };

    case constants.ACTION_TYPE.GET_NOTIFICATION_SDP:
      return {
        ...state,
        dataHomeNotification: payload.dataHomeNotification,
      };

    case constants.ACTION_TYPE.GET_CONFIG_SDP:
      return {
        ...state,
        dataHomeConfig: payload.dataHomeConfig,
      };

    case constants.ACTION_TYPE.GET_ADS_CONFIG:
      return {
        ...state,
        dataAds: payload.dataAds
      };

    case constants.ACTION_TYPE.GET_BROADCAST_SCHEDULE:
      return {
        ...state,
        broadcastSchedule: {
          ...state.broadcastSchedule,
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
