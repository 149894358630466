import {
  request,
  fetchData,
  messageHandler,
  saveAs,
  mimeTypes,
  getFilename,
  getDataIdentity,
} from "blueprint-hooks-ui";
import config from "../../../utils/config";

class Manager {
  init({ dataStorage, auth }) {
    if (auth) {
      this.auth = auth;
    }

    if (dataStorage) {
      this.storage = dataStorage;
      this.uid = dataStorage.uid;
      this.token = dataStorage.accessToken;
      this.userInfo = dataStorage.userInfo;
      this.apiUrl = dataStorage.url;
    }
  }

  fetch({ apiConfig, hideMessage = true, callback = () => {}, ...params }) {
    fetchData({
      fetchApi: request({
        ...apiConfig,
        token: this.token,
        auth: this.auth,
      }),
      hideMessage,
      handleSuccess: (data, headers) => {
        callback(data, headers);
      },
      ...params,
    });
  }
}
export default Manager;
