import React, { Component } from 'react';
import classNames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import { Tree, InputGroup, Button } from '@blueprintjs/core';
import { listToTreeComponent, getArrayUniqueValue, getKeyExpaned } from 'src/helpers';

import './index.less';

class TreeCustom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      parentKey: this.props.parentKey || 'parentId',
      expandedKey: getKeyExpaned(this.props.data, this.props.selectedKey, []),
      iconSet: this.props.iconSet || { default: this.props.isMultiple ? 'square' : 'circle', selected: 'tick-circle' },
      query: '',
      selectedKey: {}
    };

    this.handleNodeExpand = this.handleNodeExpand.bind(this);
    this.handleNodeCollapse = this.handleNodeCollapse.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.resetQuery = this.resetQuery.bind(this);

    this.handleFilter = _debounce(this.handleFilter.bind(this), 200);
  }

  componentWillReceiveProps(nextProps) {
    const { data, parentKey, iconSet, selectedKey } = this.props;

    if (nextProps.data && data.length !== nextProps.data.length) {
      this.state.data = nextProps.data;

      if (selectedKey === nextProps.selectedKey) {
        this.state.expandedKey = getKeyExpaned(nextProps.data, selectedKey, []);
      }
    }

    if (nextProps.parentKey && parentKey !== nextProps.parentKey) {
      this.state.parentKey = nextProps.parentKey;
    }

    if (nextProps.iconSet && iconSet !== nextProps.iconSet) {
      this.state.iconSet = nextProps.iconSet;
    }

    this.setState(this.state);
  }

  handleNodeExpand(node) {
    const { onNodeExpand } = this.props;
    let { expandedKey } = this.state;
    const newExpandedKey = getArrayUniqueValue([...expandedKey, node.id]);

    this.setState({
      expandedKey: newExpandedKey
    }, () => {
      onNodeExpand && onNodeExpand(node);
    })
  };

  handleNodeCollapse(node) {
    const { onNodeCollapse } = this.props;
    let { expandedKey } = this.state;
    expandedKey.splice(expandedKey.indexOf(node.id), 1);
    expandedKey = getArrayUniqueValue(expandedKey);

    // console.log('expandedKey', expandedKey);

    this.setState({
      expandedKey
    }, () => {
      onNodeCollapse && onNodeCollapse(node);
    })
  };

  handleNodeClick(node) {
    let { onNodeClick, isMultiple, selectedKey = [] } = this.props;

    if (selectedKey === null) {
      selectedKey = []
    }

    if (isMultiple) {
      const indexCurrent = selectedKey.indexOf(node.id);

      if (indexCurrent === -1) {
        selectedKey.push(node.id);

        if (this.props.isTickAll) {
          if (node.childNodes && node.childNodes.length > 0) {
            this.handleNodeExpand(node);

            node.childNodes.forEach(item => {
              selectedKey.push(item.id);
            })
          }
        }
      } else {
        selectedKey.splice(indexCurrent, 1);

        if (this.props.isTickAll) {
          if (node.childNodes && node.childNodes.length > 0) {
            node.childNodes.forEach(item => {
              const index = selectedKey.indexOf(node.id);

              selectedKey.splice(index, 1);
            })
          }
        }
      }

      selectedKey = getArrayUniqueValue(selectedKey);
    } else {
      if (selectedKey === node.id) {
        selectedKey = '';
      } else {
        selectedKey = node.id;
      }
    }

    onNodeClick && onNodeClick(selectedKey);

    // if (!node.childNodes || (node.childNodes && node.childNodes.length === 0)) {
    //   onNodeClick && onNodeClick(selectedKey);
    // } else {
    //   if (isMultiple && !this.props.isTickAll) {
    //     const indexCurrent = selectedKey.indexOf(node.id);

    //     if (indexCurrent !== -1) {
    //       selectedKey.splice(indexCurrent, 1);
    //     }
    //   }

    //   onNodeClick && onNodeClick(selectedKey);
    // }
  };

  resetQuery() {
    this.setState({
      query: '',
      data: this.props.data
    })
  };

  handleFilter(query) {
    const { data } = this.props;

    const newData = data.filter(item => {
      const normalizedTitle = item.title.toLowerCase();
      const normalizedQuery = query.toLowerCase();

      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    });

    const cloneData = _cloneDeep(newData);

    cloneData.forEach((item) => {
      if (item.parentId && item.parentId !== 'root' && !newData.find(i => i.id === item.parentId)) {
        const parentItem = data.find(i => i.id === item.parentId);

        newData.push(parentItem)
      }
    });

    this.setState({
      data: newData
    })
  };

  render() {
    const { className = 'form-ref-node', selectedKey, isPreview } = this.props;
    const { data, expandedKey, iconSet, query } = this.state;
    let dataList;

    // console.log('this.state', this.state);

    if (isPreview) {
      dataList = [];

      let parentIds = [];

      selectedKey && selectedKey.map(key => {
        let dataParent;
        const dataItem = data.find(item => item.id === key);

        if (dataItem) {
          dataItem.disabled = true;

          const parentId = dataItem.parentId;

          if (parentIds.indexOf(parentId) === -1) {
            dataParent = data.find(item => item.id === dataItem.parentId);
          }

          if (dataParent) {
            dataParent.disabled = true;

            parentIds.push(dataItem.parentId);
            dataList.push(dataParent);
          }

          if (!dataList.find(item => item.id === dataItem.id)) {
            dataList.push(dataItem)
          }
        }
      })
    }

    return (
      <div>
        {
          !isPreview && <InputGroup
          leftIcon="search"
          rightElement={query.length > 0 && <Button icon="cross" minimal={true} onClick={this.resetQuery} />}
          style={{ marginBottom: '5px' }}
          onChange={(e) => {
            const query = e.target.value;

            this.setState({
              query
            });

            this.handleFilter(query)
          }}
          value={query}
        />
        }
        <Tree
          className={className}
          onNodeExpand={this.handleNodeExpand}
          onNodeCollapse={this.handleNodeCollapse}
          onNodeClick={this.handleNodeClick}
          contents={
            listToTreeComponent({
              list: dataList || data,
              selectedKey: selectedKey,
              expandedKey: expandedKey,
              iconSet: iconSet,
              isTickAll: this.props.isTickAll,
              isDisabled: this.props.isDisabled
            })
          }
        />
      </div>
    )
  }
}

export default TreeCustom
