import React, { Component } from 'react';
import { BpBase, ButtonAction, moment } from 'blueprint-hooks-ui';
import constants from 'src/utils/constants';
import config from 'src/utils/config';
import define from 'src/utils/define';

import './index.less';

const ChannelScheduleEventList = ({ dataStorage, handleSelectProgram, currentId, channelId }) => {
  const fieldFilter = [
    {
      title: 'Chọn ngày',
      dataIndex: 'paramDate',
      type: constants.FILTER_TYPE.SELECT,
      dataMap: define.dateFromTodayMap,
      defaultValue: moment().format('YYYYMMDD')
    }
  ];

  const fieldList = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt'
    },
    {
      title: 'Tên chương trình',
      dataIndex: 'title',
      key: 'title',
      width: 240,
      render: (value, record) => {
        return `${value}${record.subTitle && ` (${record.subTitle})`}`
      }
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      key: 'time',
      align: 'right',
      render: (value, record) => {
        return `${record.startTime || 'N/A'} - ${record.endTime || 'NA'}`
      }
    },
    {
      // title: 'Chọn',
      dataIndex: 'choice',
      key: 'choice',
      align: 'center',
      render: (value, record) => {
        if (record.status) {
          return (
            <ButtonAction
              text="Chọn"
              disabled={currentId === record.id}
              onClick={() => {
                handleSelectProgram(record);
              }}
            />
          )
        }
      }
    }
  ];

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isDeleteRecord={false}
        fieldFilter={fieldFilter}
        fieldList={fieldList}
        orderName="index"
        orderType="asc"
        apiConfig={({ dataQuery }) => {
          const { paramDate = moment().format('YYYYMMDD') } = dataQuery;

          return {
            list: {
              url: `${config.apiEndpoint.CHANNEL_SCHEDULE}/${channelId}/${paramDate}`
            }
          }
        }}
        listProps={{
          isPagination: false
        }}
      />
    </div>
  )
};

export default ChannelScheduleEventList
