import React from "react";
import {
  Button,
  Intent,
  Position,
  Tag,
  Tooltip,
  Checkbox,
  Icon,
} from "@blueprintjs/core";
import {
  request,
  fetchData,
  changeToSlug,
  confirmHandler,
  formatDateTime,
  ActionBox,
  messageHandler,
  TextBox,
  ButtonAction,
  formatNumber,
  checkPermission,
  moment,
  BpForm,
  queryString,
  randomSecretKey,
  StatusCheckbox,
} from "blueprint-hooks-ui";
import {
  formatBooleanToNumber,
  getAssetFile,
  getAssetFileName,
  getAssetUrl,
  getDataAsset,
  getFileName,
  getFileType,
  getUrlTimeshiftToSource,
  getDurationByTimeshift,
  formatUrlTimeshift,
  getKeyExpaned,
  getRouteTimeshift,
  getUser,
  randomIntFromInterval,
  getStatusByTime,
  formatSecondToTime,
  getDataImage,
  getIdsByDetail,
  getDataMapLite,
  checkValidateIdByMap,
  formatArrayToMap,
  formatStateColor,
  randomSecretId,
  getAssetImageUrl,
  formatListToTree
} from "src/helpers";
import ActionManager from "src/commons/ActionManager";
import TreeSelect from "src/components/blocks/TreeSelect";
import TreeCustom from "src/components/blocks/TreeCustom";
import DateBox from "src/components/blocks/DateBox";
import ButtonChangeVOD from "src/components/blocks/ButtonChangeVOD";
import VodSeriesList from "src/components/blocks/VodSeriesList";
import VodPreview from "src/components/blocks/VodPreview";
import PeopleBox from "src/components/blocks/PeopleBox";
import ApproveList from "src/components/blocks/ApproveList";
import config from "src/utils/config";
import constants from "src/utils/constants";
import define from "src/utils/define";
import Manager from "./manager";

import difference from 'lodash';

let instanceId = null;

class Metadata extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Metadata();
    }
    return instanceId;
  }



  getConfigDataMapVod() {
    return [
      {
        keyMap: "nodeRefsMap",
        api: {
          url: config.apiEndpoint.NODE_SELECTOR,
          data: {
            status: 1,
            limit: 1000
          }
        },
        valueName: "title",
      },
      {
        keyMap: "userMap",
        api: {
          url: config.apiEndpoint.ALL_USER,
        },
        valueName: "username",
        keyName: "uid",
      },
      {
        keyMap: "nationMap",
        api: {
          url: config.apiEndpoint.CATEGORY,
          data: {
            type: constants.CATEGORY_TYPE.NATION,
            limit: 200
          },
        },
      },
      {
        keyMap: "genreMap",
        api: {
          url: config.apiEndpoint.CATEGORY,
          data: {
            type: constants.CATEGORY_TYPE.GENRE,
            limit: 200
          },
        },
      },
      {
        keyMap: "peopleMap",
        api: {
          url: config.apiEndpoint.CATEGORY,
          data: {
            type: constants.CATEGORY_TYPE.PEOPLE,
            limit: 200
          },
        },
      },
      {
        keyMap: "languageMap",
        api: {
          url: config.apiEndpoint.CATEGORY,
          data: {
            type: constants.CATEGORY_TYPE.LANGUAGE,
            limit: 200
          },
        },
      },
      {
        keyMap: 'planMap',
        api: {
          url: config.apiEndpoint.PAYMENT_PLAN
        },
        // keyName: 'code'
      },
      {
        keyMap: 'instructorMap',
        api: {
          url: config.apiEndpoint.INSTRUCTOR,
          data: {
            limit: 1000
          }
        }
      }
    ];
  }

  getFieldFormPeople() {
    return [
      {
        title: "Name",
        dataIndex: "name",
        type: constants.FORM_TYPE.STRING,
        isRequired: true,
        onChange: ({ value, handleSetValueForm }) => {
          handleSetValueForm(
            "id",
            changeToSlug(value, {
              notSpace: true,
            })
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
        type: constants.FORM_TYPE.STRING,
        isDisabled: true,
      },
      {
        title: "Type",
        dataIndex: "type",
        defaultValue: constants.CATEGORY_TYPE.PEOPLE,
        isHide: true,
        isRequired: true,
      },
      {
        title: "Image",
        dataIndex: "image",
        type: constants.FORM_TYPE.UPLOAD,
        acceptFile: "image/*",
        api: config.apiUploadOther,
        typeUpload: constants.IMAGE_TYPE.LANDSCAPE,
        uploadName: constants.UPLOAD_NAME.FILE_UPLOAD,
        changeValue: (value) => {
          // console.log('value', value);

          return getAssetFileName(value, "name");
        },
        formatValue: (value) => {
          return getAssetImageUrl(value);
        },
        getDataFile: (value) => {
          return value.name;
        },
      },
      {
        title: "Status",
        dataIndex: "activated",
        type: constants.FORM_TYPE.CHECKBOX,
        defaultValue: 1,
      },
    ];
  }

  // fieldFilter
  getFieldFilterVod({ dataMapState = {}, type }) {
    // console.log("getFieldFilterVod", dataMapState)
    const { instructorMap = {} } = dataMapState;
    return [

      {
        title: "Title",
        dataIndex: "s",
      },
      {
        title: "External ID",
        dataIndex: "externalId",
      },
      // {
      //   title: "Package",
      //   dataIndex: "planId",
      //   type: constants.FORM_TYPE.SELECT,
      //   dataMap: dataMapState?.planMap,
      //   isNumber: true
      // },
      {
        title: "Type",
        dataIndex: "type",
        type: constants.FILTER_TYPE.SELECT,
        dataMap: {
          0: "Single",
          2: "Episode",
        },
        isHide: type !== constants.VOD_TYPE.TIMESHIFT,
      },
      {
        title: "State",
        dataIndex: "state",
        type: constants.FILTER_TYPE.SELECT,
        dataMap: define.stateVodMap,
        // isHide: type !== constants.VOD_TYPE.TIMESHIFT
      },

      {
        title: "Definition",
        dataIndex: "definition",
        type: constants.FILTER_TYPE.SELECT,
        dataMap: define.vodDefinitionMap,
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Catalog",
        dataIndex: "nodeRefs",
        // placeholder: "None",
        autoWidth: true,
        // type: constants.FILTER_TYPE.SELECT,
        // map: dataMapState.nodeRefsMap || {},
        // filterable: true,
        render: ({ data, handleFilter, handleOnChange, ...props }) => {
          console.log("render nodeRefs", dataMapState);
          return (
            <TreeSelect
              value={data["nodeRefs"]}
              dataList={dataMapState["nodeRefsMapFull"]}
              handleChange={(value) => {
                handleOnChange(value)
                // handleFilter({
                //   nodeRefs: value,
                // })
              }}
            />
          );
        },
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Language",
        dataIndex: "language",
        type: constants.FILTER_TYPE.SELECT,
        autoWidth: true,
        dataMap: dataMapState["languageMap"] || {},
        filterable: true,
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Allow Nation",
        dataIndex: "allowNation",
        type: constants.FILTER_TYPE.SELECT,
        autoWidth: true,
        dataMap: {
          all: "All",
          ...dataMapState["nationMap"] || {},
        },
        isHide: type === constants.VOD_TYPE.EPISODE,
        filterable: true
      },
      {
        title: "Block Nation",
        dataIndex: "blockNation",
        type: constants.FILTER_TYPE.SELECT,
        autoWidth: true,
        dataMap: dataMapState["nationMap"] || {},
        isHide: type === constants.VOD_TYPE.EPISODE,
        filterable: true
      },
      // {
      //   title: "Screen Type",
      //   dataIndex: "screenType",
      //   type: constants.FORM_TYPE.SELECT,
      //   defaultValue: 'vod',
      //   dataMap: () => {
      //     let temp = {
      //       ...define.screenTypeMap
      //     }
      //     delete temp.header;
      //     return temp; 
      //   }
      // },
      {
        title: "Instructor",
        dataIndex: "instructors",
        type: constants.FORM_TYPE.SELECT_MULTI,
        dataMap: instructorMap
      }
      // {
      //   title: "Allow Nation",
      //   dataIndex: "allowNation",
      //   type: constants.FILTER_TYPE.SELECT,
      //   autoWidth: true,
      //   dataMap: dataMapState["nationMap"] || {},
      //   isHide: type === constants.VOD_TYPE.EPISODE,
      //   filterable: true
      // }
    ];
  }

  // fieldList
  getFieldListVod({
    type,
    vodState,
    scope,
    censor,
    dataMapState,
    handleUpdate,
    handleOpenDialog,
    refreshData,
    handleCloseDialog,
    handleUpdateStatus,
    screenType
  }) {
    this.initDialog({ handleOpenDialog, handleCloseDialog, refreshData });

    const { userMap = {}, instructorMap = {} } = dataMapState;

    const titleProps = {
      render: (value, record) => {
        const vodState = record.state;

        const {userInfo} = this.storage;
        console.log("userInfo", userInfo);

        const {scope = "admin"} = userInfo;

        const externalId = record.externalId;
        if (parseInt(vodState) === constants.STATE_VOD_TYPE.DRAFT && scope === "btv") {
          return value;
        }

        // if (externalId) {
        //   value += ` (${externalId})`
        // }

        // if (vodState === constants.STATE_VOD_TYPE.EXPIRED) {
        //   return value;
        // }

        


        return (
          <a

            onClick={() => {
              if (screenType === constants.SCREEN_TYPE.MAGAZINE) {
                this.router.push(
                  `/media/magazine/${encodeURIComponent(record.id)
                  }`
                );
                return;
              }
              const parentPath =
                record.parentId && record.parentId !== "root"
                  ? `/${record.parentId}`
                  : "";

              this.router.push(
                `/media/vod/${define.vodPath[record.type]}${parentPath}/${encodeURIComponent(record.id)
                }`
              );
            }}
          >
            {value}
          </a>
        );
      },
    };

    return [
      {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "20vw",
        ...titleProps,
      },
      {
        title: "External ID",
        dataIndex: "externalId",
        key: "externalId",
        // isFilter: true,
        isClipboard: true,
        clipboardStyle: {
          '-webkit-line-clamp': 'initial'
        }
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "center",
        width: "5rem",
        render: (value, record) => {
          if (record?.screenType === constants.SCREEN_TYPE.MAGAZINE) {
            return "Magazine"
          }
          return define.vodTypeMap[value];
        },
        isHide: type !== constants.VOD_TYPE.TIMESHIFT,
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        align: "center",
        width: 100,
        render: (value) => {
          return formatStateColor(value, define.stateVodMap[value]);
        },
        // isHide: type !== constants.VOD_TYPE.TIMESHIFT
      },
      {
        title: "Episode",
        dataIndex: "episodeNumber",
        key: "episodeNumber",
        align: "center",
        width: 100,
        isHide: type !== constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Channel",
        dataIndex: "channelId",
        key: "channelId",
        align: "center",
        isHide: type !== constants.VOD_TYPE.TIMESHIFT,
      },
      {
        title: "Episode",
        dataIndex: "episode",
        key: "episode",
        align: "right",
        width: 100,
        isHide: type !== constants.VOD_TYPE.BO,
        render: (value, record) => {
          let isAddEpisode = true;
          if (
            record.episodeNumber &&
            record.totalNoOfEpisodes &&
            record.episodeNumber >= record.totalNoOfEpisodes
          ) {
            isAddEpisode = false;
          }

          return (
            <Tooltip content="Episode List" position={Position.RIGHT}>
              <ButtonAction
                // title=""
                intent={Intent.SUCCESS}
                title={
                  (record.episodeNumber || 0) +
                  "/" +
                  (record.totalNoOfEpisodes || 0)
                }
                onClick={() => {
                  this.router.push("/media/vod/series/episode/" + encodeURIComponent(record.id));
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: "Channel",
        dataIndex: "channelId",
        key: "channelId",
        isHide: true,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        align: "center",
        // width: 120,
        isHide: true,
        render: (value, record) => {
          return (
            <>
              {define.vodTypeMap[value]}
              {record.parentId !== "root" && (
                <span
                  style={{ display: "block" }}
                >{`(${record.parentId})`}</span>
              )}
              <ButtonAction
                small
                minimal
                icon="edit"
                onClick={() => {
                  handleOpenDialog({
                    dialogWidth: 480,
                    dialogTitle: `Change type VOD ${record.title}`,
                    dialogData: record,
                    dialogFieldForm: () => [
                      {
                        title: "Type",
                        dataIndex: "type",
                        type: constants.FORM_TYPE.SELECT,
                        dataMap: {
                          0: "Single",
                          2: "Episode",
                        },
                        isRequired: true,
                      },
                      {
                        title: "Parent ID",
                        dataIndex: "parentId",
                        isHide: true,
                      },
                      {
                        title: "Series",
                        dataIndex: "parent",
                        isHide: ({ data }) => {
                          return (
                            parseInt(data.type) !== constants.VOD_TYPE.EPISODE
                          );
                        },
                        isRequired: ({ data }) => {
                          return (
                            parseInt(data.type) === constants.VOD_TYPE.EPISODE
                          );
                        },
                        render: ({ data, handleSetValueForm }) => {
                          const parentName =
                            (data["parent"] && data["parent"].title) ||
                            (data.parentId !== "root" && data.parentId);

                          return (
                            <>
                              {parentName && (
                                <span style={{ marginRight: "5px" }}>
                                  {parentName}
                                </span>
                              )}
                              <ButtonChangeVOD
                                handleSetValueForm={handleSetValueForm}
                              />
                            </>
                          );
                        },
                      },
                    ],
                    dialogFormatDataSubmit: ({ dataSubmit }) => {
                      const { parent, ...data } = dataSubmit;

                      const dataExtra = {};

                      if (
                        parseInt(dataSubmit.type) === constants.VOD_TYPE.EPISODE
                      ) {
                        dataExtra.parentId = parent.id;
                        dataExtra.deviceType = parent.deviceType;
                        dataExtra.platform = parent.platform;
                        dataExtra.nodeRefs = parent.nodeRefs;
                        dataExtra.nation = parent.nation;
                        // dataExtra.keyword = parent.keyword;
                        dataExtra.directors = parent.directors;
                        dataExtra.actors = parent.actors;
                        dataExtra.description = parent.description;
                        dataExtra.synopsis = parent.synopsis;
                        dataExtra.searchVisible = parent.searchVisible;
                        dataExtra.studio = parent.studio;
                        dataExtra.products = parent.products;
                        dataExtra.categories = parent.categories;
                        // dataExtra.tag = parent.tag;
                        dataExtra.labels = parent.labels;
                      } else {
                        dataExtra.parentId = "root";
                      }

                      return {
                        ...data,
                        type: parseInt(dataSubmit.type),
                        ...dataExtra,
                      };
                    },
                    dialogOnOK: (data, callback) => {
                      ActionManager.init({
                        dataStorage: this.storage,
                        auth: this.auth,
                      });

                      ActionManager.updateMetadata(record, data, () => {
                        messageHandler("Successful implementation!");

                        callback();

                        refreshData();
                      });
                    },
                  });
                }}
                disabled={
                  !checkPermission({
                    permissions: this.permissions,
                    resource: constants.RESOURCE_NAME.METADATA,
                    path: config.path.metadataId,
                    method: constants.METHOD_TYPE.PUT,
                    dataStorage: this.storage,
                  })
                }
              />
            </>
          );
        },
      },
      // {
      //   title: 'Chọn nguồn',
      //   dataIndex: 'source',
      //   key: 'source',
      //   align: 'center',
      //   render: (value, record) => {
      //     if (!record.source) {
      //       return <ButtonAction
      //         title=""
      //         icon="document-open"
      //         intent={Intent.PRIMARY}
      //         onClick={() => {
      //           this.openDialogTranscodeList && this.openDialogTranscodeList(record, refreshData, handleCloseDialog)
      //         }}
      //       />
      //     } else {
      //       return ''
      //     }
      //   }
      // },

      {
        title: "Definition",
        dataIndex: "definition",
        key: "definition",
        align: "center",
        width: 110,
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Catalog",
        dataIndex: "nodeRefs",
        key: "nodeRefs",
        render: (value) => {
          const { nodeRefsMap } = dataMapState;
          let valueFormat = [];
          value &&
            value.map &&
            value.map((nodeRef) => {
              if (
                nodeRefsMap &&
                nodeRefsMap instanceof Object &&
                nodeRefsMap[nodeRef]
              ) {
                valueFormat.push(nodeRef);
              }
            });

          return (
            <TextBox.Tag
              isDataMap
              dataSource={valueFormat}
              keyMap="nodeRefsMap"
              dataMapState={dataMapState}
            />
          );
        },
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
      {
        title: "Screen Type",
        dataIndex: "screenType",
        render: value => define.screenTypeMap[value]
      },
      {
        title: "Instructor",
        dataIndex: "instructors",
        render: (value = []) => {
          if (Array.isArray(value)) {
            return value.map(el => {
              return <div>{instructorMap[el]}</div>
            })
          } return value;
        }
      },
      {
        title: "Require Login",
        dataIndex: "requireLogin",
        key: "requireLogin",
        align: "center",
        width: 150,
        // render: (value) => {
        //   return value ? "True" : "False";
        // },
        isHide: type === constants.VOD_TYPE.EPISODE,
        render: (value, record) => {
          const userInfo = this.storage?.userInfo;
          const vodState = record.state;

          const { scope } = userInfo;
          let fetchDataConfig = {}
          if (scope === "btv") {
            fetchDataConfig = {
              messageText: "Submit successfully. Open Review Content to see details!"
            }
          }
           let isDisabled = false;
          if (parseInt(vodState) === constants.STATE_VOD_TYPE.DRAFT) {
            isDisabled = true;
        }
          return (
            <StatusCheckbox
            disabled={isDisabled}
              record={record}
              name="requireLogin"
              handleAction={(checked, handleCallback) => {
                handleUpdateStatus({
                  url: `${config.apiEndpoint.METADATA}/${record.id}`,
                  handleCallback,
                  requireLogin: checked ? 1 : 0,
                  fetchDataConfig
                });
              }}
              authProps={{
                permissions: this.permissions,
                resource: constants.RESOURCE_NAME.METADATA,
              }}
            />
          );
        },
      },
      {
        title: "Pin up",
        dataIndex: "pinUp",
        key: "pinUp",
        align: "center",
        width: 150,
        // render: (value) => {
        //   return value ? "True" : "False";
        // },
        isHide: type === constants.VOD_TYPE.EPISODE,
        render: (value, record) => {
          const userInfo = this.storage?.userInfo;
          const vodState = record.state;

          const { scope } = userInfo;
          let fetchDataConfig = {}
          if (scope === "btv") {
            fetchDataConfig = {
              messageText: "Submit successfully. Open Review Content to see details!"
            }
          }
          let isDisabled = false;
          if (parseInt(vodState) === constants.STATE_VOD_TYPE.DRAFT) {
            isDisabled = true;
        }
          return (
            <StatusCheckbox
              disabled={isDisabled}
              record={record}
              name="pinUp"
              handleAction={(checked, handleCallback) => {
                handleUpdateStatus({
                  url: `${config.apiEndpoint.METADATA}/${record.id}`,
                  handleCallback,
                  pinUp: checked ? 1 : 0,
                  fetchDataConfig
                });
              }}
              authProps={{
                permissions: this.permissions,
                resource: constants.RESOURCE_NAME.METADATA,
              }}
            />
          );
        },
      },
      {
        title: "Review",
        dataIndex: "requireAction",
        key: "requireAction",
        align: "center",
        width: 130,
        isHide:
          scope !== constants.SCOPE_TYPE.LEADER &&
          scope !== constants.SCOPE_TYPE.ADMIN,
        render: (value, record) => {
          if (value) {
            return (
              <Button
                text="Review"
                onClick={() => {
                  fetchData({
                    fetchApi: request({
                      url: `${config.apiEndpoint.APPROVE}/metadata/${record.id}`,
                      token: this.token,
                    }),
                    hideMessage: true,
                    handleSuccess: (data) => {
                      if (data && data.length > 0) {
                        this.openDialogApprove({
                          // dataDetail: record,
                          dataRecord: record,
                          dataCompare: data[0],
                          title: record.title,
                          type: record.type,
                        });
                      } else {
                        messageHandler.success("Content is moderated!");
                      }
                    },
                  });
                }}
              />
            );
          }
        },
      },
      {
        title: "Published By",
        dataIndex: "publishedBy",
        key: "publishedBy",
        width: 150,
        render: (value) => {
          return getUser({ uid: value, userMap });
        },
      },
      {
        title: "Published Date",
        dataIndex: "publishedAt",
        key: "publishedAt",
        width: 150,
        render: (value) => {
          return (
            value && (
              <>
                <div>{formatDateTime(value, "DD-MM-YYYY")}</div>
                <div>{formatDateTime(value, "HH:mm:ss")}</div>
              </>
            )
          );
        },
      },
      {
        title: "Availability Date",
        dataIndex: "availabilityDate",
        key: "availabilityDate",
        width: 150,
        render: (value) => {
          return (
            value && (
              <>
                <div>{moment.unix(value).format("DD-MM-YYYY")}</div>
                <div>{moment.unix(value).format("HH:mm:ss")}</div>
              </>
            )
          );
        },
      },
      {
        title: "Expire Date",
        dataIndex: "expireDate",
        key: "expireDate",
        width: 150,
        render: (value, record) => {
          return (
            value && (
              <DateBox
                scope={scope}
                value={value}
                record={record}
                apiConfig={{
                  url: config.apiEndpoint.METADATA,
                }}
                refreshData={refreshData}
              />
            )
          );
        },
      },
      {
        title: "Take Down By",
        dataIndex: "takedownBy",
        key: "takedownBy",
        render: (value) => {
          return getUser({ uid: value, userMap });
        },
      },
      {
        title: "Take Down At",
        dataIndex: "takedownAt",
        key: "takedownAt",
        // width: 110,
        render: (value) => {
          return (
            value && (
              <>
                <div>{formatDateTime(value, "DD-MM-YYYY")}</div>
                <div>{formatDateTime(value, "HH:mm:ss")}</div>
              </>
            )
          );
        },
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
        // width: 110
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        // width: 110,
        render: (value) => {
          return (
            value && (
              <>
                <div>{formatDateTime(value, "DD-MM-YYYY")}</div>
                <div>{formatDateTime(value, "HH:mm:ss")}</div>
              </>
            )
          );
        },
      },
      {
        title: "Updated By",
        dataIndex: "updatedBy",
        key: "updatedBy",
        render: (value) => {
          return getUser({ uid: value, userMap });
        },
      },
      {
        title: "Updated At",
        dataIndex: "lastUpdatedAt",
        key: "lastUpdatedAt",
        // width: 110,
        render: (value) => {
          return (
            value && (
              <>
                <div>{formatDateTime(value, "DD-MM-YYYY")}</div>
                <div>{formatDateTime(value, "HH:mm:ss")}</div>
              </>
            )
          );
        },
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        render: (value) => {
          return getUser({ uid: value, userMap });
        },
      },
      {
        title: "Device Type",
        dataIndex: "deviceType",
        key: "deviceType",
        // width: 120,
        isHide: type === constants.VOD_TYPE.EPISODE,
        render: (value, record) => {
          return (
            <ActionBox.Checkbox
              isUpdateRecord
              value={value && value.map((key) => key.toString())}
              dataMap={define.deviceMap}
              config={{
                refreshData,
                api: {
                  url: `${config.apiEndpoint.METADATA}/${record.id}`,
                },
                changeValue: (value) => {
                  return value.map((key) => parseInt(key));
                },
                recordName: "deviceType",
                confirmProps: (value) => {
                  return {
                    title: "Update",
                    content: `Do you want to to make this change ${record.title}`,
                  };
                },
              }}
            />
          );
        },
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        // width: 120,
        isHide: type === constants.VOD_TYPE.EPISODE,
        render: (value, record) => {
          return (
            <ActionBox.Checkbox
              isUpdateRecord
              value={value && value.map((key) => key.toString())}
              dataMap={define.platformMap}
              config={{
                refreshData,
                api: {
                  url: `${config.apiEndpoint.METADATA}/${record.id}`,
                },
                changeValue: (value) => {
                  return value.map((key) => parseInt(key));
                },
                recordName: "platform",
                confirmProps: (value) => {
                  return {
                    title: "Update",
                    content: `Do you want to to make this change ${record.title}`,
                  };
                },
              }}
            />
          );
        },
      },
      {
        title: "Genre",
        dataIndex: "genre",
        key: "genre",
        isHide: type === constants.VOD_TYPE.EPISODE,
        render: (value) => {
          const { genreMap } = dataMapState;
          let valueFormat = [];
          value &&
            value.map &&
            value.map((genreId) => {
              if (genreMap && genreMap instanceof Object && genreMap[genreId]) {
                valueFormat.push(genreId);
              }
            });
          return (
            <TextBox.Tag
              isDataMap
              dataSource={valueFormat}
              keyMap="genreMap"
              dataMapState={dataMapState}
            />
          );
        },
      },
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        isHide: type === constants.VOD_TYPE.EPISODE,
      },
    ];
  }

  // fieldForm
  getFieldFormVod(props = {}) {
    const {
      parentKey,
      type,
      dataMapState,
      handleCloseDialog,
      handleOpenDialog,
      userInfo = {},
      isDuplicate,
      dataParent = {},
      dataDetail = {},
      screenType,
      submitType
    } = props;

    const { scope } = userInfo;

    this.initDialog({ handleOpenDialog, handleCloseDialog });

    const { params = {} } = this.router || {};

    const { recordId, vodId } = params;

    let fieldExtra;

    switch (type) {
      case constants.VOD_TYPE.TIMESHIFT:
        fieldExtra = [
          {
            title: "Timeshift",
            dataIndex: "timeshift",
            isHide: true,
          },
          {
            title: "Source",
            dataIndex: "source",
            isDisabled: true,
            isRegister: true,
          },
          {
            title: "Type",
            dataIndex: "type",
            type: constants.FORM_TYPE.SELECT,
            dataMap: {
              0: "Single",
              2: "Episode",
            },
            isRequired: true,
          },
          {
            title: "Parent ID",
            dataIndex: "parentId",
            isHide: true,
          },
          {
            title: "Series",
            dataIndex: "parent",
            isHide: ({ data }) => {
              return parseInt(data.type) !== 2;
            },
            isRequired: ({ data }) => {
              return parseInt(data.type) === 2;
            },
            render: ({ data, handleSetValueForm }) => {
              const parentName =
                (data["parent"] && data["parent"].title) ||
                (data.parentId !== "root" && data.parentId);

              return (
                <>
                  {parentName && (
                    <span style={{ marginRight: "5px" }}>{parentName}</span>
                  )}
                  <Button
                    text="Chọn"
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      this.openDialogVodSeries(null, (data) => {
                        handleSetValueForm("parent", data);
                      });
                    }}
                  />
                </>
              );
            },
          },
        ];
        break;

      case constants.VOD_TYPE.LE:
        fieldExtra = [
          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.VOD_TYPE.LE,
            isHide: true,
          },
          // {
          //   title: 'Clip ngắn',
          //   dataIndex: 'shortClip',
          //   type: constants.FORM_TYPE.CHECKBOX,
          //   // defaultValue: 0,
          //   changeValue: (value) => formatBooleanToNumber(value)
          // },
          {
            title: "Source",
            dataIndex: "source",
            isRequired: true,
            // isDisabled: ({ y }) => {
            //   return (
            //     submitType === constants.SUBMIT_TYPE.UPDATE && dataDetail.source
            //   );
            // },
            // isRegister: true,
            // isHide: () => {
            //   return recordId === constants.SUBMIT_TYPE.CREATE
            // },
            isCol: true,
            colSpan: 10,
          },
          // {
          //   title: "Ads params",
          //   dataIndex: "adsParams"
          // },
        ];
        break;

      case constants.VOD_TYPE.BO:
        fieldExtra = [
          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.VOD_TYPE.BO,
            isHide: true,
          },
          {
            title: "Total Episode",
            dataIndex: "totalNoOfEpisodes",
            type: constants.FORM_TYPE.NUMBER,
            min: 1,
            isRequired: true,
          },
        ];
        break;

      case constants.VOD_TYPE.EPISODE:
        fieldExtra = [
          {
            title: "ParentId",
            dataIndex: "parentId",
            defaultValue: vodId,
            isHide: true,
            isDisabled: ({ submitType }) => {
              return submitType === constants.SUBMIT_TYPE.UPDATE;
            },
          },
          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.VOD_TYPE.EPISODE,
            isHide: true,
          },
          {
            title: "Source",
            dataIndex: "source",
            isCol: true,
            colSpan: 10,
            isRequired: true,
            // isDisabled: ({ submitType }) => {
            //   return (
            //     submitType === constants.SUBMIT_TYPE.UPDATE && dataDetail.source
            //   );
            // },
          },
          // {
          //   title: "Ads params",
          //   dataIndex: "adsParams"
          // },

        ];
        break;

      default:
        fieldExtra = [];
        break;
    }

    if (screenType === constants.SCREEN_TYPE.MAGAZINE) {
      fieldExtra = [
        {
          title: "Type",
          dataIndex: "type",
          defaultValue: constants.VOD_TYPE.LE,
          isHide: true,
        },
        {
          title: "Source",
          dataIndex: "source",
          isCol: true,
          colSpan: 10,
          isRequired: true,
          // isDisabled: ({ submitType }) => {
          //   return (
          //     submitType === constants.SUBMIT_TYPE.UPDATE && dataDetail.source
          //   );
          // },
        },
      ]
    }

    let fieldPackages = {
      title: "Package",
      dataIndex: "products",
      type: constants.FORM_TYPE.SELECT,
      dataMap: dataMapState?.planMap,
      isNumber: true,
      placeholder: "Please choose package",
      isHide: type === constants.VOD_TYPE.EPISODE
    }

    if (submitType === constants.SUBMIT_TYPE.CREATE && type !== constants.VOD_TYPE.EPISODE) {
      fieldPackages = {
        ...fieldPackages,
        defaultValue: 1,
      }
    }

    const fieldForm = [
      {
        title: "Episode Number",
        dataIndex: "episodeNumber",
        type: constants.FORM_TYPE.NUMBER,
        isRequired: () => {
          return type === constants.VOD_TYPE.EPISODE;
        },
        onChange: ({ data, handleSetValueForm, submitType }) => {
          if (
            type === constants.VOD_TYPE.EPISODE &&
            submitType === constants.SUBMIT_TYPE.CREATE
          ) {
            handleSetValueForm(
              "title",
              data.titleParent + " - Episode " + data.episodeNumber
            );

            handleSetValueForm(
              "id",
              data.parentId + "episode" + data.episodeNumber
            );
          }
        },
        isHide: () => {
          return type !== constants.VOD_TYPE.EPISODE;
        },
        // isDisabled: true,
        changeValue: (value) => {
          return value ? parseInt(value) : 0;
        },
      },
      {
        title: "Title Parent",
        dataIndex: "titleParent",
        key: "titleParent",
        isHide: true,
      },
      // {
      //   title: 'Ẩn phim gốc',
      //   dataIndex: 'hideVodOriginal',
      //   type: constants.FORM_TYPE.CHECKBOX,
      //   isHide: !isDuplicate
      // },
      {
        title: "Type",
        dataIndex: "type",
        type: constants.FORM_TYPE.SELECT,
        dataMap: {
          0: "Single",
          2: "Episode",
        },
        isRequired: isDuplicate,
        isHide: !isDuplicate,
      },
      {
        title: "Parent ID",
        dataIndex: "parentId",
        isHide: true,
      },
      {
        title: "Series",
        dataIndex: "parent",
        isHide: ({ data }) => {
          return (
            !isDuplicate || parseInt(data.type) !== constants.VOD_TYPE.EPISODE
          );
        },
        isRequired: ({ data }) => {
          return (
            isDuplicate && parseInt(data.type) === constants.VOD_TYPE.EPISODE
          );
        },
        defaultValue: ({ data }) => {
          if (dataParent.title) {
            return {
              title: dataParent.title,
              id: data.parentId,
            };
          }
        },
        render: ({ data, handleSetValueForm }) => {
          const { parent = {} } = data;

          const parentName =
            parent.title || (data.parentId !== "root" && data.parentId);

          return (
            <>
              {parentName && (
                <span style={{ marginRight: "5px" }}>{parentName}</span>
              )}
              <ButtonChangeVOD
                handleSetValueForm={handleSetValueForm}
                onChange={(parent) => {
                  const episodeNumber = parent.episodeNumber + 1;

                  handleSetValueForm("episodeNumber", episodeNumber);

                  if (parent.title) {
                    handleSetValueForm(
                      "title",
                      parent.title + " - Episode " + episodeNumber
                    );
                  }
                }}
              />
            </>
          );
        },
      },
      {
        title: "Episode Number",
        dataIndex: "episodeNumber",
        type: constants.FORM_TYPE.NUMBER,
        isHide: ({ data }) => {
          return (
            !isDuplicate || parseInt(data.type) !== constants.VOD_TYPE.EPISODE
          );
        },
        isRequired: ({ data }) => {
          return (
            isDuplicate && parseInt(data.type) === constants.VOD_TYPE.EPISODE
          );
        },
        onChange: ({ data, handleSetValueForm }) => {
          if (parseInt(data.type) === constants.VOD_TYPE.EPISODE) {
            const { parent = {} } = data;

            if (isDuplicate && parent.title) {
              handleSetValueForm(
                "title",
                parent.title + " - Episode " + data.episodeNumber
              );
            }
          }
        },
      },
      {
        title:
          type === constants.VOD_TYPE.EPISODE ? "Episode Name" : type === constants.VOD_TYPE.LE ? "Movie Name" : screenType === constants.SCREEN_TYPE.MAGAZINE ? "Title" : "Series Name",
        dataIndex: "title",
        isRequired: true,
        hasChange: true,
        onChange: ({ value, data, handleSetValueForm }) => {
          if (recordId === constants.SUBMIT_TYPE.CREATE && value) {
            handleSetValueForm(
              "id",
              changeToSlug(value, {
                notSpace: true,
              })
            );
          }
        },
      },
      // {
      //   title: "ID",
      //   dataIndex: "id",
      //   isHide: true,
      //   // isDisabled: true,
      //   isDisabled: true,
      //   isRequired: true,
      //   // isRequired: ({ submitType }) => {
      //   //   return submitType === constants.SUBMIT_TYPE.CREATE
      //   // }
      // },
      {
        title: "SubTitle",
        dataIndex: "synopsis",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
      },
      // {
      //   title: "From",
      //   dataIndex: "from",
      //   type: constants.FORM_TYPE.SELECT,
      //   map: define.vodFromMap,
      //   isHide: type === constants.VOD_TYPE.BO,
      //   isDisabled: true,
      // },
      {
        title: "Description",
        dataIndex: "description",
        type: constants.FORM_TYPE.TEXT_AREA,
      },
      {
        title: "Transconde Info",
        dataIndex: "transcodeInfo",
        isHide: true,
      },
      ...fieldExtra,
      // {
      //   title: "Catalog",
      //   dataIndex: "targetId",
      //   // isRequired: true,
      //   keyMap: 'targetMap',
      //   filterable: true,
      //   config: {
      //     api: {
      //       url: `${config.apiEndpoint.METADATA}`,
      //       data: {
      //         type: "0"
      //       }
      //     },
      //     valueName: 'title'
      //   },
      //   fieldProps: {
      //     isAutoSearchItem: false
      //   }
      // },
      {
        ...fieldPackages
        // title: "Package",
        // dataIndex: "products",
        // type: constants.FORM_TYPE.SELECT,
        // dataMap: dataMapState?.planMap,
        // defaultValue: submitType === constants.SUBMIT_TYPE.UPDATE ? null  : 1,
        // isNumber: true,
        // placeholder: "Please choose package",
      },
      {
        title: "Landscape (568x318)",
        type: constants.FORM_TYPE.UPLOAD_IMAGE,
        dataIndex: "landscape",
        isRequired: true,
        api: config.apiUploadOther,
        typeUpload: constants.IMAGE_TYPE.LANDSCAPE,
        uploadName: constants.UPLOAD_NAME.FILE_UPLOAD,
        changeValue: (value) => {
          // console.log('value', value);

          return getAssetFileName(value, "name");
        },
        formatValue: (value) => {
          return getAssetImageUrl(value);
        },
        getDataFile: (value) => {
          return value.name;
        },
        // formatFileName: true,
        size: "568x318",
        // minWidth: 568,
        // minHeight: 318,
        isSortable: false,
        isCropping: true,
        isCol: true,
        colSpan: 6,
        groupName: "Image",
      },
      {
        title: screenType === constants.SCREEN_TYPE.MAGAZINE ? "Portrait (640x845)" : "Portrait (320x448)",
        type: constants.FORM_TYPE.UPLOAD_IMAGE,
        dataIndex: "portrait",
        isRequired: true,
        api: config.apiUploadOther,
        typeUpload: constants.IMAGE_TYPE.LANDSCAPE,
        uploadName: constants.UPLOAD_NAME.FILE_UPLOAD,
        changeValue: (value) => {
          // console.log('value', value);

          return getAssetFileName(value, "name");
        },
        formatValue: (value) => {
          return getAssetImageUrl(value);
        },
        getDataFile: (value) => {
          return value.name;
        },
        // formatFileName: true,
        size: screenType === constants.SCREEN_TYPE.MAGAZINE ? "640x845" : "320x448",
        // minWidth: 320,
        // minHeight: 448,
        isSortable: false,
        isCropping: true,
        isCol: true,
        colSpan: 6,
        groupName: "Image",
      },
      {
        title: "Catalog",
        dataIndex: "nodeRefs",
        isRequired: ({ data }) => {
          return parseInt(type) !== constants.VOD_TYPE.EPISODE;
        },
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
        render: ({ handleSetValueForm, handleResetForm, data, isPreview }) => {
          return (
            <TreeCustom
              isMultiple
              // isTickAll={true}
              isPreview={isPreview}
              data={dataMapState.nodeMapFull || []}
              selectedKey={data.nodeRefs}
              isExpanded={true}
              onNodeClick={(selectedValue) => {
                handleSetValueForm &&
                  handleSetValueForm("nodeRefs", selectedValue);
              }}
            />
          );
        },
        // register: ({ dataMapState, data }) => {
        //   if (parseInt(type) !== constants.VOD_TYPE.EPISODE) {
        //     return {
        //       validate: (value) => {
        //         return checkValidateIdByMap(value, dataMapState.nodeMap)
        //           ? true
        //           : "Please select catalog";
        //       },
        //     };
        //   }
        // },
      },
      // {
      //   title: "Search Visible",
      //   isHide: ({ data }) => {
      //     return (
      //       vodId ||
      //       (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
      //     );
      //   },
      //   dataIndex: "searchVisible",
      //   type: constants.FORM_TYPE.SWITCH,
      //   groupName: "Extra",
      // },
      // {
      //   title: "Keyword",
      //   isHide: ({ data }) => {
      //     return (
      //       vodId ||
      //       (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
      //     );
      //   },
      //   dataIndex: "keyword",
      //   type: constants.FORM_TYPE.TAG,
      //   groupName: "Extra",
      // },
      // {
      //   title: "Tag",
      //   isHide: ({ data }) => {
      //     return (
      //       vodId ||
      //       (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
      //     );
      //   },
      //   dataIndex: "tag",
      //   type: constants.FORM_TYPE.TAG,
      //   groupName: "Extra",
      // },
      {
        title: "Screen Type",
        dataIndex: "screenType",
        type: constants.FORM_TYPE.SELECT,
        defaultValue: screenType === constants.SCREEN_TYPE.MAGAZINE ? 'magazine' : 'vod',
        isRequired: true,
        dataMap: () => {
          let temp = {
            ...define.screenTypeMap
          }
          if (screenType === constants.SCREEN_TYPE.MAGAZINE) {
            delete temp.vod;
          } else {
            delete temp.magazine;
          }

          delete temp.header;
          return temp;
        }
      },
      {
        title: "Instructors",
        dataIndex: "instructors",
        type: constants.FORM_TYPE.SELECT_MULTI,
        // dataMap: dataMapState['instructorMap'],
        isHide: screenType === constants.SCREEN_TYPE.MAGAZINE,
        isFetchDataMap: true,
        keyMap: "instructorMap",
        filterable: true,
        isSearchData: true,
        config: {
          api: {
            url: config.apiEndpoint.INSTRUCTOR,
            data: {
              limit: 1000
            },
          },
          queryName: 'name',
        },
      },
      {
        title: "Produce Nation",
        dataIndex: "nation",
        type: constants.FORM_TYPE.SELECT_MULTI,
        dataMap: dataMapState.nationMap || {},
        isHide: ({ data }) => {
          return parseInt(type) === constants.VOD_TYPE.EPISODE;
        },
        // isAddRecord: true,
        // keyMap: "nationLiteMap",
        // isFetchDataMap: true,
        // isSearchData: true,
        // isHide: ({ data }) => {
        //   return parseInt(type) === constants.VOD_TYPE.EPISODE;
        // },
        // filterable: true,
        // config: {
        //   api: (params = {}) => {
        //     const { dataDetail = {} } = params;
        //
        //     return {
        //       url: config.apiEndpoint.CATEGORY,
        //       data: {
        //         type: constants.CATEGORY_TYPE.NATION,
        //         activated: 1,
        //         limit: 200
        //         // ids: getIdsByDetail(dataDetail, "nation"),
        //       },
        //     };
        //   },
        //   queryName: "name",
        //   searchQuery: {
        //     type: constants.CATEGORY_TYPE.NATION,
        //   },
        //   checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
        //     ActionManager.checkExist(
        //       data,
        //       config.apiEndpoint.CATEGORY,
        //       callbackSubmit
        //     );
        //   },
        // },
        groupName: "Extra",
      },
      {
        title: "Block nation",
        dataIndex: "blockNation",
        type: constants.FORM_TYPE.SELECT_MULTI,
        dataMap: dataMapState.nationMap || {},
        isHide: ({ data }) => {
          return parseInt(type) === constants.VOD_TYPE.EPISODE;
        },
        // isAddRecord: true,
        // keyMap: "blockNationLiteMap",
        // isFetchDataMap: true,
        // isSearchData: true,
        // isHide: ({ data }) => {
        //   return parseInt(type) === constants.VOD_TYPE.EPISODE;
        // },
        // filterable: true,
        // config: {
        //   api: (params = {}) => {
        //     const { dataDetail = {} } = params;
        //
        //     return {
        //       url: config.apiEndpoint.CATEGORY,
        //       data: {
        //         type: constants.CATEGORY_TYPE.NATION,
        //         activated: 1,
        //         limit: 200
        //         // ids: getIdsByDetail(dataDetail, "blockNation"),
        //       },
        //     };
        //   },
        //   keyName: 'code',
        //   queryName: "name",
        //   searchQuery: {
        //     type: constants.CATEGORY_TYPE.NATION,
        //   },
        //   checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
        //     ActionManager.checkExist(
        //       data,
        //       config.apiEndpoint.CATEGORY,
        //       callbackSubmit
        //     );
        //   },
        // },
        groupName: "Extra",
      },
      {
        title: "Allow nation",
        dataIndex: "allowNation",
        type: constants.FORM_TYPE.SELECT_MULTI,
        dataMap: dataMapState.nationMap || {},
        helperText: (
          <div>
            (!)
            If not select, the content will show at any country.
          </div>
        ),
        isHide: ({ data }) => {
          return parseInt(type) === constants.VOD_TYPE.EPISODE;
        },
        // dataMap: {},
        // isAddRecord: true,
        // keyMap: "allowNationLiteMap",
        // isFetchDataMap: true,
        // isSearchData: true,
        // isHide: ({ data }) => {
        //   return parseInt(type) === constants.VOD_TYPE.EPISODE;
        // },
        // filterable: true,
        // config: {
        //   api: (params = {}) => {
        //     const { dataDetail = {} } = params;
        //
        //     return {
        //       url: config.apiEndpoint.CATEGORY,
        //       data: {
        //         type: constants.CATEGORY_TYPE.NATION,
        //         activated: 1,
        //         limit: 200
        //         // ids: getIdsByDetail(dataDetail, "allowNation"),
        //       },
        //     };
        //   },
        //   keyName: 'code',
        //   queryName: "name",
        //   searchQuery: {
        //     type: constants.CATEGORY_TYPE.NATION,
        //   },
        //   checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
        //     ActionManager.checkExist(
        //       data,
        //       config.apiEndpoint.CATEGORY,
        //       callbackSubmit
        //     );
        //   },
        // },
        groupName: "Extra",
      },
      {
        title: "Genre",
        dataIndex: "categories",
        type: constants.FORM_TYPE.SELECT_MULTI,
        keyMap: "genreMap",
        isFetchDataMap: true,
        isSearchData: true,
        filterable: true,
        config: {
          api: {
            url: config.apiEndpoint.CATEGORY,
            data: {
              type: constants.CATEGORY_TYPE.GENRE,
              activated: 1,
              limit: 1000
            },
          },
          queryName: "name",
          searchQuery: {
            type: constants.CATEGORY_TYPE.LABEL,
          },
          checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
            ActionManager.checkExist(
              data,
              config.apiEndpoint.CATEGORY,
              callbackSubmit
            );
          },
        },
        isAddRecord: true,
        fieldForm: [
          {
            title: "Name",
            dataIndex: "name",
            type: constants.FORM_TYPE.STRING,
            isRequired: true,
            onChange: ({ value, handleSetValueForm }) => {
              handleSetValueForm(
                "id",
                changeToSlug(value, {
                  notSpace: true,
                })
              );
            },
          },
          {
            title: "ID",
            dataIndex: "id",
            type: constants.FORM_TYPE.STRING,
            isDisabled: true,
          },
          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.CATEGORY_TYPE.GENRE,
            isHide: true,
            isRequired: true,
          },
          {
            title: "Status",
            dataIndex: "activated",
            type: constants.FORM_TYPE.CHECKBOX,
            defaultValue: 1,
            changeValue: (value) => formatBooleanToNumber(value),
          },
        ],
        groupName: "Extra",
      },
      {
        title: "Keywords",
        // isRequired: ({ data }) => {
        //   return parseInt(type) !== constants.VOD_TYPE.EPISODE
        // },
        dataIndex: "keywords",
        type: constants.FORM_TYPE.SELECT_MULTI,
        keyMap: "labelMap",
        isFetchDataMap: true,
        filterable: true,
        isSearchData: true,
        config: {
          api: (params = {}) => {
            const { dataDetail } = params;
            return {
              url: config.apiEndpoint.CATEGORY,
              data: {
                type: constants.CATEGORY_TYPE.LABEL,
                activated: 1,
                ids: dataDetail?.keywords,
                limit: 1000,
              },
            }
          },
          queryName: "name",
          searchQuery: {
            type: constants.CATEGORY_TYPE.LABEL,
          },
          checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
            ActionManager.checkExist(
              data,
              config.apiEndpoint.CATEGORY,
              callbackSubmit
            );
          },
        },
        isAddRecord: true,
        fieldForm: [
          {
            title: "Name",
            dataIndex: "name",
            type: constants.FORM_TYPE.STRING,
            isRequired: true,
            onChange: ({ value, handleSetValueForm }) => {
              handleSetValueForm(
                "id",
                changeToSlug(value, {
                  notSpace: true,
                })
              );
            },
          },
          {
            title: "ID",
            dataIndex: "id",
            type: constants.FORM_TYPE.STRING,
            isDisabled: true,
          },
          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.CATEGORY_TYPE.LABEL,
            isHide: true,
            isRequired: true,
          },
          {
            title: "Status",
            dataIndex: "activated",
            type: constants.FORM_TYPE.CHECKBOX,
            defaultValue: 1,
            changeValue: (value) => formatBooleanToNumber(value),
          },
        ],
        groupName: "Extra",
      },
      {
        title: "Require Login",
        dataIndex: "requireLogin",
        type: constants.FORM_TYPE.CHECKBOX,
        changeValue: (value) => formatBooleanToNumber(value),
        colSpan: 4,
        isHide: () => {
          return parseInt(type) === constants.VOD_TYPE.EPISODE;
        },
      },
      {
        title: "Pin Up",
        dataIndex: "pinUp",
        type: constants.FORM_TYPE.CHECKBOX,
        changeValue: (value) => formatBooleanToNumber(value),
        colSpan: 4,
        isHide: () => {
          return parseInt(type) === constants.VOD_TYPE.EPISODE;
        },
      },
      {
        title: "Actor",
        dataIndex: "actors",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
        type: constants.FORM_TYPE.SELECT_MULTI,
        keyMap: "actorMap",
        isFetchDataMap: true,
        isSearchData: true,
        filterable: true,
        config: {
          api: (params = {}) => {
            const { dataDetail } = params;

            return {
              url: config.apiEndpoint.CATEGORY,
              data: {
                type: constants.CATEGORY_TYPE.PEOPLE,
                activated: 1,
                ids: getIdsByDetail(dataDetail, "actors"),
                limit: 500
              },
            };
          },
          queryName: "name",
          searchQuery: {
            type: constants.CATEGORY_TYPE.PEOPLE,
          },
          checkDataBeforeSubmit: ({ data, callbackSubmit }) => {
            ActionManager.checkExist(
              data,
              config.apiEndpoint.CATEGORY,
              callbackSubmit
            );
          },
        },
        isAddRecord: true,
        fieldForm: this.getFieldFormPeople,
        renderList: (value, dataMapState) => {
          return <PeopleBox value={value} dataList={dataMapState} />;
        },
        groupName: "Extra",
      },
      {
        title: "Director",
        dataIndex: "directors",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
            || screenType === constants.SCREEN_TYPE.MAGAZINE
          );
        },
        type: constants.FORM_TYPE.SELECT_MULTI,
        keyMap: "directorMap",
        isFetchDataMap: true,
        isSearchData: true,
        filterable: true,
        config: {
          api: (params = {}) => {
            const { dataDetail } = params;

            return {
              url: config.apiEndpoint.CATEGORY,
              data: {
                type: constants.CATEGORY_TYPE.PEOPLE,
                activated: 1,
                ids: getIdsByDetail(dataDetail, "directors"),
                limit: 500
              },
            };
          },
          queryName: "name",
          searchQuery: {
            type: constants.CATEGORY_TYPE.PEOPLE,
          },
        },
        isAddRecord: true,
        fieldForm: this.getFieldFormPeople,
        renderList: (value, dataMapState) => {
          return <PeopleBox value={value} dataList={dataMapState} />;
        },
        groupName: "Extra",
      },
      {
        title: "Language",
        dataIndex: "languages",
        // isRequired: true,
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
        type: constants.FORM_TYPE.SELECT_MULTI,
        keyMap: "languageMap",
        isFetchDataMap: true,
        isSearchData: true,
        filterable: true,
        config: {
          api: (params = {}) => {
            const { dataDetail } = params;

            return {
              url: config.apiEndpoint.CATEGORY,
              data: {
                type: constants.CATEGORY_TYPE.LANGUAGE,
                activated: 1,
                ids: getIdsByDetail(dataDetail, "languages"),
                limit: 500
              },
            };
          },
          queryName: "name",
          searchQuery: {
            type: constants.CATEGORY_TYPE.LANGUAGE,
          },
        },
        isAddRecord: true,
        fieldForm: [
          {
            title: "Name",
            dataIndex: "name",
            type: constants.FORM_TYPE.STRING,
            isRequired: true,
            onChange: ({ value, handleSetValueForm }) => {
              handleSetValueForm(
                "id",
                changeToSlug(value, {
                  notSpace: true,
                })
              );

              handleSetValueForm(
                "code",
                changeToSlug(value, {
                  notSpace: true,
                })
              );
            },
          },
          {
            title: "ID",
            dataIndex: "id",
            type: constants.FORM_TYPE.STRING,
            isDisabled: true,
          },
          // {
          //   title: "Code",
          //   dataIndex: "code",
          //   type: constants.FORM_TYPE.STRING,
          //   isRequired: true,
          //   onChange: ({ value, handleSetValueForm }) => {
          //     handleSetValueForm(
          //       "id",
          //       changeToSlug(value, {
          //         notSpace: true,
          //       })
          //     );
          //   }
          // },

          {
            title: "Type",
            dataIndex: "type",
            defaultValue: constants.CATEGORY_TYPE.LANGUAGE,
            isHide: true,
            isRequired: true,
          },
          {
            title: "Image (91x91)",
            dataIndex: "image",
            acceptFile: "image/*",
            type: constants.FORM_TYPE.UPLOAD,
            api: config.apiUploadOther,
            typeUpload: constants.IMAGE_TYPE.LANDSCAPE,
            uploadName: constants.UPLOAD_NAME.FILE_UPLOAD,
            changeValue: (value) => {
              return getAssetFileName(value, "name");
            },
            formatValue: (value) => {
              return getAssetImageUrl(value);
            },
            getDataFile: (value) => {
              return value.name;
            },
            size: "91x91"
          },
          {
            title: "Status",
            dataIndex: "activated",
            type: constants.FORM_TYPE.CHECKBOX,
            defaultValue: 1,
            changeValue: (value) => formatBooleanToNumber(value),
          }
        ],
        groupName: "Extra",
      },
      {
        title: "Definition",
        dataIndex: "definition",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE) || screenType === constants.SCREEN_TYPE.MAGAZINE
          );
        },
        // isRequired: type !== constants.VOD_TYPE.EPISODE,
        type: constants.FORM_TYPE.SELECT,
        isCol: true,
        colSpan: 4,
        dataMap: define.vodDefinitionMap,
      },
      {
        title: "Rating",
        // isRequired: type !== constants.VOD_TYPE.EPISODE,
        defaultValue: 0,
        dataIndex: "rating",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
        type: constants.FORM_TYPE.SELECT,
        isCol: true,
        colSpan: 4,
        placeholder: "Please choose rating",
        isNumber: true,
        dataMap: {
          0: "Unrate",
          1: "G",
          2: "PG",
          3: "R-13",
          4: "R-16",
          5: "R-18",
          6: "SPG"
        },
      },
      {
        title: "Duration (minute)",
        // isRequired: type !== constants.VOD_TYPE.EPISODE,
        isHide: type === constants.VOD_TYPE.EPISODE || screenType === constants.SCREEN_TYPE.MAGAZINE,
        dataIndex: "duration",
        type: constants.FORM_TYPE.NUMBER,
        min: 1,
        isCol: true,
        colSpan: 4,
      },
      {
        title: "Availability Date",
        dataIndex: "availabilityDate",
        type: constants.FORM_TYPE.DATE_TIME,
        // isRequired: true,
        changeValue: (value) => {
          return moment(value).unix();
        },
        formatValue: (value) => {
          return value === 0 ? moment().toDate() : moment.unix(value).toDate();
        },
        minDate: (data) => {
          return (
            type === constants.VOD_TYPE.EPISODE &&
            data.availabilityDateParent &&
            moment.unix(data.availabilityDateParent)
          );
        },
        defaultValue: moment().unix(),
        isCol: true,
        colSpan: 4,
      },
      {
        title: "Availability Date Parent",
        dataIndex: "availabilityDateParent",
        type: constants.FORM_TYPE.DATE_TIME,
        isCol: true,
        colSpan: 4,
        isHide: true,
      },
      {
        title: "Year",
        isHide: ({ data }) => {
          return (
            vodId ||
            (data.timeshift && parseInt(type) === constants.VOD_TYPE.EPISODE)
          );
        },
        min: 1900,
        max: new Date().getFullYear(),
        // register: {
        //   validate: (value) => {
        //     let messErr = true;
        //     if (value < 1970) {
        //       messErr = "Năm không được nhỏ hơn 1970.";
        //     } else if (value > new Date().getFullYear()) {
        //       messErr = "Năm không được lớn hơn " + new Date().getFullYear();
        //     }
        //     return messErr;
        //   },
        // },
        dataIndex: "year",
        type: constants.FORM_TYPE.NUMBER,
        isCol: true,
        colSpan: 4,
      },
      {
        title: "Expire Date",
        dataIndex: "expireDate",
        type: constants.FORM_TYPE.DATE_TIME,
        changeValue: (value) => {
          return moment(value).unix();
        },
        formatValue: (value) => {
          return value && moment.unix(value).toDate();
        },
        minDate: (data) => {
          if (data.availabilityDate) {
            if (data.availabilityDate * 1000 < Date.now()) {
              return moment().add(1, "day");
            } else {
              return moment.unix(data.availabilityDate).add(1, "day");
            }
          } else {
            return moment().add(1, "day");
          }
        },
        isHide: ({ data }) => {
          return type === constants.VOD_TYPE.EPISODE;
        },
        helperText: "Leave blank if the content is permanently copyrighted.",
        // isCol: true,
        // colSpan: 4,
      },
    ];

    if (parentKey) {
      return fieldForm.map((item) => {
        return {
          ...item,
          dataIndex: `${parentKey}.${item.dataIndex}`,
        };
      });
    } else {
      return fieldForm;
    }
  }

  // ctaRecord
  getCtaRecordVod({
    handleOpenPreview,
    handleOpenDialog,
    record = {},
    dataMapState,
    refreshData,
    type,
    vodState,
    scope,
    censor,
    screenType
  }) {
    const ctaRecord = [
      // {
      //   title: "Preview",
      //   icon: "presentation",
      //   handleAction: () =>
      //     handleOpenPreview({
      //       previewTitle: record.title,
      //       previewContent: (
      //         <VodPreview
      //           detail={record}
      //           dataMapState={dataMapState}
      //           handleOpenPreview={handleOpenPreview}
      //         />
      //       ),
      //     }),
      // },
    ];

    // if (type === constants.VOD_TYPE.LE || type === constants.VOD_TYPE.BO) {
    //   const hasFeatured = !!(
    //     record.rank && record.rank !== record.availabilityDate
    //   );
    //   // if (type === constants.VOD_TYPE.LE) {
    //   //   ctaRecord.push({
    //   //     title: "Preview",
    //   //     icon: "presentation",
    //   //     handleAction: () =>
    //   //       handleOpenPreview({
    //   //         previewTitle: record.title,
    //   //         previewContent: (
    //   //           <VodPreview
    //   //             detail={record}
    //   //             dataMapState={dataMapState}
    //   //             handleOpenPreview={handleOpenPreview}
    //   //           />
    //   //         ),
    //   //       }),
    //   //   });
    //   // }
    //   if (
    //     scope === constants.SCOPE_TYPE.ADMIN ||
    //     scope === constants.SCOPE_TYPE.LEADER
    //   ) {
    //     ctaRecord.push({
    //       title: hasFeatured ? "Featured" : "Tick Featured",
    //       icon: hasFeatured ? "star" : "star-empty",
    //       intent: hasFeatured ? Intent.WARNING : Intent.NONE,
    //       handleAction: () => {
    //         this.confirmFeatured(record, () => {
    //           refreshData();
    //         });
    //       },
    //     });
    //   }
    // }

    if (
      type === constants.VOD_TYPE.BO
      // && record.state === constants.STATE_VOD_TYPE.PUBLISHED
    ) {
      // ctaRecord.push({
      //   title: "Sửa nhanh",
      //   icon: "edit",
      //   intent: Intent.NONE,
      //   handleAction: () =>
      //     handleOpenDialog({
      //       dialogWidth: 480,
      //       dialogTitle: `Sửa nhanh phim bộ ${record.title}`,
      //       dialogData: record,
      //       dialogFieldForm: () => [
      //         {
      //           title: "Tổng số tập",
      //           dataIndex: "totalNoOfEpisodes",
      //           type: constants.FORM_TYPE.NUMBER,
      //           min: 1,
      //           isRequired: true,
      //         },
      //         {
      //           title: "Số tập",
      //           dataIndex: "episodeNumber",
      //           type: constants.FORM_TYPE.NUMBER,
      //           isRequired: true,
      //           max: (data) => {
      //             return data.totalNoOfEpisodes;
      //           },
      //         },
      //         {
      //           title: "Định dạng",
      //           dataIndex: "definition",
      //           type: constants.FORM_TYPE.SELECT,
      //           isRequired: true,
      //           dataMap: define.vodDefinitionMap,
      //           // isCol: true,
      //           // colSpan: 4,
      //           placeholder: "None",
      //         },
      //         {
      //           title: "Giới hạn độ tuổi",
      //           dataIndex: "rating",
      //           type: constants.FORM_TYPE.SELECT,
      //           isRequired: true,
      //           dataMap: define.vodRatingMap,
      //           // isCol: true,
      //           // colSpan: 4,
      //         },
      //         {
      //           title: "Thời lượng (phút)",
      //           dataIndex: "duration",
      //           type: constants.FORM_TYPE.NUMBER,
      //           isRequired: true,
      //           // isCol: true,
      //           // colSpan: 4
      //         },
      //       ],
      //       // dialogFormatDataSubmit: ({ dataSubmit }) => {
      //       //
      //       // },
      //       dialogOnOK: (data, callback) => {
      //         ActionManager.init({
      //           dataStorage: this.storage,
      //           auth: this.auth,
      //         });
      //         ActionManager.metadataUpdateSeries(record, data, () => {
      //           callback();
      //           refreshData();
      //         });
      //       },
      //     }),
      // });
    }

    const hasPublish = checkPermission({
      permissions: this.permissions,
      resource: constants.RESOURCE_NAME.METADATA,
      path: config.path.metadataIdPublish,
      method: constants.METHOD_TYPE.POST,
      dataStorage: this.storage,
    });

    if (
      hasPublish &&
      (scope === constants.SCOPE_TYPE.ADMIN ||
        scope === constants.SCOPE_TYPE.LEADER) &&
      ((record.state === constants.STATE_VOD_TYPE.EXPIRED &&
        Date.now() < moment(record.expireDate * 1000).valueOf()) ||
        record.state === constants.STATE_VOD_TYPE.DRAFT ||
        record.state === constants.STATE_VOD_TYPE.TAKE_DOWN)
    ) {
      ctaRecord.push({
        title: "Publish",
        icon: "share",
        intent: Intent.SUCCESS,
        handleAction: () => {
          this.confirmPublish(record, () => {
            // this.router.push(`/media/vod/${define.vodPath[type]}`);

            refreshData();
          });
        },
        authProps: {
          resource: constants.RESOURCE_NAME.METADATA,
          path: config.path.metadataIdPublish,
          method: constants.METHOD_TYPE.POST,
        },
      });
    }

    if (
      record.state === constants.STATE_VOD_TYPE.PUBLISHED &&
      type !== constants.VOD_TYPE.EPISODE &&
      (scope === constants.SCOPE_TYPE.ADMIN ||
        scope === constants.SCOPE_TYPE.LEADER)
    ) {
      ctaRecord.push({
        title: "Take Down",
        icon: "circle-arrow-down",
        intent: Intent.WARNING,
        handleAction: () => {
          this.confirmTakeDown(record, () => {
            // this.router.push(`/media/vod/${define.vodPath[type]}`);
            refreshData();
          });
        },
        authProps: {
          resource: constants.RESOURCE_NAME.METADATA,
          path: config.path.metadataIdTakedown,
          method: constants.METHOD_TYPE.POST,
        },
      });
    }

    const isCensor = censor && record.state !== constants.STATE_VOD_TYPE.SUBMIT;

    const vodId = this.router.params.vodId;

    const parentPath = vodId ? `/${vodId}` : "";

    if (
      scope === constants.SCOPE_TYPE.BTV &&
      (record.state === constants.STATE_VOD_TYPE.DRAFT ||
        record.state === constants.STATE_VOD_TYPE.TAKE_DOWN ||
        (record.state === constants.STATE_VOD_TYPE.SUBMIT &&
          !record.requireAction))
    ) {
      const hasSubmit = checkPermission({
        permissions: this.permissions,
        resource: constants.RESOURCE_NAME.METADATA,
        path: config.path.metadataIdSubmit,
        dataStorage: this.storage,
        method: constants.METHOD_TYPE.POST
      });
      ctaRecord.push({
        title: "Submit",
        icon: "send-to",
        intent: Intent.WARNING,
        isHide: !hasSubmit,
        handleAction: () => {
          confirmHandler({
            title: "Submit",
            content: `You definitely want to submit this content?`,
            onOk: () => {
              ActionManager.metadataSubmit(record, () => {
                let pushTo = `/media/vod/${define.vodPath[type]}${parentPath}`
                if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                  pushTo = "/media/magazine";
                }
                this.router.push(pushTo);
                refreshData();
              });
            },
          });
        },
        authProps: {
          resource: constants.RESOURCE_NAME.METADATA,
          path: config.path.metadataIdSubmit,
          method: constants.METHOD_TYPE.POST
        }
      });
    }

    if (
      (type === constants.VOD_TYPE.LE || type === constants.VOD_TYPE.EPISODE) &&
      scope !== constants.SCOPE_TYPE.ADMIN
    ) {
      const hasDuplicate = checkPermission({
        permissions: this.permissions,
        resource: constants.RESOURCE_NAME.METADATA,
        path: config.path.metadataDuplicate,
        dataStorage: this.storage,
      });

      if (hasDuplicate) {
        ctaRecord.push({
          title: "Duplicate",
          icon: "duplicate",
          intent: Intent.DANGER,
          handleAction: () => {
            if (record.parentId !== "root") {
              this.router.push(
                `/media/vod/${record.parentId}/${record.id}/duplicate`
              );
            } else {
              let pushTo = `/media/vod/${record.id}/duplicate`;
              if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                pushTo = "/media/magazine";
              }
              this.router.push(pushTo);
            }
          },
          // authProps: {
          //   resource: constants.RESOURCE_NAME.METADATA,
          //   path: config.path.metadataDuplicate
          // }
        });
      }
    }

    // if (type === constants.VOD_TYPE.TIMESHIFT) {
    //   ctaRecord.push({
    //     title: 'Chỉnh sửa',
    //     icon: 'edit',
    //     intent: Intent.SUCCESS,
    //     handleAction: () => {
    //       this.router.push(getRouteTimeshift(record));
    //     },
    //     authProps: {
    //       resource: constants.RESOURCE_NAME.METADATA,
    //       path: config.path.metadataId,
    //       method: constants.METHOD_TYPE.PUT
    //     }
    //   })
    // }

    return ctaRecord;
  }

  // detailProps
  getVodDetailProps(params = {}) {
    const { type, isDuplicate, screenType } = params;

    return {
      isFormByGroup: true,
      formProps: {
        // isPreview: ({ dataRecord, scope }) => {
        //   return !isDuplicate && dataRecord.state === constants.STATE_VOD_TYPE.PUBLISHED
        // }
      },
      cancelText: ({ dataRecord }) => {
        // if (dataRecord.state === constants.STATE_VOD_TYPE.PUBLISHED) {
        //   return 'Close'
        // }

        return "Close";
      },
      pushTo: (dataSubmit, data) => {
        const parentId = this.router.query.parentId;
        const from = this.router.query.from;
        const vodId = this.router.params.vodId;

        let pushTo = `/media/vod/${define.vodPath[type]}${vodId ? `/${vodId}` : ""
          }`;

        if (from === constants.VOD_FROM_TYPE.TIMESHIFT) {
          pushTo = "/media/vod/timeshift";
        }

        if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
          pushTo = "/media/magazine";
        }

        if (parentId) {
          ActionManager.deleteMetadata(
            parentId,
            config.apiEndpoint.METADATA,
            () => {
              this.router.push(pushTo);
            }
          );
        } else {
          this.router.push(pushTo);
        }
      },
      // renderPreview: ({
      //   dataDetail,
      //   dataRecord,
      //   dataMapState,
      //   refreshData,
      //   handleSetValueForm,
      // }) => {
      //   // console.log('dataDetail', dataDetail);

      //   return (
      //     <VodPreview
      //       isPanel={false}
      //       detail={{
      //         ...dataRecord,
      //         ...dataDetail,
      //       }}
      //       dataMapState={dataMapState}
      //       refreshData={refreshData}
      //       handleSetValueForm={handleSetValueForm}
      //     />
      //   );
      // },
      ctaExtra: ({
        handleSubmit,
        dataRecord = {},
        dataParent = {},
        hasEdit,
        submitType,
        scope,
        censor,
      }) => {
        const { state: vodState, requireAction } = dataRecord;

        const isCensor = censor && vodState !== constants.STATE_VOD_TYPE.SUBMIT;
        let hasSubmit;

        if (
          scope === constants.SCOPE_TYPE.BTV &&
          (submitType === constants.SUBMIT_TYPE.CREATE ||
            vodState === constants.STATE_VOD_TYPE.DRAFT ||
            vodState === constants.STATE_VOD_TYPE.TAKE_DOWN ||
            (vodState === constants.STATE_VOD_TYPE.SUBMIT && !requireAction))
        ) {
          hasSubmit = true;
        }

        const vodId = this.router.params.vodId;
        const from = this.router.query.from;

        const parentPath = vodId ? `/${vodId}` : "";

        const hasPublish = checkPermission({
          permissions: this.permissions,
          resource: constants.RESOURCE_NAME.METADATA,
          path: config.path.metadataIdPublish,
          method: constants.METHOD_TYPE.POST,
          dataStorage: this.storage,
        });

        // console.log('type', type);

        if (isDuplicate) {
          const hasDuplicate = checkPermission({
            permissions: this.permissions,
            resource: constants.RESOURCE_NAME.METADATA,
            path: config.path.metadataDuplicate,
            dataStorage: this.storage,
          });

          return [
            {
              title: "Duplicate",
              intent: Intent.DANGER,
              notHasEdit: true,
              icon: "duplicate",
              handleAction: (data = {}) => {
                const cloneId = `${data.id}_${randomSecretId(7)}`;

                // console.log('data', data);

                const {
                  titleParent,
                  availabilityDateParent,
                  parent = {},
                  hideVodOriginal,
                  ...dataSubmit
                } = data;

                const { _id, ...dataOriginal } = dataRecord;

                if (
                  !dataRecord.media ||
                  (dataRecord.media && !dataRecord.media.drmId)
                ) {
                  dataSubmit.media = {
                    drmId: data.id,
                    drmType: "sigma",
                  };
                }

                const type = parseInt(dataSubmit.type || dataRecord.type);

                let parentId;

                if (type === constants.VOD_TYPE.EPISODE) {
                  parentId = parent.id;
                } else {
                  parentId = "root";
                }

                const parentPath = parentId !== "root" ? `/${parentId}` : "";

                this.confirmDuplicate(
                  {
                    ...dataOriginal,
                    ...dataSubmit,
                    parentId,
                    state: constants.STATE_VOD_TYPE.DRAFT,
                    source: dataRecord.source,
                    type,
                    id: cloneId,
                  },
                  () => {
                    if (
                      hideVodOriginal &&
                      vodState === constants.STATE_VOD_TYPE.PUBLISHED
                    ) {
                      ActionManager.metadataTakedown(
                        { id: data.id },
                        {
                          reason: `Duplicate ${cloneId}`,
                        },
                        () => {
                          this.router.push(
                            `/media/vod/${define.vodPath[type]}${parentPath}`
                          );
                        }
                      );
                    } else {
                      let pushTo = `/media/vod/${define.vodPath[type]}${parentPath}`
                      if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                        pushTo = "/media/magazine";
                      }
                      this.router.push(pushTo);
                    }
                  }
                );
              },
              isHide: !hasDuplicate,
            },
          ];
        } else {

          const userInfo = this.storage?.userInfo;

          const { scope } = userInfo;
          let fetchDataConfig = {}
          if (scope === "btv") {
            fetchDataConfig = {
              messageText: "Submit successfully. Open Review Content to see details!"
            }
          }
          return [

            {
              // title: (censor && dataRecord.state === constants.STATE_VOD_TYPE.DRAFT) ? 'Nháp' : 'OK',
              title:
                vodState === constants.STATE_VOD_TYPE.DRAFT ||
                  submitType === constants.SUBMIT_TYPE.CREATE
                  ? "Draft"
                  : "Save",
              intent: Intent.PRIMARY,
              handleAction: (data, callback) => {
                handleSubmit(
                  {
                    ...data,
                    // state: constants.STATE_VOD_TYPE.EXPIRED
                  },
                  callback,
                  {
                    fetchDataConfig
                  }
                );
              },
              notHasEdit: dataRecord.type === constants.VOD_TYPE.TRANSCODE,
              // isHide: (
              //   (vodState === constants.STATE_VOD_TYPE.PUBLISHED && scope !== constants.SCOPE_TYPE.LEADER)
              //   || scope === constants.SCOPE_TYPE.ADMIN
              //   // || scope === constants.SCOPE_TYPE.LEADER
              // )
            },
            {
              title: "Submit",
              intent: Intent.SUCCESS,
              notHasEdit: true,
              handleAction: (data, callback) => {
                handleSubmit(
                  {
                    ...data,
                  },
                  (dataSubmit) => {
                    if (dataRecord.id) {
                      dataSubmit.id = dataRecord.id;
                    }

                    this.confirmSubmit(dataSubmit, isCensor, () => {
                      if (from === constants.VOD_FROM_TYPE.TIMESHIFT) {
                        this.router.push("/media/vod/timeshift");
                      } else {
                        let pushTo = `/media/vod/${define.vodPath[type]}${parentPath}`

                        if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                          pushTo = "/media/magazine";
                        }
                        this.router.push(pushTo);
                      }
                    });
                  },
                  {
                    isPushTo: false,
                    hideMessage: true,
                    notSubmit: dataRecord.id,
                  }
                );
              },
              isHide: !hasSubmit || submitType === constants.SUBMIT_TYPE.CREATE,
            },
            {
              title: "Publish",
              intent: Intent.SUCCESS,
              notHasEdit: true,
              handleAction: (data, callback) => {
                handleSubmit(
                  {
                    ...data,
                  },
                  (dataSubmit) => {
                    if (dataRecord.id) {
                      dataSubmit.id = dataRecord.id;
                    }

                    this.confirmPublish(dataSubmit, () => {
                      if (from === constants.VOD_FROM_TYPE.TIMESHIFT) {
                        this.router.push("/media/vod/timeshift");
                      } else {
                        let pushTo = `/media/vod/${define.vodPath[type]}${parentPath}`
                        if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                          pushTo = "/media/magazine";
                        }
                        this.router.push(pushTo);
                      }
                    });
                  },
                  {
                    isPushTo: false,
                    hideMessage: true,
                  }
                );
              },
              isHide:
                scope === constants.SCOPE_TYPE.BTV ||
                scope === constants.SCOPE_TYPE.SALE_MKT ||
                !hasPublish ||
                vodState === constants.STATE_VOD_TYPE.PUBLISHED ||
                vodState === constants.STATE_VOD_TYPE.PENDING ||
                (type === constants.VOD_TYPE.BO &&
                  submitType === constants.SUBMIT_TYPE.CREATE),
              // isHide: scope === constants.SCOPE_TYPE.LEADER || !hasPublish || vodState === constants.STATE_VOD_TYPE.PUBLISHED || (type === constants.VOD_TYPE.BO && submitType === constants.SUBMIT_TYPE.CREATE),
            },
            {
              title: "Take Down",
              intent: Intent.SUCCESS,
              notHasEdit: true,
              handleAction: (data, callback) => {
                this.confirmTakeDown(dataRecord, () => {
                  if (from === constants.VOD_FROM_TYPE.TIMESHIFT) {
                    this.router.push("/media/vod/timeshift");
                  } else {
                    let pushTo = `/media/vod/${define.vodPath[type]}${parentPath}`
                    if (screenType && screenType === constants.SCREEN_TYPE.MAGAZINE) {
                      pushTo = "/media/magazine";
                    }
                    this.router.push(pushTo);
                  }
                });
              },
              isHide:
                scope === constants.SCOPE_TYPE.EDITOR ||
                (vodState !== constants.STATE_VOD_TYPE.PUBLISHED &&
                  (scope === constants.SCOPE_TYPE.ADMIN ||
                    scope === constants.SCOPE_TYPE.LEADER)),
            },
          ];
        }
      },
    };
  }

  pushToVOD(id) {
    ActionManager.pushToVOD(id, (data) => {
      if (data.id) {
        const parentPath =
          data.parentId && data.parentId !== "root" ? `/${data.parentId}` : "";

        this.router.push(
          `/media/vod/${define.vodPath[data.type]}${parentPath}/${encodeURIComponent(data.id)}`
        );
      } else {
        messageHandler.warning("Metadata has been deleted or does not exist!");
      }
    });
  }

  // dialog
  openDialogMoveVodSeries(record) {
    this.openDialog({
      dialogWidth: 860,
      dialogTitle: "Series",
      dialogHideFooter: true,
      dialogContent: (
        <VodSeriesList dataDetail={record} dataStorage={this.storage} />
      ),
      dialogBackTo: null,
    });
  }

  // confirm
  confirmPublish(record, callback) {
    confirmHandler({
      title: "Publish",
      // content: `You definitely want to publish the content?`,
      content: (
        <p>
          Do you want to publish <strong>{record.title}</strong>?
        </p>
      ),
      onOk: (dataForm) => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataPublish(record, callback);
      },
    });
  }

  confirmSubmit(record, isCensor, callback) {
    confirmHandler({
      title: "Submit",
      content: <p>You definitely want to submit content?</p>,
      onOk: (dataForm) => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataSubmit(record, callback);
      },
    });
  }

  confirmTakeDown(record, callback) {
    confirmHandler({
      title: "Take Down",
      content: (
        <>
          You definitely want to take down content <strong>{record.title}</strong>?
        </>
      ),
      // isForm: true,
      // content: (
      //   <>
      //     <p>
      //       You definitely want to take down content <strong>{record.title}</strong>?
      //     </p>
      //     <BpForm
      //       fieldForm={[
      //         {
      //           title: "Reason",
      //           dataIndex: "reason",
      //           placeholder: "Enter a reason",
      //           type: constants.FORM_TYPE.TEXT_AREA,
      //           isRequired: true,
      //         },
      //       ]}
      //     />
      //   </>
      // ),
      onOk: (dataForm) => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataTakedown(record, {
          reason: "Takedown"
        }, callback);
      },
    });
  }

  confirmFeatured(record = {}, callback) {
    const hasFeatured = !!(
      record.rank && record.rank !== record.availabilityDate
    );

    confirmHandler({
      title: "Notification",
      content: `Are you sure you want${hasFeatured ? " remove " : " "}tick featured content?`,
      onOk: () => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataFeatured(record, callback);
      },
    });
  }

  confirmDuplicate(data, callback) {
    confirmHandler({
      title: "Duplicate",
      content: `You definitely want to duplicate content?`,
      onOk: () => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataDuplicate(data, callback);
      },
    });
  }

  confirmMoveVodSingle(record) {
    confirmHandler({
      title: "Confirm",
      content: "You want to put this content to single?",
      onOk: () => {
        this.router.push("/media/vod/ingest/detail/single/" + encodeURIComponent(record.id));
      },
    });
  }

  confirmRestore(record, callback) {
    confirmHandler({
      title: "Restore",
      content: "Do you want to to restore this content?",
      onOk: () => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataRestore(record, callback);
      },
    });
  }

  confirmSplit(data, callback) {
    confirmHandler({
      title: "Split episode",
      content: `Do you want to to split this content into movies?`,
      onOk: () => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataSplit(data, callback);
      },
    });
  }

  confirmMerge(data, callback) {
    confirmHandler({
      title: "Merge series",
      content: `Do you want to to include this content in the series?`,
      onOk: () => {
        ActionManager.init({
          dataStorage: this.storage,
          auth: this.auth,
        });

        ActionManager.metadataMerge(data, callback);
      },
    });
  }

  // dialog
  openDialogApprove({ dataDetail = {}, dataCompare, title, dataRecord, type, }) {
    const dialogProps = {};

    if (dataCompare instanceof Object) {
      dialogProps.dialogData = dataRecord;
      dialogProps.dialogDataCompare = dataCompare.payload;
      dialogProps.dialogFieldForm = ({
        dataMapState,
        handleOpenDialog,
        handleCloseDialog,
      }) => {
        let fieldForm = this.getFieldFormVod({
          type,
          dataMapState,
          handleOpenDialog,
          handleCloseDialog,
        })
        const temp = [...fieldForm]
        // console.log("dataDetail", dataDetail)
        console.log("temp", fieldForm)
        const index = temp.findIndex(el => el.dataIndex === "products");
        // console.log("Index", index);
        temp.splice(index, 1);
        // console.log("temp1", temp)
        console.log("difference",difference(fieldForm, temp))

        return [
          ...temp
        ]

        // return this.getFieldFormVod({
        //   type,
        //   dataMapState,
        //   handleOpenDialog,
        //   handleCloseDialog,
        // });
      }

      const permission = checkPermission({
        resource: constants.RESOURCE_NAME.APPROVE,
        path: config.path.approveRejectMetadata,
        method: constants.METHOD_TYPE.POST,
        permissions: this.permissions,
        dataStorage: this.storage,
      });

      if (permission) {
        dialogProps.dialogFooter = ({ handleClose }) => (
          <>
            <Button
              intent={Intent.DANGER}
              text="Reject"
              icon="error"
              onClick={() => {
                confirmHandler({
                  title: "Notification",
                  content: `Reject approve ${title || dataCompare.id}`,
                  onOk: () => {
                    ActionManager.approveReject(dataCompare, () => {
                      handleClose();

                      this.refreshData();
                    });
                  },
                });
              }}
            />
            <Button
              intent={Intent.SUCCESS}
              text="Approve"
              icon="tick-circle"
              onClick={() => {
                confirmHandler({
                  title: "Notification",
                  content: `Agree approve ${title || dataCompare.id}`,
                  onOk: () => {
                    ActionManager.approvePublish(dataCompare, () => {
                      handleClose();

                      this.refreshData();
                    });
                  },
                });
              }}
            />
          </>
        );
      } else {
        dialogProps.dialogFooter = <></>;
      }

      dialogProps.dialogFormProps = {
        isPreview: true,
        isFormByGroup: true
      };
    }

    if (dataCompare instanceof Array) {
      dialogProps.dialogContent = (
        <ApproveList
          dataDetail={dataDetail}
          dataList={dataCompare}
          dataStorage={this.storage}
        />
      );

      dialogProps.dialogFooter = null;

      dialogProps.dialogHideFooter = true;
    }

    this.openDialog({
      dialogWidth: 860,
      dialogTitle: `Review ${title || dataCompare.id}`,
      ...dialogProps,
    });
  }

  openMetadataDetail({ dialogData, dialogTitle }) {
    // console.log('dialogData', dialogData);

    this.openDialog({
      dialogWidth: 720,
      dialogTitle,
      dialogData,
      dialogHideFooter: true,
      dialogFieldForm: () => this.getFieldFormVod(),
      dialogFormProps: {
        isPreview: true,
      },
    });
  }
}

export default Metadata;
