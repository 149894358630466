class Manager {
  init({ dataStorage, auth, router, permissions }) {
    if (auth) {
      this.auth = auth;
    }

    if (router) {
      this.router = router;
    }

    if (dataStorage) {
      this.storage = dataStorage;
      this.uid = dataStorage.uid;
      this.token = dataStorage.accessToken;
      this.userInfo = dataStorage.userInfo;
      this.apiUrl = dataStorage.url;
    }

    if (permissions) {
      this.permissions = permissions;
    }
  }

  initDialog({ handleOpenDialog, handleCloseDialog, refreshData }) {
    if (handleOpenDialog) {
      this.openDialog = handleOpenDialog;
    }

    if (handleOpenDialog) {
      this.closeDialog = handleCloseDialog;
    }

    if (refreshData) {
      this.refreshData = refreshData;
    }
  }


}

export default Manager;
