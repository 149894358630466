import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { connect } from "react-context-hooks-store";
import { useTranslation } from "react-i18next";
import {
  Button,
  MenuItem,
  Popover,
  Classes,
  Position,
  Tooltip,
  PopoverInteractionKind,
} from "@blueprintjs/core";
import {
  BpBadge,
  BpToaster,
  Layout,
  fetchData,
  request,
  useRouter,
  useAuth,
} from "blueprint-hooks-ui";
import AppManager from "src/commons/AppManager";
import { routes } from "src/router";
import config from "src/utils/config";
import constants from "src/utils/constants";
import logo from "src/assets/images/logo.png";
import logoHeader from "src/assets/images/logo-header.png";

const App = ({
               dispatch,
               children,
               isOpenNotify,
               toggleNotifyBox,
               profile = {},
               permissions,
               getPermissions,
               setProfileInfo,
             }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const auth = useAuth();

  const user = auth[process.env.STORAGE_KEY] || {};

  const { userInfo = {} } = user;

  useEffect(() => {
    if (
      user &&
      Object.entries(user).length > 0 &&
      user.constructor === Object
    ) {
      getProfile(user);
      // getUsers(user);
    }
  }, [user]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      getPermissions(user);

      AppManager.init({
        permissions,
        router,
        dataStorage: user,
        auth,
      });
    }
  }, [user]);

  if (config.openPages.includes(pathname)) {
    return (
      <div className="app-open-pages">
        {children}
        <BpToaster/>
      </div>
    );
  }

  function getProfile(userInfo) {
    fetchData({
      fetchApi: request({
        url: `${config.apiEndpoint.USER}/${userInfo.uid}`,
        token: userInfo.accessToken,
      }),
      hideMessage: true,
      handleSuccess: (data) => {
        setProfileInfo(data)
      },
    });
  }

  function getUsers(userInfo) {
    const baseUrl =
      userInfo.url && userInfo.url.replace(process.env.API_URL, "");

    fetchData({
      fetchApi: request({
        url: baseUrl,
        token: userInfo.accessToken,
      }),
      hideMessage: true,
      handleSuccess: (data) => {
        // DispatchManager.instance().setUsers(data)
      },
    });
  }

  const goToProfile = () => {
    router.push("/profile");
  };

  return (
    <Layout
      isFixed
      dataMenu={config.dataMenu}
      homeUrl={config.homeUrl}
      redirectUrl={config.redirectUrl}
      pageTitle={config.pageTitle}
      routes={routes}
      permissions={permissions}
    >
      <Layout.Sidebar logo={logoHeader}/>
      <Layout.Content
        logo={logo}
        locales={false}
        infoText={profile.username}
        infoExtra={
          <>
            <MenuItem
              icon="follower"
              text={t("Profile")}
              onClick={goToProfile}
            />
          </>
        }
        actionExtra={
          <>
            {
              (userInfo.scope === constants.SCOPE_TYPE.ADMIN || userInfo.scope === constants.SCOPE_TYPE.LEADER)
              &&
              (
                <Tooltip
                  content="Review Content"
                  position={Position.BOTTOM}
                  boundary="flip"
                >
                  <Button
                    className={Classes.MINIMAL}
                    icon="endorsed"
                    onClick={() => router.push("/approve")}
                  />
                </Tooltip>
              )
            }
          </>
        }
      >
        {children}
      </Layout.Content>
      <BpToaster/>
    </Layout>
  );
};

const mapState = ({
                    common: { isOpenNotify },
                    global: { profile },
                    auth: { permissions },
                  }) => ({
  isOpenNotify,
  permissions,
  profile,
});

const mapDispatch = ({ getPermissions, setProfileInfo }) => ({
  getPermissions,
  setProfileInfo
});

export default connect(mapState, mapDispatch)(App);

App.propTypes = {
  children: PropTypes.element.isRequired,
};
