import React from "react";
import moment from "moment";
import { Button, Intent } from "@blueprintjs/core";
import { TextBox } from "blueprint-hooks-ui";
import {
  formatBooleanToNumber,
  formatSecondToTime,
  getStatusByTime,
} from "src/helpers";
import ChannelList from "src/components/blocks/ChannelList";
import ProgramList from "src/components/blocks/ProgramList";
import NodeSelectorList from "src/components/blocks/NodeSelectorList";
import PackageList from "src/components/blocks/PackageList";
import MetadataTab from "src/components/blocks/MetadataTab";
import ButtonChangeEventON from "src/components/blocks/ButtonChangeEventON";
import HomeTargetBox from "src/components/blocks/HomeTargetBox";
import TimeBox from "src/components/blocks/TimeBox";
import DailyTimeBox from "src/components/blocks/DailyTimeBox";
import config from "src/utils/config";
import constants from "src/utils/constants";
import define from "src/utils/define";
import Manager from "./manager";
import DetailItem from "../../../components/blocks/DetailItem";

let instanceId = null;

class SDP extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new SDP();
    }
    return instanceId;
  }

  getFieldListHomeSDP() {
    return [
      // {
      //   title: "Rank", // 'Thứ tự hiển thị',
      //   dataIndex: "rank",
      //   key: "rank",
      //   align: "right",
      //   width: 81,
      // },
      // {
      //   title: "Target Type", // 'Loại target',
      //   dataIndex: "targetType",
      //   key: "targetType",
      //   align: "center",
      //   width: 130,
      //   render: (value, record = {}) => {
      //     const { target } = record;

      //     return (
      //       <HomeTargetBox
      //         targetType={value}
      //         target={record.target}
      //         dataStorage={this.storage}
      //         auth={this.auth}
      //         permissions={this.permissions}
      //       />
      //     );
      //   },
      // },
      {
        title: "Time", //"Thời gian",
        dataIndex: "time",
        key: "time",
        align: "center",
        sorter: false,
        render: (value) => {
          return value && <TimeBox time={value}/>;
        },
      },

      {
        title: "Daily", //"Lịch",
        dataIndex: "daily",
        key: "daily",
        // width: 120,
        align: "center",
        sorter: false,
        render: (value = {}, record) => {
          return (
            record.isDaily &&
            value.days && (
              <>
                <TextBox
                  isDataMap
                  dataSource={value.days}
                  dataMap={define.dailyDayMap}
                />
                <DailyTimeBox time={value.time}/>
              </>
            )
          );
        },
      },
      {
        title: "State",
        dataIndex: "state",
        key: "time",
        align: "center",
        sorter: false,
        render: (value, record) => {
          return define.stateSpotlightMap[value];
        },
      },
    ];
  }

  getFieldFormHomeSDP(params = {}) {
    const { isHomeMenu } = params;
    return [
      {
        title: "Schedule", // "Đặt lịch",
        dataIndex: "isDaily",
        type: constants.FORM_TYPE.CHECKBOX,
        hasChange: true,
        changeValue: (value) => formatBooleanToNumber(value),
        onChange: ({ data, handleSetValueForm }) => {
          handleSetValueForm("time.startTime", null);
        },
      },
      {
        title: "Day of the week", // "Ngày trong tuần",
        dataIndex: "daily.days",
        type: constants.FORM_TYPE.CHECKBOX_MULTI,
        inline: true,
        hasChange: true,
        dataMap: define.dailyDayMap,
        formatValue: (values) => {
          return values && values.map((value) => value.toString());
        },
        changeValue: (values) => {
          return values && values.map((value) => Number(value));
        },
        isHide: ({ data = {} }) => {
          return !data.isDaily;
        },
        isRequired: ({ data = {} }) => {
          return data.isDaily;
        },
      },
      {
        title: "Show Time", // "Giờ hiển thị",
        dataIndex: "daily.time.showTime",
        type: constants.FORM_TYPE.TIME,
        format: "HH:mm",
        hasChange: true,
        changeValue: (value) => {
          const hour = Number(moment(value).format("HH"));
          const minute = Number(moment(value).format("mm"));
          return hour * 60 + minute;
        },
        formatValue: (value) => {
          return moment(formatSecondToTime(value), "HH:mm").toDate();
        },
        onChange: ({ data, handleSetValueForm }) => {
          const { daily = {} } = data;
          const { time = {} } = daily;

          const showTime = time.showTime || data["daily.time.showTime"];
          const startTime = time.startTime || data["daily.time.startTime"];

          if (startTime && showTime > startTime) {
            handleSetValueForm("daily.time.startTime", null);
          }
        },
        isHide: ({ data = {} }) => {
          return !data.isDaily;
        },
        isRequired: ({ data = {} }) => {
          return data.isDaily;
        },
        isCol: true,
        colSpan: 4,
      },
      {
        title: "Start Time", // "Giờ bắt đầu",
        dataIndex: "daily.time.startTime",
        type: constants.FORM_TYPE.TIME,
        format: "HH:mm",
        hasChange: true,
        isHide: true,
        changeValue: (value) => {
          const hour = Number(moment(value).format("HH"));
          const minute = Number(moment(value).format("mm"));
          return hour * 60 + minute;
        },
        minDate: (data) => {
          const showTime =
            data.daily && data.daily.time && data.daily.time.showTime;

          if (showTime) {
            const date = new Date(showTime * 60 * 1000);
            const hh = date.getUTCHours();
            const mm = date.getUTCMinutes();
            return moment(`${hh}:${mm}`, "HH:mm").toDate();
          }
        },
        formatValue: (value) => {
          return moment(formatSecondToTime(value), "HH:mm").toDate();
        },
        onChange: ({ data, handleSetValueForm }) => {
          const { daily = {} } = data;
          const { time = {} } = daily;

          const startTime = time.startTime || data["daily.time.startTime"];
          const endTime = time.endTime || data["daily.time.endTime"];

          if (endTime && startTime > endTime) {
            handleSetValueForm("daily.time.endTime", null);
          }
        },
        // isHide: ({ data = {} }) => {
        //   return !data.isDaily;
        // },
        // isRequired: ({ data = {} }) => {
        //   return data.isDaily;
        // },

        // register: ({ data }) => {
        //   const showTime = data.daily && data.daily.time && data.daily.time.showTime;
        //
        //   // console.log('data', data);
        //
        //   // console.log('showTime', showTime);
        //
        //   return {
        //     validate: (value) => {
        //       // console.log('value', value);
        //       // console.log('showTime', showTime);
        //
        //       return showTime && value < showTime && 'Giờ bắt đầu lớn hơn hoặc bằng giờ hiển thị';
        //     }
        //   }
        // },
        isCol: true,
        colSpan: 4,
      },
      {
        title: "End Time", // "Giờ kết thúc",
        dataIndex: "daily.time.endTime",
        type: constants.FORM_TYPE.TIME,
        format: "HH:mm",
        hasChange: true,
        changeValue: (value) => {
          const hour = Number(moment(value).format("HH"));
          const minute = Number(moment(value).format("mm"));

          return hour * 60 + minute;
        },
        minDate: (data) => {
          const startTime =
            data.daily && data.daily.time && data.daily.time.startTime;
          if (startTime) {
            const date = new Date(startTime * 60 * 1000);

            const hh = date.getUTCHours();
            const mm = date.getUTCMinutes();

            return moment(`${hh}:${mm}`, "HH:mm").toDate();
          }
        },
        formatValue: (value) => {
          return moment(formatSecondToTime(value), "HH:mm").toDate();
        },
        isHide: ({ data = {} }) => {
          return !data.isDaily;
        },
        isRequired: ({ data = {} }) => {
          return data.isDaily;
        },

        // register: ({ data }) => {
        //   const startTime = data.daily && data.daily.time && data.daily.time.startTime;
        //
        //   return {
        //     validate: (value) => {
        //       return startTime && value < startTime && 'Giờ kết thúc lớn hơn giờ kết thúc';
        //     }
        //   }
        // },
        isCol: true,
        colSpan: 4,
      },
      {
        title: "Show date", // "Thời gian hiển thị",
        dataIndex: "time.showTime",
        type: constants.FORM_TYPE.DATE_TIME,
        hasChange: true,
        minDate: moment().toDate(),
        changeValue: (value) => {
          return moment(value).unix();
        },
        formatValue: (value) => {
          return moment.unix(value);
        },
        onChange: ({ data, handleSetValueForm }) => {
          const { time = {} } = data;

          const showTime = time.showTime || data["time.showTime"];
          const startTime = time.startTime || data["time.startTime"];

          if (isHomeMenu && !data.isDaily) {
            handleSetValueForm("time.startTime", showTime);
          }

          if (
            startTime && moment(startTime).valueOf() < moment(showTime).valueOf()
          ) {
            handleSetValueForm("time.startTime", null);
          }
        },
        // isRequired: ({ data }) => {
        //   return !data.isDaily
        // },
        // isHide: ({ data }) => {
        //   return data.isDaily
        // }
      },
      {
        title: "Start Date", // "Thời gian bắt đầu",
        dataIndex: "time.startTime",
        type: constants.FORM_TYPE.DATE_TIME,
        hasChange: true,
        isHide: true,
        minDate: (data) => {
          const showTime = data.time && data.time.showTime;

          if (showTime) {
            return moment(showTime).toDate();
          } else {
            return moment().toDate();
          }
        },
        changeValue: (value) => {
          return moment(value).unix();
        },
        formatValue: (value) => {
          return moment.unix(value);
        },
        onChange: ({ data, handleSetValueForm }) => {
          const { time = {} } = data;

          const startTime = time.startTime || data["time.startTime"];
          const endTime = time.endTime || data["time.endTime"];

          if (
            endTime && moment(endTime).valueOf() < moment(startTime).valueOf()
          ) {
            handleSetValueForm("time.endTime", null);
          }
        },
        // isRequired: ({ data }) => {
        //   return !data.isDaily;
        // },
        // isHide: ({ data }) => {
        //   return isHomeMenu || data.isDaily;
        // },
      },
      {
        title: "End Date", // "Thời gian kết thúc",
        dataIndex: "time.endTime",
        type: constants.FORM_TYPE.DATE_TIME,
        hasChange: true,
        changeValue: (value) => {
          return moment(value).unix();
        },
        formatValue: (value) => {
          return moment.unix(value);
        },
        minDate: (data) => {
          const startTime = data.time && data.time.startTime;
          if (startTime) {
            return moment(startTime).add(5, "minute");
          } else {
            return moment().toDate();
          }
        },
        // isRequired: ({ data }) => {
        //   return !data.isDaily
        // },
        // isHide: ({ data }) => {
        //   return data.isDaily
        // }
      },
      {
        title: "Status", // "Trạng thái",
        dataIndex: "activated",
        type: constants.FORM_TYPE.CHECKBOX,
        defaultValue: 1,
        changeValue: (value) => formatBooleanToNumber(value),
      },
    ];
  }

  getFieldFormTargetType(params = {}) {
    let type = params.type;
    let dataList = params.dataList;
    let handleOpenDialog = params.handleOpenDialog;
    let handleCloseDialog = params.handleCloseDialog;
    return [
      {
        title: "Target Type",
        dataIndex: "targetType",
        type: constants.FORM_TYPE.SELECT,
        dataMap: define.targetTypeMap,
        isDisabled: true,
        defaultValue: constants.TARGET_TYPE.VOD,
        changeValue: (value) => Number(value),
        onReset: ({ value, data, handleSetValueForm }) => {
          handleSetValueForm("target.channelId", null);
          handleSetValueForm("target.sourceId", null);
          handleSetValueForm("target.vodId", null);
          handleSetValueForm("target.catalogId", null);
          handleSetValueForm("target.choice", null);
          handleSetValueForm("target.url", null);
          handleSetValueForm("target.programId", null);
          handleSetValueForm("target.date", null);
          handleSetValueForm("program", null);
          handleSetValueForm("packages", null);
        },
        isRequired: true,
        isHide: type === "spotlight"
      },
      // {
      //   title: 'Loại target',
      //   dataIndex: 'target.target',
      //   isHide: true
      // },
      {
        title: "Choose channel", // "Chọn kênh",
        dataIndex: "target.channelId",
        isRequired: ({ data }) => {
          return (
            parseInt(data.targetType) === constants.TARGET_TYPE.CHANNEL ||
            parseInt(data.targetType) === constants.TARGET_TYPE.PROGRAM
          );
        },
        isHide: ({ data }) => {
          return (
            parseInt(data.targetType) !== constants.TARGET_TYPE.CHANNEL &&
            parseInt(data.targetType) !== constants.TARGET_TYPE.PROGRAM
          );
        },
        hasChange: true,
        render: ({ data, handleSetValueForm }) => {
          const channelId = data.target && data.target.channelId;

          return (
            <>
              {channelId && (
                <span style={{ marginRight: "5px" }}>{channelId}</span>
              )}
              <Button
                intent={Intent.PRIMARY}
                text={channelId ? "Change" : "Choose"} // "Đổi" : "Chọn"
                onClick={() => {
                  this.openDialog({
                    dialogTitle: "Choose channel", // "Chọn kênh",
                    dialogContent: (
                      <ChannelList
                        handleSelectChannel={(record) => {
                          handleSetValueForm("target.channelId", record.id);

                          if (
                            parseInt(data.targetType) === constants.TARGET_TYPE.CHANNEL
                          ) {
                            handleSetValueForm(
                              "target.sourceId",
                              `channel_${record.id}`
                            );
                          }

                          this.closeDialog();
                        }}
                      />
                    ),
                    dialogHideFooter: true,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        title: "Chọn chương trình",
        dataIndex: "program",
        isRequired: ({ data }) => {
          return parseInt(data.targetType) === constants.TARGET_TYPE.PROGRAM;
        },
        isHide: ({ data }) => {
          const channelId = data.target && data.target.channelId;

          return !channelId;
        },
        render: ({ data, handleSetValueForm, fieldForm }) => {
          const program = data.program;

          return (
            <>
              {program && (
                <span style={{ marginRight: "5px" }}>
                  {program.title}
                  {program.subTitle && ` (${program.subTitle})`}
                </span>
              )}
              <Button
                intent={Intent.PRIMARY}
                text={program ? "Change" : "Choose"}
                onClick={() => {
                  this.openDialog({
                    dialogTitle: "Chọn chương trình",
                    dialogContent: (
                      <ProgramList
                        channelId={data.target && data.target.channelId}
                        handleSelectProgram={(record) => {
                          handleSetValueForm("program", record);

                          if (
                            fieldForm.find((item) => item.dataIndex === "title")
                          ) {
                            handleSetValueForm("title", record.title);
                          }

                          // if (fieldForm.find(item => item.dataIndex === 'content')) {
                          //   handleSetValueForm('content', record.title);
                          // }

                          if (
                            fieldForm.find((item) => item.dataIndex === "text")
                          ) {
                            handleSetValueForm("text", record.title);
                          }

                          const startDate = moment(record.startDate).format(
                            "DD-MM-YYYY"
                          );
                          const endDate = moment(record.endDate).format(
                            "DD-MM-YYYY"
                          );

                          const startTime = moment(
                            `${startDate} ${record.startTime}`,
                            "DD-MM-YYYY HH:mm"
                          ).toISOString();
                          const endTime = moment(
                            `${endDate} ${record.endTime}`,
                            "DD-MM-YYYY HH:mm"
                          ).toISOString();

                          handleSetValueForm("time.startTime", startTime);
                          handleSetValueForm("time.endTime", endTime);

                          if (
                            parseInt(data.targetType) === constants.TARGET_TYPE.PROGRAM
                          ) {
                            handleSetValueForm("target.programId", record._id);

                            handleSetValueForm(
                              "target.date",
                              moment(record.day, "YYYYMMDD").format(
                                "YYYY-MM-DD"
                              )
                            );
                          }

                          this.closeDialog();
                        }}
                      />
                    ),
                    dialogHideFooter: true,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        title: "Program Id",
        dataIndex: "target.programId",
        isHide: true,
        hasChange: true,
      },
      {
        title: "Program Date",
        dataIndex: "target.date",
        isHide: true,
        hasChange: true,
      },
      {
        title: "Source Id",
        dataIndex: "target.sourceId",
        isHide: true,
        hasChange: true,
      },
      {
        title: ({data}) => {
          if(data.screenType === constants.SCREEN_TYPE.MAGAZINE ) {
            return "Choose Magazine"
          } 
          return "Choose Video"
        }, //"Chọn VOD",
        dataIndex: "target.vodId",
        hasChange: true,
        isRequired: ({ data }) => {
          return parseInt(data.targetType) === constants.TARGET_TYPE.VOD;
        },
        isHide: ({ data }) => {
          return !data.screenType || parseInt(data.targetType) !== constants.TARGET_TYPE.VOD;
        },
        render: ({ data, handleSetValueForm }) => {
          const vodId = data.target && data.target.vodId;
          return (
            <DetailItem
              data={data}
              dataList={dataList}
              handleOpenDialog={this.openDialog}
              handleCloseDialog={this.closeDialog}
              handleSetValueForm={handleSetValueForm}
              type={type}
              screenType={data?.screenType}
              dataKey={"target.vodId"}
              typeData={2}
              target={data.target}
              textButton={vodId ? "Change" : "Choose"}
              titleModal={data.screenType === constants.SCREEN_TYPE.MAGAZINE ? "Select Magazine" : "Select Video"}
            />
            // <>
            //   {vodId && <span style={{ marginRight: "5px" }}>{vodId}</span>}
            //   <Button
            //     intent={Intent.PRIMARY}
            //     // text={vodId ? "Đổi" : "Chọn"}
            //     text={vodId ? "Change" : "Choose"}
            //     onClick={() => {
            //       this.openDialog({
            //         dialogTitle: "Choose VOD", // "Chọn VOD",
            //         dialogContent: (
            //           <MetadataTab
            //             handleSelect={(record) => {
            //               handleSetValueForm("target.vodId", record.id);
            //               console.log(record);
            //               if (type == "spotlight") {
            //                 let stringDescription = `${record.definition} • ${
            //                   record.categories && record.categories.length
            //                     ? record.categories[0].toString() + "• "
            //                     : ""
            //                 }${record.year}`;
            //                 handleSetValueForm(
            //                   "description",
            //                   stringDescription
            //                 );
            //               }

            //               this.closeDialog();
            //             }}
            //           />
            //         ),
            //         dialogHideFooter: true,
            //       });
            //     }}
            //   />
            // </>
          );
        },
      },
      {
        title: "Chọn sự kiện",
        dataIndex: "target.sourceId",
        hasChange: true,
        isRequired: ({ data }) => {
          return parseInt(data.targetType) === constants.TARGET_TYPE.EVENT;
        },
        isHide: ({ data }) => {
          return parseInt(data.targetType) !== constants.TARGET_TYPE.EVENT;
        },
        render: ({ data, handleSetValueForm, submitType }) => {
          return (
            <ButtonChangeEventON
              data={data}
              submitType={submitType}
              openDialog={this.openDialog}
              handleSelectEventON={(record) => {
                const { extra = {} } = record;

                handleSetValueForm("target.sourceId", `event_${record._id}`);
                handleSetValueForm("packages", extra.packages);

                this.closeDialog();
              }}
            />
          );
        },
      },
      // {
      //   title: 'Gói cước',
      //   dataIndex: 'packages',
      //   hasChange: true,
      //   // isRequired: ({ data }) => {
      //   //   return parseInt(data.targetType) === constants.TARGET_TYPE.EVENT
      //   // },
      //   isHide: ({ data }) => {
      //     const sourceId = data.target && data.target.sourceId;
      //
      //     return !sourceId || parseInt(data.targetType) !== constants.TARGET_TYPE.EVENT
      //   },
      //   isRegister: true,
      //   // isDisabled: true,
      //   type: constants.FORM_TYPE.SELECT_MULTI,
      //   keyMap: 'planMap',
      //   isFetchDataMap: true,
      //   config: {
      //     api: {
      //       url: config.apiEndpoint.PLAN,
      //       data: {
      //         status: 1
      //       }
      //     },
      //     keyName: 'code'
      //   }
      // },
      {
        title: "Catalog", //"Chọn node nội dung",
        dataIndex: "target.catalogId",
        hasChange: true,
        isRequired: ({ data }) => {
          return parseInt(data.targetType) === constants.TARGET_TYPE.NODE;
        },
        isHide: ({ data }) => {
          return parseInt(data.targetType) !== constants.TARGET_TYPE.NODE;
        },
        render: ({ data, handleSetValueForm }) => {
          const catalogId = data.target && data.target.catalogId;

          return (
            <>
              {catalogId && (
                <span style={{ marginRight: "5px" }}>{catalogId}</span>
              )}
              <Button
                intent={Intent.PRIMARY}
                text={catalogId ? "Change" : "Choose"}
                onClick={() => {
                  this.openDialog({
                    dialogTitle: "Catalog", //"Chọn node nội dung",
                    dialogContent: (
                      <NodeSelectorList
                        handleSelectNode={(record) => {
                          handleSetValueForm("target.catalogId", record.id);

                          this.closeDialog();
                        }}
                      />
                    ),
                    dialogHideFooter: true,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        title: "Chọn gói",
        dataIndex: "target.choice",
        hasChange: true,
        isRequired: ({ data }) => {
          return parseInt(data.targetType) === constants.TARGET_TYPE.PACKAGE;
        },
        isHide: ({ data }) => {
          return parseInt(data.targetType) !== constants.TARGET_TYPE.PACKAGE;
        },
        render: ({ data, handleSetValueForm }) => {
          const choice = data.target && data.target.choice;

          return (
            <>
              {choice && (
                <span style={{ marginRight: "5px" }}>
                  {choice.packageCode} ({choice.time})
                </span>
              )}
              <Button
                intent={Intent.PRIMARY}
                text={choice ? "Change" : "Choose"}
                onClick={() => {
                  this.openDialog({
                    dialogTitle: "Chọn gói",
                    dialogContent: (
                      <PackageList
                        handleSelectPackage={(record, plan) => {
                          handleSetValueForm("target.choice", {
                            // packageCode: record.code,
                            packageCode: `${record.id}_1`,
                            time: plan.time,
                          });

                          this.closeDialog();
                        }}
                      />
                    ),
                    dialogHideFooter: true,
                  });
                }}
              />
            </>
          );
        },
      },
      {
        title: "Url",
        dataIndex: "target.url",
        hasChange: true,
        isHide: ({ data }) => {
          return parseInt(data.targetType) !== constants.TARGET_TYPE.URL;
        },
      },
    ];
  }
  updateHomeMenu() {
    console.log("updateHomeMenu",)
  }
}

export default SDP;
