import {
  Provider,
  connect,
  memoize,
  useStore,
  useDispatch,
  useDeepEffect,
  useDeepIsomorphicLayoutEffect,
  useDeepMemo,
  useDeepCallback,
  combineReducers
} from 'react-context-hooks-store';
import { auth, global, common } from './reducers';
import * as rootAction from './actions';

const rootReducer = combineReducers({
  auth,
  global,
  common
});

export {
  Provider,
  connect,
  memoize,
  rootReducer,
  rootAction,
  useStore,
  useDispatch,
  useDeepEffect,
  useDeepIsomorphicLayoutEffect,
  useDeepMemo,
  useDeepCallback,
  combineReducers
}
