import React from 'react';
import moment from 'moment';
import { messageHandler } from 'blueprint-hooks-ui';
import config from 'src/utils/config';
import constants from 'src/utils/constants';
import define from 'src/utils/define';
import Manager from './manager';

let instanceId = null;
let autoIndexMetadata = 1;

class Metadata extends Manager {
  static instance() {
    if (!instanceId) {
      instanceId = new Metadata();
    }
    return instanceId;
  }

  metadataPublish(record, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}/publish`,
        method: 'POST'
      },
      callback,
      hideMessage: false,
      hideMessageFail: false,
      messageText: 'Publish successful!',
      messageFailText: "Something went wrong. Please contact to admin!",
    })
  }

  metadataTakedown(record, data, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}/takedown`,
        method: 'POST',
        data
      },
      callback,
      hideMessage: false,
      messageText: 'Take down successful!'
    })
  }

  metadataSubmit(record, callback) {
    const { id, ...data } = record;

    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${id}/submit`,
        method: 'POST',
        data
      },
      callback,
      hideMessage: false,
      messageText: 'Submit successful!'
    })
  }

  metadataSplit(data, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/split`,
        method: 'POST',
        data: {
          ids: data.ids
        }
      },
      callback,
      hideMessage: false,
      messageText: 'Split successful!'
    })
  }

  metadataMerge(data = {}, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/merge`,
        method: 'POST',
        data: {
          ids: data.ids,
          parentId: data.parentId
        }
      },
      callback,
      hideMessage: false,
      messageText: 'Merge successful!'
    })
  }

  metadataDuplicate(data = {}, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}`,
        method: 'POST',
        // data: getDataIdentity(data)
      },
      callback,
      hideMessage: false,
      
      messageText: 'Duplicate successful!'
    })
  }

  metadataUpdateSeries(record, data, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/update-series/${record.id}`,
        method: 'POST',
        data
      },
      callback,
      hideMessage: false,
      messageText: 'Update successful!'
    })
  }

  metadataFeatured(record = {}, callback) {
    const data = {};

    data.rank = moment().unix();

    if (record.rank && record.rank !== record.availabilityDate) {
      data.rank = record.availabilityDate
    }

    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}`,
        method: 'PUT',
        data
      },
      callback,
      hideMessage: false,
      messageText: 'Update successful!'
    })
  }

  metadataRestore(record, callback) {
    const data = {
      isDeleted: 0
    };

    if (record.state && parseInt(record.state) === constants.STATE_VOD_TYPE.PENDING) {
      data.state = constants.STATE_VOD_TYPE.DRAFT;
    }

    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}`,
        method: 'PUT',
        data
      },
      callback,
      hideMessage: false,
      messageText: 'Update successful!'
    })
  }

  approvePublish(record, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}/action`,
        method: 'POST',
        data: {
          approve: 1
        }
      },
      callback,
      hideMessage: false,
      messageText: 'Approve successful!'
    })
  }

  approveReject(record, callback, params = {}) {
    const { hideMessage = false } = params;

    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${record.id}/action`,
        method: 'POST',
        data: {
          approve: 0
        }
      },
      callback,
      hideMessage,
      messageText: 'Reject approve successful!'
    })
  }

  checkExistMetadata(data, callback) {
    if (!data.tmpId) {
      data.tmpId = data.id;
    }

    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/check-exist/${data.id}`,
      },
      handleSuccess: (res = {}) => {
        const { exist } = res;

        if (exist) {
          data.id = data.tmpId + autoIndexMetadata;

          autoIndexMetadata++;

          this.checkExistMetadata(data, callback);

          // messageHandler.warning('VOD ID đã tồn tại!');
        } else {
          delete data.tmpId;

          callback && callback(data);
        }
      }
    });
  }

  checkExist(data, apiEndpoint, callback, keyCheck) {
    this.fetch({
      apiConfig: {
        url: `${apiEndpoint}/check-exist/${keyCheck ? data[keyCheck] : data.id}`,
      },
      hideMessageFail: true,
      handleSuccess: (data = {}) => {
        const { exist } = data;

        if (exist) {
          messageHandler.warning('ID already exists!')
        } else {
          callback && callback();
        }
      },
      handleFail: () => {
        callback && callback();
      },
      // handleAction: (data) => {
      //   console.log('data checkExist', data);
      // }
    });
  }

  checkExistIngestVOD(id, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA}/${id}`
      },
      hideMessage: true,
      handleSuccess: (data = {}) => {
        if (data.type === constants.VOD_TYPE.TRANSCODE) {
          callback && callback();
        } else {
          messageHandler.warning(`This content has been used for ${constants.VOD_TEXT[data.type]} ${data.title}`)
        }
      }
    })
  }

  deleteMetadata(recordId, api, callback) {
    this.fetch({
      apiConfig: {
        url: `${api}/${recordId}`,
        method: 'DELETE'
      },
      callback
    })
  }

  getEpisode(parentId, callback) {
    this.fetch({
      apiConfig: {
        url: `${config.apiEndpoint.METADATA_EPISODE}?parentId=${parentId}`
      },
      callback
    })
  }
}

export default Metadata;
