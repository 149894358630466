module.exports = {
  DEVICE_TYPE: {
    TV: 1,
    MOBILE: 2,
    WEB: 3,
  },
  DEVICE_NAME: {
    MOBILE: "mobile",
    TABLET: "tablet",
    SMART_TV: "smarttv",
    CONSOLE: "console",
    WEARABLE: "wearable",
    DESKTOP: "desktop",
  },
  OS_TYPE: {
    IOS: "iOS",
    ANDROID: "Android",
    WINDOWS_PHONE: "Windows Phone",
    MAC_OS: "Mac OS",
  },
  ACTION_TYPE: {
    UPDATE_STATE: "updateState",
    SHOW_MODAL: "showModal",
    HIDE_MODAL: "hideModal",
    GET_USERS: "getUsers",
    GET_PROFILE: "getProfile",
    TOGGLE_NOTIFY: "toggleNotify",
    SET_NOTIFY_INFO: "setNotifyInfo",
    GET_SPOTLIGHT_SDP: "getSpotlightSDP",
    GET_MENU_SDP: "getMenuSDP",
    GET_EVENT_SDP: "getEventSDP",
    GET_NOTIFICATION_SDP: "getNotificationSDP",
    GET_CONFIG_SDP: "getConfigSDP",
    GET_BROADCAST_SCHEDULE: "getBroadcastSchedule",
    GET_ADS_CONFIG: "getAdsConfig",
  },
  AUTH_TYPE: {
    LOGIN: "login",
    LOGOUT: "logout",
    PERMISSION: "permission",
  },
  AUTH_ACTION: {
    PASSWORD: 1,
    OTP_REGISTER: 2,
    NEW_PASSWORD: 3,
    OTP_LOGIN: 4,
  },
  THEME_ACTION_TYPE: {
    CHANGE_THEME: "changeTheme",
  },
  SUBMIT_TYPE: {
    CREATE: "create",
    EDIT: "edit",
    UPDATE: "update",
  },
  FORM_TYPE: {
    STRING: "string",
    PASSWORD: "password",
    EMAIL: "email",
    BUTTON: "button",
    TEXT: "text",
    TEXT_AREA: "textarea",
    NUMBER: "number",
    NUMBERIC: "numberic",
    DATE: "date",
    DATE_TIME: "datetime",
    TIME: "time",
    SELECT: "select",
    SEARCH_SELECT: "search_select",
    SELECT_MULTI: "select_multi",
    RADIO: "radio",
    RADIO_GROUP: "radio_group",
    CONTROL_GROUP: "control_group",
    CHECKBOX: "checkbox",
    CHECKBOX_MULTI: "checkbox_multi",
    SWITCH: "switch",
    TAG: "tag",
    RATE: "rate",
    UPLOAD: "upload",
    UPLOAD_IMAGE: "upload_image",
    UPLOAD_LIST_IMAGE: "upload_list_image",
    UPLOAD_FILE: "upload_file",
    UPLOAD_LIST_FILE: "upload_list_file",
    PLAYER: "player",
    DYNAMIC_FORM: "dynamic_form",
    OBJECT: "object",
    GROUP: "group",
    EDITOR: "editor",
  },
  FILTER_TYPE: {
    STRING: "string",
    SELECT: "select",
    SEARCH_SELECT: "search_select",
    CHECKBOX: "checkbox",
    SWITCH: "switch",
    DATE: "date",
    DATE_RANGE: "date_range",
    BUTTON_GROUP: "button_group",
  },
  PLATFORM_TYPE: {
    1: "IOS_MOBILE",
    2: "ANDROID_MOBILE",
    3: "ANDROID_TV",
    4: "TIZEN",
    5: "WEB_OS",
    6: "WEB"
  },
  TRANSACTION_TYPE: {
    INIT: 'INIT',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL'
  },
  DATA_TYPE: {
    ARRAY: "array",
    OBJECT: "object",
  },
  MESSAGE_TEXT: {
    SUCCESS: "Thực hiện thành công!",
    FAIL: "Thực hiện thất bại!",
  },
  STORAGE_KEY: {
    USER: "user",
    LANGUAGE: "i18nextLng",
  },
  UPLOAD_TYPE: {
    IMAGE: "image",
    FILE: "file",
    AUDIO: "audio",
    VIDEO: "video",
    RELEASE: "release",
    SUBTITLE: "subtitle",
  },
  UPLOAD_NAME: {
    FILE_UPLOAD: "fileUpload",
    IMAGE_FILE: "imageFile",
  },
  VOD_TYPE: {
    LE: 0,
    BO: 1,
    EPISODE: 2,
    TIMESHIFT: 3,
    TRANSCODE: 4,
  },
  METADATA_PRODUCT_TYPE: {
    AVOD: 'AVOD',//FREE
    SVOD: 'SVOD'
  },
  // STATE_VOD_TYPE: {
  //   MOI: 0,
  //   XUAT_BAN: 1,
  //   GO_XUONG: 2,
  //   HET_HAN: 3
  // },
  STATE_VOD_TYPE: {
    DRAFT: 0,
    PENDING: 1,
    SUBMIT: 2,
    PUBLISHED: 3,
    EXPIRED: 4,
    TAKE_DOWN: 5,
  },
  CATEGORY_TYPE: {
    GENRE: 1,
    NATION: 2,
    PEOPLE: 3,
    LABEL: 4,
    LANGUAGE: 6,
  },
  DASHBOARD_TYPE: {
    0: "Mới",
    1: "Xuất bản",
    2: "Gỡ xuống",
    3: "Hết hạn",
  },
  VOD_TEXT: {
    0: "Phim lẻ",
    1: "Bộ",
    2: "Tập",
    3: "Timeshift",
    4: "Transcode",
  },
  IMAGE_TYPE: {
    LANDSCAPE: "landscape",
    PORTRAIT: "portrait",
    THUMBNAIL: "thumbnail",
    POSTER: "poster",
    TRAILER: "trailer",
    OST: "ost",
    SUBTITLE: "subtitle",
  },
  ASSET_TYPE: {
    LANDSCAPE: "image-landscape",
    PORTRAIT: "image-portrait",
    THUMBNAIL: "image-thumbnail",
    POSTER: "image-poster",
    TRAILER: "trailer",
    OST: "ost",
    SUBTITLE: "subtitle",
  },
  SCOPE_TYPE: {
    ADMIN: "admin",
    LEADER: "leader",
    BTV: "btv",
    EDITOR: "btv",
    CTV: "ctv",
    SALE_MKT: "ctv",
  },
  LANGUAGE_KEY: {
    EN: "en",
    VI: "vi",
  },
  EVENT_TYPE: {
    CHANNEL: "1",
    LIVE: "3",
  },
  NOTI_TYPE: {
    CREATE_METADATA: "CREATE_METADATA",
    CREATE_APPROVE: "CREATE_APPROVE",
  },
  EVENT_STATUS_NAME: {
    INIT: "init",
    SCHEDULE: "schedule",
    PRE: "pre",
    POST: "post",
    RUNNING: "running",
    STOP: "stop",
    START_ERROR: "start_error",
    ERROR: "error",
    ENDED: "ended",
  },
  RESOURCE_NAME: {
    CMS_MENU: "cms-menu",
    NATION: "nation",
    STUDIO: "studio",
    PEOPLE: "people",
    NODE: "node",
    CHANNEL: "channel",
    METADATA: "metadata",
    GENRE: "genre",
    LABEL: "label",
    APPROVE: "approve",
    ZONE: "zone",
    USER: "user",
    NOTIFICATION: "notification",
    TRANSCODE: "transcode",
    PLAN: "plan",
    SDP: "sdp-server",
    AUTHORIZATION: "authorization",
    TAXONOMY: "taxonomy",
    STATISTIC: "statistic",
    INSTRUCTOR: "instructor",
    PAYMENT: 'payment',
    USER_CUSTOMER: 'user-customer',
  },
  METHOD_TYPE: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  MENU_TYPE: {
    NODE: 2,
    CHANNEL: 6,
    MIX: 7,
    EVENT: 71,
    LIVE: 72,
    PACKAGE: 9,
    ACCOUNT: 10,
  },
  TARGET_TYPE: {
    CANCEL: -1,
    HOME: 0,
    CHANNEL: 1,
    VOD: 2,
    EVENT: 3,
    ALL_CHANNEL: 6,
    LOGIN: 7,
    PACKAGE: 9,
    ACCOUNT: 10,
    NODE: 11,
    URL: 12,
  },
  HOME_SDP_TYPE: {
    TEXT: 1,
    IMAGE: 2,
  },
  STATUS_TYPE: {
    DEACTIVATED: 0,
    ACTIVE: 1,
    SCHEDULE: 2,
  },
  WEEK_DAY: {
    1: "Mo",
    2: "Tu",
    3: "We",
    4: "Th",
    5: "Fr",
    6: "Sa",
    7: "Su",
  },
  VOD_FROM_TYPE: {
    SYNC: "sync",
    TDPS: "tdps",
    DMS: "dms",
    TIMESHIFT: "timeshift",
    CMS: "cms",
  },
  PRODUCT_TYPE: {
    TVOD: "TVOD",
    SVOD: "SVOD",
    AVOD: "AVOD",
  },
  CHANNEL_EVENT_TYPE: {
    HIGHLIGHT: "highlight",
    LIVE: "live",
  },
  EPG_STATUS: {
    OK: "ok",
    NOT_FOUND: "not_found",
    SAME_TIME: "same_time",
    TOO_LONG_TIME: "too_long_time",
  },
  EVENT_PRE_TYPE: {
    IMAGE: "image",
    VIDEO: "video",
  },
  CHART_TYPE: {
    LINE: "line",
    PIE: "pie",
    BAR: "bar",
    AREA: "area",
    LIST: "list",
  },
  DISPLAY_TYPE: {
    LANDSCAPE: 1,
    PORTRAIT: 2,
    CYCLE: 3
  },

  TYPE_DETAIL_ITEM_COMPONENT: {
    CHANNEL: 1,
    VOD: 2,
    // NOTIFICATION:3
  },
  SEARCH_CUSTOMER_BY: {
    NAME: 1,
    EMAIL: 2,
  },
  TIME_TYPE: {
    MINUTE: 'm',
    HOUR: 'h',
    DAY: 'd',
    WEEK: 'w',
    MONTH: 'M'
  },
  TIME_TYPE_PLAN_DURATION: {
    HOUR: "hour",
    DAY: "day",
    WEEK: "week",
    MONTH: "month"
  },
  SCREEN_TYPE: {
    MAGAZINE: 'magazine',
    VIDEO: 'vod',
    HEADER: 'header',
    MAGAZINE_HEADER: 'magazine_header'
  },
  IDS_CATEGORY_CATALOG_DISABLED_EDIT: ["085806795", "071332126999", "003770897", "085801111et" ],
  STATE_SPOTLIGHT: {
    RUNNING: 1,
    PENDING: 2,
    INITIAL: 3,
    FINISH: 4
  }
};
