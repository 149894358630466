import React, { Component } from 'react';
import { BpBase, ButtonAction } from 'blueprint-hooks-ui';
import config from 'src/utils/config';
import constants from 'src/utils/constants';

import './index.less';

const EventONList = ({ dataStorage, handleSelectEventON }) => {
  const fieldFilter = [
    {
      title: 'Tên sự kiện',
      dataIndex: 'search'
    }
  ];

  const fieldList = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt'
    },
    {
      title: 'Tên sự kiện',
      dataIndex: 'name',
      key: 'name',
      render: (value) => {
        return value
      }
    },
    {
      // title: 'Chọn',
      dataIndex: 'choice',
      key: 'choice',
      align: 'center',
      render: (value, record) => {
        if (
          record.status !== constants.EVENT_STATUS_NAME.STOP &&
          record.status !== constants.EVENT_STATUS_NAME.ENDED &&
          record.status !== constants.EVENT_STATUS_NAME.ERROR &&
          record.status !== constants.EVENT_STATUS_NAME.START_ERROR
        ) {
          return (
            <ButtonAction
              text="Chọn"
              onClick={() => {
                handleSelectEventON(record);
              }}
            />
          )
        }
      }
    }
  ];

  return (
    <div>
      <BpBase
        hasInView
        isAddRecord={false}
        isDeleteRecord={false}
        fieldFilter={fieldFilter}
        fieldList={fieldList}
        apiConfig={{
          list: {
            url: `${config.apiEndpoint.SDP_EVENT_ON}/events-cms`,
            // params: {
            //   status: constants.EVENT_STATUS_NAME.RUNNING
            // }
          }
        }}
        listProps={{
          limit: 10,
          pageName: 'page',
          // perPageName: 'per_page'
        }}
      />
    </div>
  )
};

export default EventONList
